import { useCallback, useLayoutEffect, useRef, useState } from "react";
import useDateRange from "./useDateRange";
import useUpdateEffect from "./useUpdateEffect";

interface SidepanelOptions {
  onClose?: () => void;
}
function useSidepanel<T>(options?: SidepanelOptions) {
  const [tableData, setTableData] = useState<T[]>([]);
  const data = useSidepanelBase(tableData, options);
  return {
    ...data,
    setTableData,
  };
}

export function useSidepanelBase<T>(tableData: T[], options?: SidepanelOptions) {
  const [activeRow, setActiveRow] = useState<number>();
  const { range } = useDateRange();
  const sidePanelProps = activeRow !== undefined ? tableData?.[activeRow] : undefined;
  const isOpen = activeRow !== undefined;
  const optionsRef = useRef(options);

  useUpdateEffect(() => {
    if (isOpen) {
      setActiveRow(0);
    }
  }, [range]);

  useLayoutEffect(() => {
    optionsRef.current = options;
  });

  const onRowClick = useCallback((index: number) => {
    setActiveRow(index);
  }, []);

  const onClose = useCallback(() => {
    setActiveRow(undefined);
    optionsRef?.current?.onClose?.();
  }, []);

  const onNextClick = () => {
    if (activeRow === undefined || activeRow === tableData.length - 1) return;
    setActiveRow(activeRow + 1);
  };

  const onPreviousClick = () => {
    if (activeRow === undefined || activeRow === 0) return;
    setActiveRow(activeRow - 1);
  };

  return {
    tableData,
    activeRow,
    setActiveRow,
    sidePanelProps,
    onRowClick,
    onNextClick,
    onPreviousClick,
    onClose,
    isOpen,
    disableNext: activeRow === tableData.length - 1,
    disablePrevious: activeRow === 0,
  };
}

export default useSidepanel;
