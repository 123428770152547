import { color, rem } from "config/variable.styles";
import styled from "styled-components";

export const DateSelectButton = styled.button`
  all: unset;
  cursor: pointer;
  font-size: ${rem(14)};
  font-weight: 500;
  padding: ${rem(2)} ${rem(4)};
  :hover {
    color: ${color.navyBlue};
  }
  &.active {
    font-weight: 700;
    color: ${color.navyBlue};
    border-bottom: ${rem(2)} solid ${color.navyBlue};
    margin-bottom: ${rem(-2)};
  }
`;

export const Space = styled.div`
  display: flex;
  align-items: center;
  gap: ${rem(8)};
`;

export const ButtonGroup = styled.div`
  display: flex;
  border-radius: 10px;
  border: 1px solid #e8e8e8;

  & > *:not(:last-child) {
    border-right: 1px solid #e8e8e8;
  }

  & > :first-child {
    border-radius: 8px 0 0 8px;
  }

  & > :last-child {
    border-radius: 0 8px 8px 0;
  }
`;

export const Button = styled.button`
  all: unset;
  cursor: pointer;
  padding: 5px 18px;
  font-size: 13px;
  color: #707070;
  transition: all 0.1s ease-in-out;
  position: relative;
  overflow: hidden;

  &:not(.active) {
    &::before {
      content: "";
      position: absolute;
      top: -10px; /* Adjust this value to control the shadow position */
      left: 0;
      width: 100%;
      right: 0;
      height: 10px; /* Should match the border-radius */
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Adjust shadow properties as needed */
    }
  }

  &.active {
    background-color: #e8e8e8;
    color: black;
    font-weight: 500;
  }
`;
