import styled from "styled-components";
import { Button, withStyles, IconButton, Popover, Badge, Collapse } from "@material-ui/core";
import { rem, Transition, color, xl, rgba, CollapseTransition } from "../../config/variable.styles";

const headerProfileXL = xl(` 
  padding-left: ${rem(40)};
`);

const headerLogoXL = xl(` 
  padding-right: ${rem(40)};
`);

export const HeaderContainer = styled.div`
  padding: ${rem(0)} ${rem(20)};
  background: ${color.White};
  .header {
    display: flex;
    align-items: center;
    transition: ${Transition};
  }
  .d-none {
    display: none;
    transition: ${Transition};
  }
`;

export const HeaderLogo = styled.div`
  display: block;
  cursor: pointer;
  padding-right: ${rem(60)};
  .MuiLink-root {
    display: inline-flex;
    svg {
      width: ${rem(100)};
      height: ${rem(26)};
    }
  }
  ${headerLogoXL}
`;
export const HeaderNavMenu = styled.div`
  margin-right: auto;
  .nav {
    display: flex;
    list-style: none;
    padding: ${rem(0)};
    margin: ${rem(0)};
    &__item {
      padding: 0 ${rem(30)} 0 0;
      display: inline-flex;
      position: relative;
      &:last-child {
        padding: 0 0 0 0;
      }
      &_link {
        letter-spacing: ${rem(0.38)};
        color: ${color.DarkBlack};
        font-size: 0.95rem;
        line-height: ${rem(17)};
        font-weight: 400;
        text-transform: capitalize;
        transition: ${Transition};
        text-decoration: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        border-top: ${rem(3)} solid transparent;
        padding: ${rem(16)} ${rem(0)} ${rem(23)};
        &:hover {
          color: ${color.navyBlue};
          text-decoration: none;
          border-top: ${rem(3)} solid ${color.navyBlue};
        }
        &_active {
          color: ${color.navyBlue};
          border-top: ${rem(3)} solid ${color.navyBlue};
          font-weight: 700;
        }
        .downArrow {
          display: block;
          margin-left: ${rem(5)};
          line-height: 0;
          margin-top: ${rem(2)};
          font-size: 0.8rem;
        }
        &:hover {
          .downArrow {
            svg {
              color: ${color.navyBlue};
            }
          }
        }
      }
    }
  }
`;
export const HeaderAction = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;
export const HeaderProfile = styled.div`
  padding-left: ${rem(12)};
  ${headerProfileXL}
`;
export const ProfileBtn = withStyles(
  {
    root: {
      background: "transparent",
      borderRadius: 0,
      border: 0,
      color: `${color.BrownishGrey}`,
      height: "auto",
      fontSize: rem(13),
      padding: "0",
      paddingRight: rem(0),
      boxShadow: "inherit",
      position: "relative",
      minWidth: "inherit",
      "&:hover": {
        background: "transparent",
        boxShadow: "inherit",
      },
      "& .profile-thumb": {
        height: rem(32),
        width: rem(32),
        flex: `0 0 ${rem(32)}`,
        borderRadius: "5px",
        marginRight: rem(0),
        background: `${color.BrownishGrey}`,
        display: "block",
        overflow: "hidden",
        "& img": {
          width: "100%",
          height: "100%",
          objectFit: "cover",
        },
      },
      "& .icon-chevron": {
        color: `${color.Black}`,
        fontSize: rem(16),
        position: "absolute",
        right: 0,
        top: "50%",
        height: rem(16),
        transform: "translate(0, -50%)",
        display: "none",
        alignItems: "center",
      },
    },
    label: {
      textTransform: "capitalize",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
  },
  { name: "profile-btn" }
)(Button);

export const HeaderActionIcon = withStyles(
  {
    root: {
      height: rem(32),
      width: rem(32),
      padding: rem(5),
      margin: `0 ${rem(8)}`,
      "& .icon": {
        fontSize: rem(24),
        color: `${color.Black}`,
        display: "flex",
      },
      "&:hover": {
        background: color.White,
        "& .icon svg path": {
          fill: `${color.Primary}`,
        },
      },
      "&.open": {
        background: color.White,
        "& .icon svg path": {
          fill: `${color.Primary}`,
        },
      },
    },
    label: {
      padding: "0",
      justifyContent: "center",
    },
  },
  { name: "header-action" }
)(IconButton);
export const NotificationsPopover = withStyles(
  {
    root: {
      padding: rem(10),
    },
    paper: {
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
      padding: 0,
      width: rem(600),
      borderRadius: 0,
      height: "calc(100% - 80px)",
      boxShadow: `0 0 ${rem(20)} 0 ${rgba(color.Black, 0.2)}`,
    },
  },
  { name: "notification" }
)(Popover);

export const ActionBadge = withStyles(
  {
    root: {
      margin: 0,
    },
    badge: {
      backgroundColor: color.coral,
      fontSize: rem(14),
      fontWeight: 700,
      padding: `0 ${rem(5)}`,
      right: rem(10),
      top: rem(5),
      cursor: "pointer",
    },
  },
  { name: "action-badge" }
)(Badge);

export const CollapseStyle = withStyles(
  {
    root: {
      "&.dashboard-header": {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        width: "100%",
        transition: CollapseTransition,
        zIndex: 99,
      },
    },
  },
  { name: "header-collapse" }
)(Collapse);

export const Arrow = styled.span`
  margin-left: ${rem(5)};
  transition: transform 0.1s ease-in-out;
  &.open {
    transform: rotate(90deg);
  }
`;

export const Scrollable = styled.div`
  padding: ${rem(10)};
  max-height: ${rem(350)};
  direction: rtl;
  overflow-y: auto;
  position: relative;
  font-size: ${rem(14)};

  .close {
    position: absolute;
    top: ${rem(6)};
    right: ${rem(6)};
    cursor: pointer;
  }

  // customize scrollbar
  ::-webkit-scrollbar {
    width: ${rem(13)};
    &-track {
      background: white;
    }
    &-thumb {
      background: #707070;
      &:hover {
        background: #606060;
        cursor: grab;
      }
      &:active {
        cursor: grabbing;
      }
    }
    &-button {
      display: none;
    }
  }
`;

export const List = styled.ul`
  list-style: none;
  direction: ltr;

  li {
    line-height: 24px;
  }
`;
