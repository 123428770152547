import { TimelineAmchartObject } from "types/EventTimeline.types";

// Duration was used to introduce gaps between bins on the timeline.
// We are removing the gaps from timeline to experiment.
// Until we decide to bring gaps back, we are keeping duration
// variable unused. Please make following changes to have gaps back.
// amc_fromDate: addMinutes(from, duration);
const convertDataForChart = (activity_timelines: TimelineAmchartObject[] | undefined, duration = 12) => {
  // Adding dummy data to each categories to show an
  // empty track even if no activities are present.
  const base = {
    from_dttm: "",
    to_dttm: "",
    counts: {
      BRIDGE: 0,
      CARGO: 0,
      MAINTENANCE: 0,
      SAFETY: 0,
    },
    weather_summary: undefined,
    id: "",
    color: "",
  };

  const data: {
    alerts: TimelineAmchartObject[];
    attentions: TimelineAmchartObject[];
    routines: TimelineAmchartObject[];
  } = {
    alerts: [{ type: "Alert", ...base }],
    attentions: [{ type: "Attention", ...base }],
    routines: [{ type: "Routine", ...base }],
  };
  activity_timelines?.forEach((item) => {
    switch (item.type) {
      case "Alert":
        data.alerts.push(item);
        break;
      case "Attention":
        data.attentions.push(item);
        break;
      case "Routine":
        data.routines.push(item);
        break;
    }
  });
  return [...data.alerts, ...data.attentions, ...data.routines];
};

export default convertDataForChart;
