import { InfiniteData, useInfiniteQuery, useQuery } from "@tanstack/react-query";

import { useObserver } from "hooks";
import { queryKeys, sensorsClient } from "config";
import { GetSensorsRequest, GetSensorsRequest_Sort } from "@shipin/sensors-client/service";
import { useProtoFilters } from "hooks/useFilters";

const ENTRIES_PER_PAGE = 10;
const SENSORS_KEY = "sensors-list";

async function fetchSensors(request: GetSensorsRequest) {
  const { sensors = [] } = await sensorsClient.getSensors(request).response;

  if (!sensors.length || sensors.length < ENTRIES_PER_PAGE) {
    return {
      sensors,
      nextPage: undefined,
    };
  }

  return {
    sensors,
    nextPage: request.offset + ENTRIES_PER_PAGE,
  };
}

export type SensorQueryResult = Awaited<ReturnType<typeof fetchSensors>>;

const selectSensors = (response: InfiniteData<SensorQueryResult>): SensorQueryResult => ({
  nextPage: response.pages[response.pages.length - 1].nextPage,
  sensors: response.pages.flatMap((page) => page?.sensors ?? []),
});

function useSensorsQuery(sort: GetSensorsRequest_Sort) {
  const filters = useProtoFilters();

  const query = useInfiniteQuery({
    queryKey: [
      SENSORS_KEY,
      {
        areaNames: filters.areaNames,
        compartmentNames: filters.compartmentNames,
        edgeIds: filters.edgeIds,
        placementIds: filters.placementIds,
        sectionIds: filters.sectionIds,
        sensorTypes: [],
        statuses: [],
        vesselIds: filters.vesselIds,
        fleetIds: filters.fleetIds,
        sort,
      },
    ],
    queryFn: ({ pageParam = 0 }) =>
      fetchSensors({
        count: ENTRIES_PER_PAGE,
        offset: pageParam,
        filter: {
          areaNames: filters.areaNames,
          compartmentNames: filters.compartmentNames,
          edgeIds: filters.edgeIds,
          placementIds: filters.placementIds,
          sectionIds: filters.sectionIds,
          sensorTypes: [],
          statuses: [],
          vesselIds: filters.vesselIds,
          fleetIds: filters.fleetIds,
        },
        sorts: [sort],
      }),

    initialPageParam: 0,
    getNextPageParam: (lastPage) => lastPage.nextPage,

    staleTime: 0,
    gcTime: 0,

    select: selectSensors,
  });

  const ref = useObserver({
    onIntersect: () => query.fetchNextPage(),
  });

  return [ref, query] as const;
}

function useSensorsCountQuery() {
  return useQuery({
    ...queryKeys.sensors.sensorInfo,
    queryFn: () => sensorsClient.getSensorsCount({}).response,
    select: (res) => Number(res.count) || 0,
    staleTime: Infinity,
    gcTime: Infinity,
  });
}

function useFilteredSensorsCountQuery() {
  const filters = useProtoFilters();

  return useQuery({
    queryKey: [
      "sensors-count",
      {
        areaNames: filters.areaNames,
        compartmentNames: filters.compartmentNames,
        edgeIds: filters.edgeIds,
        placementIds: filters.placementIds,
        sectionIds: filters.sectionIds,
        sensorTypes: [],
        statuses: [],
        vesselIds: filters.vesselIds,
        fleetIds: filters.fleetIds,
      },
    ],
    queryFn: () =>
      sensorsClient.getSensorsCount({
        filter: {
          areaNames: filters.areaNames,
          compartmentNames: filters.compartmentNames,
          edgeIds: filters.edgeIds,
          placementIds: filters.placementIds,
          sectionIds: filters.sectionIds,
          sensorTypes: [],
          statuses: [],
          vesselIds: filters.vesselIds,
          fleetIds: filters.fleetIds,
        },
      }).response,
    select: (res) => Number(res.count) || 0,
    staleTime: Infinity,
    gcTime: Infinity,
  });
}

export { useSensorsQuery, useSensorsCountQuery, useFilteredSensorsCountQuery, SENSORS_KEY };
