import { useTransfer } from "./useTransfer";

function useTransferVessel(rowId?: string[]) {
  return useTransfer({
    mode: "vessels",
    tranform: (_, vessel) =>
      rowId?.includes(vessel.id) ? { id: vessel.id, name: vessel.name, selected: true } : { id: vessel.id, name: vessel.name, selected: false },
  });
}

export { useTransferVessel };
