import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "config/api";
import { ReduxState } from "types/ReduxState/common.types";
import { FleetBridgeActivitiesQuery, FleetBridgeActivitiesResponse } from "types/Reports.types";
import { reports } from "constants/api/reports";
import { ERROR_MESSAGE } from "constants/api/common";

const initialState: ReduxState<FleetBridgeActivitiesResponse> = {
  status: "idle",
  data: null,
  error: null,
};
export const fetchReports = createAsyncThunk("fetchReports", async (payload: FleetBridgeActivitiesQuery, { rejectWithValue }) => {
  try {
    const res = await axios.post<FleetBridgeActivitiesResponse>(reports.fleetBridgeActivities, payload);
    return res.data;
  } catch (err) {
    return rejectWithValue(err?.response?.data?.msg || ERROR_MESSAGE);
  }
});

const reportsSlice = createSlice({
  name: "reportStats",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchReports.pending, (state) => {
      state.status = "loading";
      state.data = null;
    });
    builder.addCase(fetchReports.fulfilled, (state, action) => {
      state.status = "success";
      state.data = action.payload;
      state.error = null;
    });
    builder.addCase(fetchReports.rejected, (state, action) => {
      state.error = JSON.stringify(action.payload);
      state.status = "error";
      state.data = null;
    });
  },
});

export default reportsSlice.reducer;
