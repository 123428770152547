import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useEffect, useState } from "react";
import ProfileThumb from "components/ProfileThumb/ProfileThumb";
import * as Yup from "yup";
import { useFormik } from "formik";
import { getSignedUrl } from "services/redux/reducers/User/signedUrl.slice";
import { toast } from "react-toastify";
import { SuccessToast } from "components/Toastbar";
import { SUCCESS_MESSASGE } from "constants/api/userProfile";
import { useCurrentUser, useInvalidateCurrentUser, useUpdateUserMutation } from "queries";

// In old version, this was left form but now it is right form
// not renaming it to avoid loosing the commit history
const ProfileLeftForm = () => {
  const [profileEditMode, setProfileEditMode] = useState(false);
  const { status: signUrlStatus } = useAppSelector((state) => state.signedUrl);
  const isLoadingUpdate = profileEditMode && signUrlStatus === "loading";
  const dispatch = useAppDispatch();

  const invalidateCurrentUser = useInvalidateCurrentUser();
  const { data: userProfile, isLoading } = useCurrentUser();
  const { mutate: updateUserProfile } = useUpdateUserMutation({
    onSuccess: (data) => {
      toast.success(<SuccessToast>{SUCCESS_MESSASGE}</SuccessToast>);
      setProfileEditMode(false);
      invalidateCurrentUser(data);
    },
  });

  const { values, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      image_url: userProfile?.image_url || "",
    },
    validationSchema: Yup.object().shape({
      image_url: Yup.string(),
    }),
    onSubmit: (formValues) => {
      if (!userProfile?.id) return;

      dispatch(getSignedUrl({ userId: userProfile?.id, imageURl: formValues?.image_url }))
        .then((res: any) => {
          if (!!res?.payload?.get_url && typeof res?.payload?.get_url === "string") {
            updateUserProfile({
              image_url: res?.payload?.get_url,
              id: userProfile?.id,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          setProfileEditMode(false);
        });
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    return () => {
      URL.revokeObjectURL(values.image_url);
    };
  }, [values.image_url]);

  return (
    <form onSubmit={handleSubmit}>
      <ProfileThumb
        loading={isLoading || isLoadingUpdate}
        setFieldValue={setFieldValue}
        image_url={values.image_url}
        profileEditMode={profileEditMode}
        setProfileEditMode={setProfileEditMode}
      />
    </form>
  );
};

export default ProfileLeftForm;
