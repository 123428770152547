import styled from "styled-components";
import { color, rem } from "config/variable.styles";
import { Modal } from "components/Modal";

export const UsersModalStyle = styled(Modal)`
  .modal-wrap {
    max-width: ${rem(600)};
    min-height: ${rem(730)};
    .modal-header {
      h3 {
        text-transform: capitalize;
      }
    }
  }
`;
export const UsersModalContent = styled.div`
  display: flex;
  flex-direction: column;
  .modalFormRow {
    width: 100%;
    display: flex;
    margin-bottom: ${rem(30)};
    label {
      margin: 0px;
      font-size: ${rem(16)};
      color: ${color.DarkBlack};
      width: ${rem(170)};
      max-width: ${rem(170)};
      flex: 0 0 ${rem(170)};
      padding: ${rem(9)} 0;
    }
    .from-group {
      flex: 1 1 auto;
      &.center {
        align-self: center;
      }
      .MuiTextField-root {
        width: 100%;
        .MuiInput-root {
          width: 100%;
          &:after,
          &:before {
            display: none;
          }
          .MuiInput-input {
            padding: ${rem(10)} ${rem(12)};
            background-color: ${color.White};
            width: 100%;
            &.Mui-disabled {
              cursor: default;
              color: ${color.warmGreyTwo};
              background-color: ${color.disabledBgColor};
            }
          }
        }
        .MuiFormHelperText-root {
          position: absolute;
          bottom: ${rem(-20)};
          color: ${color.redColor};
        }
      }
      .statusSelectBox {
        width: 100%;
        position: relative;
        &:after,
        &:before {
          display: none;
        }
        .MuiInput-input {
          padding: ${rem(10)} ${rem(12)};
          background-color: ${color.White};
          width: 100%;
          font-size: ${rem(16)};
          color: ${color.DarkBlack};
          &.Mui-disabled {
            cursor: default;
            color: ${color.disabledTextColor};
            background-color: ${color.disabledBgColor};
          }
        }
        span.icon {
          position: absolute;
          right: ${rem(15)};
          line-height: 0;
          pointer-events: none;
        }
        &.Mui-disabled {
          span.icon {
            color: ${color.Black};
          }
        }
      }
      .email-text-block {
        padding: ${rem(0)} ${rem(12)};
      }
    }
  }
`;
