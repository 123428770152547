import { useMemo, useState } from "react";
import axios from "axios";
import { IoClose } from "react-icons/io5";
import { MdArrowRight } from "react-icons/md";
import { useQuery } from "@tanstack/react-query";
import { Menu, MenuItem, Box, Popover } from "@material-ui/core";

import { useMenu } from "hooks";
import { useCurrentUser } from "queries";

import Help from "components/Icon/Help";
import { Scrollable, Arrow, HeaderActionIcon, List } from "./Header.styles";
import { Version } from "./Header.types";

const roles = ["monitor", "admin", "superuser"];

const ShipShape = () => {
  const { data } = useQuery({
    queryKey: ["app-versions"],
    queryFn: () => axios.get<Array<Version>>("/versions.json").then((e) => e.data),
    staleTime: Infinity,
    gcTime: Infinity,
  });

  // There are some cases when versions.json doesn't exist.
  // In that case, it returns the HTML as string which causes app to crash.
  // So, checking if data is an array or not.
  const versions = !!data && Array.isArray(data) ? data : undefined;

  const { data: user } = useCurrentUser();
  const [menuProps, openMenu] = useMenu({ className: "shipshape-menu" });
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
  const webAppVersion = useMemo(() => versions?.find((e) => e.name === "application-version")?.app_version, [versions]);

  const open = Boolean(anchorElement);
  const isVisible = !!versions && (!!user?.role ? roles.includes(user.role.toLowerCase()) : false);
  const listItemProps = {
    style: isVisible ? { paddingTop: 4, paddingBottom: 4 } : { paddingRight: 24 },
    onClick: isVisible ? (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => setAnchorElement(e.currentTarget) : undefined,
  };

  const onHelpClick = () => {
    window.open("https://info.shipin.ai/shipshape", "_blank");
  };

  return (
    <>
      <HeaderActionIcon onClick={openMenu}>
        <Help />
      </HeaderActionIcon>
      <Menu {...menuProps} autoFocus={false}>
        <MenuItem onClick={onHelpClick}>ShipShape Help Center</MenuItem>
        <MenuItem {...listItemProps}>
          <Box display="flex" alignItems="center">
            <p>FleetVision Version {webAppVersion ?? ""}</p>
            {isVisible && (
              <Arrow className={open ? "open" : undefined}>
                <MdArrowRight size={24} color="#707070" />
              </Arrow>
            )}
          </Box>
        </MenuItem>
      </Menu>
      <Popover
        open={open}
        anchorEl={anchorElement}
        onClose={() => setAnchorElement(null)}
        PaperProps={{
          style: {
            backgroundColor: "#f1f1f1",
            marginTop: 20,
            borderRadius: 0,
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Scrollable>
          <span className="close" role="button" onClick={() => setAnchorElement(null)}>
            <IoClose color="#757575" />
          </span>
          <List>
            {versions?.map((e, i) => (
              <li key={i}>{e.chart}</li>
            ))}
          </List>
        </Scrollable>
      </Popover>
    </>
  );
};

export default ShipShape;
