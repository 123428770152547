import { Transition, color, rem, rgba } from "config/variable.styles";
import styled from "styled-components";

export const UserTableWrapper = styled.div`
  position: relative;
  z-index: 9;
  .leaflet-container {
    height: 100%;
    width: 100%;
    z-index: 1;
  }
`;

export const UserTableContainer = styled.div`
  background-color: ${color.White};
  padding: 0 ${rem(20)};
  height: calc(100vh - ${rem(210)});
  overflow: auto;
  .more-action {
    cursor: pointer;
  }
  .MuiTableContainer-root {
    overflow-x: visible;
    box-shadow: inherit;
    .MuiTable-root {
      .MuiTableHead-root {
        position: sticky;
        top: ${rem(0)};
        background-color: ${color.White};
        z-index: 9;
        filter: none;
        .MuiTableRow-root {
          .MuiTableCell-root {
            font-size: ${rem(14)};
            font-weight: bold;
            color: ${color.DarkBlack} !important;
            line-height: ${rem(17)};
            padding: ${rem(11)} ${rem(20)};
            border-bottom-color: ${color.duckEggBlue};
            position: relative;
            z-index: 21;
            &:hover {
              background-color: ${color.iceBlue};
            }
            &:last-child {
              &:hover {
                background-color: transparent;
              }
            }
            .MuiButtonBase-root {
              display: flex;
              justify-content: space-between;
            }
          }
        }
      }
      .MuiTableBody-root {
        .MuiTableRow-root {
          transition: ${Transition};
          background-color: ${color.White};
          will-change: filter;
          &:hover,
          &.row-hover {
            filter: drop-shadow(0 0 ${rem(8)} ${rgba(color.Black, 0.25)});
            transform: scaleX(1.0072);
            position: relative;
            z-index: 10;
            .table-action {
              opacity: 1;
              pointer-events: inherit;
              &:hover {
                background: transparent;
              }
            }
            .MuiTableCell-root {
              .icon {
                display: block;
                visibility: visible;
              }
              &::after {
                opacity: 1;
              }
            }
          }
          .MuiTableCell-root {
            font-size: ${rem(14)};
            font-weight: normal;
            vertical-align: middle;
            color: ${color.DarkBlack};
            line-height: ${rem(17)};
            padding: ${rem(6)} ${rem(20)} ${rem(7)};
            border-top: none;
            border-left: none;
            border-right: none;
            border-bottom-color: ${color.duckEggBlue};
            position: relative;
            height: ${rem(56)};
            &.highlight {
              color: ${color.navyBlue};
              font-weight: 700;
            }
            &:first-child {
              &::after {
                display: none;
              }
            }
            &::after {
              content: "";
              position: absolute;
              transition: ${Transition};
              top: 0;
              left: ${rem(-1)};
              height: 100%;
              width: ${rem(2)};
              background-color: ${color.White};
              opacity: 0;
            }
            strong {
              font-size: ${rem(14)};
              font-weight: 400;
              display: inline-block;
              color: ${color.DarkBlack};
            }
            .fleetsTableCounter {
              font-size: ${rem(16)};
              font-weight: 500;
            }
            .flagcontentCountryCode {
              font-size: ${rem(14)};
              font-weight: 400;
              color: ${color.DarkBlack};
            }
            .icon {
              line-height: 0;
              visibility: hidden;
            }
          }
          .MuiTableCell-body {
            .type-item {
              font-weight: 500;
            }
          }
        }
      }
    }
    &.isSticky {
      .MuiTable-root {
        .MuiTableHead-root {
          z-index: 20;
          filter: drop-shadow(0 ${rem(4)} ${rem(4)} ${rgba(color.Black, 0.2)});
        }
      }
    }
  }
`;
