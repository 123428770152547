import ChevronIcon from "./ChevronIcon";

const SortIcon = () => {
  return (
    <span className="icon icon-sorticon">
      <span className="up-icon">
        <ChevronIcon />
      </span>
      <span className="down-icon">
        <ChevronIcon />
      </span>
    </span>
  );
};

export default SortIcon;
