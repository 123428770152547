import { useMemo } from "react";
import { useAppSelector } from "hooks";
import { parseDateRange } from "components/Filters/DateRangePicker";
import { AppActivityFilter } from "@shipin/shipin-app-server-client";
import { ActivitiesFilter, Blacklist, OperationType, Severity } from "@shipin/proto-activity-client/activity";
import { convertToTimestamp } from "../utils";

/**
 * Hook that returns current selection of filters.
 */
function useFilters() {
  // TODO: Subscribing to tableFilterBar will also add subscription for
  // activeFilters which is not required. Refactor tableFilterBar slice
  // once this common hook is used everywhere to eliminate subscription
  // for active filters and reduce unnecessary re-renders.
  const {
    severityFilter,
    fleetsFilter,
    vesselsFilter,
    vesselTypeFilter,
    activityFilter,
    areaNameFilter,
    compartmentNameFilter,
    cameraFilter,
    activityNameFilter,
    tagsFilter,
    dateRange: { start, end, live, zoom },
    blacklist,
  } = useAppSelector((state) => state.tableFilterBar);
  const filter: Omit<AppActivityFilter, "start_dttm" | "end_dttm"> & { start_dttm: string; end_dttm: string } = useMemo(() => {
    const { getDateRange } = parseDateRange(start, end, live, zoom);
    const { min, max } = getDateRange();

    // When adding a new filter here, verify manually the sensros query API
    // We need to add it manually to the sensor query API
    return {
      severity: severityFilter?.length ? severityFilter : undefined,
      vessel_type: vesselTypeFilter?.length ? vesselTypeFilter : undefined,
      operation_type: activityFilter?.length ? activityFilter : undefined,
      vessel_id: vesselsFilter?.length ? vesselsFilter : undefined,
      relative_time: AppActivityFilter.relative_time.NONE,
      fleet_id: fleetsFilter !== "All" && fleetsFilter?.length !== 0 ? fleetsFilter : undefined,
      edge_id: undefined,
      start_dttm: min,
      end_dttm: max,
      activity_id: undefined,
      compartment_id: undefined,
      area_id: undefined,
      camera_id: cameraFilter?.length ? cameraFilter : undefined,
      feed_id: undefined,
      compartment_name: compartmentNameFilter?.length ? compartmentNameFilter : undefined,
      area_name: areaNameFilter?.length ? areaNameFilter : undefined,
      title: activityNameFilter?.length ? activityNameFilter : undefined,
      blacklist: blacklist,
      tag_id: tagsFilter?.length ? tagsFilter : undefined,
    };
  }, [
    start,
    end,
    live,
    zoom,
    severityFilter,
    vesselTypeFilter,
    activityFilter,
    vesselsFilter,
    fleetsFilter,
    cameraFilter,
    compartmentNameFilter,
    areaNameFilter,
    activityNameFilter,
    blacklist,
    tagsFilter,
  ]);

  return filter;
}

export function useProtoFilters(): ActivitiesFilter {
  const {
    severityFilter,
    fleetsFilter,
    vesselsFilter,
    activityFilter,
    areaNameFilter,
    compartmentNameFilter,
    cameraFilter,
    activityNameFilter,
    dateRange: { start, end, live, zoom },
    blacklist,
    vesselTypeFilter,
    tagsFilter,
    navigationStatusFilter,
    beaufortFilter,
    distanceFilter,
    speedFilter,
    visibilityFilter,
    phaseOfDaysFilter,
  } = useAppSelector((state) => state.tableFilterBar);

  return useMemo(() => {
    const { getDateRange } = parseDateRange(start, end, live, zoom);
    const { min, max } = getDateRange();

    const result: ActivitiesFilter = {
      cameraIds: cameraFilter ?? [],
      placementIds: [],
      vesselTypes: vesselTypeFilter ?? [],
      activityIds: [],
      duration: undefined,
      tagIds: tagsFilter ?? [],
      feedIds: [],
      sectionIds: [],
      edgeIds: [],
      startTimestamp: convertToTimestamp(min),
      endTimestamp: convertToTimestamp(max),
      beauforts: beaufortFilter,
      distanceFromShores: distanceFilter,
      geoFencings: [],
      navigationStatuses: navigationStatusFilter,
      phaseOfDays: phaseOfDaysFilter,
      vesselSpeeds: speedFilter,
      visibilities: visibilityFilter,
      workflowStatuses: [],
      fleetIds: fleetsFilter === "All" ? [] : fleetsFilter,
      operationTypes: (activityFilter ?? [])?.map((value) => {
        if (value === "CARGO") {
          return OperationType.CARGO;
        } else if (value === "SAFETY") {
          return OperationType.SAFETY;
        } else if (value === "MAINTENANCE") {
          return OperationType.MAINTENANCE;
        } else if (value === "BUNKERING") {
          return OperationType.BUNKERING;
        } else if (value === "BRIDGE") {
          return OperationType.BRIDGE;
        } else {
          return OperationType.UNSPECIFIED;
        }
      }),
      compartmentNames: compartmentNameFilter ?? [],
      areaNames: areaNameFilter ?? [],
      severities: (severityFilter ?? [])?.map((value) => {
        if (value === "Routine") {
          return Severity.ROUTINE;
        } else if (value === "Attention") {
          return Severity.ATTENTION;
        } else if (value === "Alert") {
          return Severity.ALERT;
        } else {
          return Severity.UNSPECIFIED;
        }
      }),

      titles: activityNameFilter ?? [],
      vesselIds: vesselsFilter ?? [],
      blacklist: blacklist ? Blacklist.ONLY : Blacklist.EXCLUDE,
    };

    return result;
  }, [
    start,
    end,
    live,
    zoom,
    cameraFilter,
    vesselTypeFilter,
    tagsFilter,
    beaufortFilter,
    distanceFilter,
    navigationStatusFilter,
    speedFilter,
    visibilityFilter,
    fleetsFilter,
    activityFilter,
    compartmentNameFilter,
    areaNameFilter,
    severityFilter,
    activityNameFilter,
    vesselsFilter,
    blacklist,
    phaseOfDaysFilter,
  ]);
}
// TanStack's react-query does not support BigInt in queryKey, so we convert it to string
// It internally uses JSON.stringify which does not support serializing BigInt
export function protoFilterKey(requestType: string, filter: ActivitiesFilter, includeCameras?: boolean): any {
  const { startTimestamp, endTimestamp, blacklist, ...rest } = filter;
  return [
    requestType,
    Number(filter.startTimestamp?.seconds),
    Number(filter.endTimestamp?.seconds),
    blacklist.valueOf(),
    ...Object.values(rest),
    ...(includeCameras ? [includeCameras] : []),
  ];
}

export default useFilters;
