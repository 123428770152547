import { colors } from "ui/Theme/colors";
import { LabelButton } from "./Button.styles";
import { rem } from "config/variable.styles";

const style = { minWidth: rem(90) };

const Alert = () => (
  <LabelButton
    style={{
      ...style,
      backgroundColor: colors.red[500],
    }}
    className="bold white"
  >
    Alert
  </LabelButton>
);

const Attention = () => (
  <LabelButton
    style={{
      ...style,
      backgroundColor: colors.orange[400],
    }}
    className="bold white"
  >
    Attention
  </LabelButton>
);

const Routine = () => (
  <LabelButton
    style={{
      ...style,
      backgroundColor: colors.green[500],
    }}
    className="bold white"
  >
    Routine
  </LabelButton>
);

export { Alert, Attention, Routine };
