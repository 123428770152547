import { ChangeEvent, ReactNode, memo } from "react";
import { Skeleton } from "@material-ui/lab";
import { Box, SortDirection } from "@material-ui/core";
import { ActivityNotifConfig } from "@shipin/notifs-client/service";
import Tooltip, { TippyProps } from "@tippyjs/react";

import { Alert, Attention, Routine } from "ui/Button/SeverityButtons";
import { TableHeadCell, TableHeadRow, TableHead, TableItemRow, TableItemCell, Checkbox, colors, TableSort, SortIcon } from "ui";
import { NotificationSortBy } from "./NotificationConfig";
import { rem } from "config/variable.styles";

const style = { padding: "3px 0" };
const headerStyle = {
  ...style,
  marginRight: 5,
};

type ConfigFields = Exclude<keyof ActivityNotifConfig, "activityTitle" | "severity" | "operationType">;

const severities: Record<string, ReactNode> = {
  Routine: <Routine />,
  Attention: <Attention />,
  Alert: <Alert />,
};

const Loader = () => (
  <TableItemRow>
    <TableItemCell width={100}>
      <Skeleton width={80} />
    </TableItemCell>
    <TableItemCell width={500} style={{ fontSize: 14 }}>
      <Skeleton width={500} />
    </TableItemCell>
    {/* <TableItemCell>
      <Skeleton width={20} />
    </TableItemCell>
    <TableItemCell>
      <Skeleton width={20} />
    </TableItemCell> */}
    <TableItemCell>
      <Skeleton width={20} />
    </TableItemCell>
    <TableItemCell>
      <Skeleton width={20} />
    </TableItemCell>
  </TableItemRow>
);

interface TableHeaderProps {
  order: SortDirection;
  sortBy: NotificationSortBy;
  onSort: (order: SortDirection, sortBy: NotificationSortBy) => void;
  checked: Record<ConfigFields, boolean>;
  onCheck: (key: ConfigFields) => void;
  loading?: boolean;
  disabled?: boolean;
}
const TableHeader = (props: TableHeaderProps) => {
  const { order, sortBy, onSort, checked, onCheck, loading = false, disabled = false } = props;

  const getTableCellProps = (sortKey: NotificationSortBy) => ({
    sortDirection: sortBy === sortKey ? order : undefined,
    onClick: () => {
      const newOrder = sortBy === sortKey && order === "asc" ? "desc" : "asc";
      onSort(newOrder, sortKey);
    },
  });

  const getCheckboxProps = (key: ConfigFields) => ({
    disabled: loading || disabled,
    checked: loading ? false : checked[key],
    onClick: (e: React.MouseEvent) => e.stopPropagation(),
    onChange: (e: ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();
      onCheck(key);
    },
  });

  const getTooltipProps = (key: ConfigFields): TippyProps => ({
    content: <Box fontSize={rem(12)}>{!!checked[key] ? "Clear All" : "Select All"}</Box>,
    theme: "light",
    placement: "top",
    disabled: loading || disabled,
  });

  return (
    <TableHead>
      <TableHeadRow>
        <TableHeadCell style={{ paddingLeft: 18 }} {...getTableCellProps("severity")}>
          <TableSort IconComponent={SortIcon}>Severity</TableSort>
        </TableHeadCell>
        <TableHeadCell {...getTableCellProps("description")} width={500}>
          <TableSort IconComponent={SortIcon}>Description</TableSort>
        </TableHeadCell>
        {/* <TableHeadCell>
          <Tooltip {...getTooltipProps("inApp")}>
            <Checkbox {...getCheckboxProps("inApp")} style={headerStyle} />
          </Tooltip>
          <TableSort IconComponent={() => null}>In-App</TableSort>
        </TableHeadCell>
        <TableHeadCell>
          <Tooltip {...getTooltipProps("immediateEmail")}>
            <Checkbox {...getCheckboxProps("immediateEmail")} style={headerStyle} />
          </Tooltip>
          <TableSort IconComponent={() => null}>Email Notification</TableSort>
        </TableHeadCell> */}
        <TableHeadCell>
          <Tooltip {...getTooltipProps("dailyEmail")}>
            <Checkbox {...getCheckboxProps("dailyEmail")} style={headerStyle} />
          </Tooltip>
          <TableSort IconComponent={() => null}>Email Daily Summary</TableSort>
        </TableHeadCell>
        <TableHeadCell>
          <Tooltip {...getTooltipProps("weeklyEmail")}>
            <Checkbox {...getCheckboxProps("weeklyEmail")} style={headerStyle} />
          </Tooltip>
          <TableSort IconComponent={() => null}>Email Weekly Summary</TableSort>
        </TableHeadCell>
      </TableHeadRow>
    </TableHead>
  );
};

interface RowProps extends ActivityNotifConfig {
  onChange: (value: ActivityNotifConfig) => void;
}
const Row = memo((props: RowProps) => {
  const { activityTitle, severity, dailyEmail, weeklyEmail } = props;

  const getOnChange = (key: ConfigFields) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const { onChange, ...rest } = props;
    onChange({ ...rest, [key]: e.target.checked });
  };

  return (
    <TableItemRow>
      <TableItemCell width={100}>{severities[severity] ?? "-"}</TableItemCell>
      <TableItemCell width={350} style={{ fontSize: 14 }}>
        {activityTitle ?? "Detector title"}
      </TableItemCell>
      {/* <TableItemCell>
        <Checkbox onChange={getOnChange("inApp")} checked={inApp} color={colors.gray[500]} style={style} />
      </TableItemCell>
      <TableItemCell>
        <Checkbox onChange={getOnChange("immediateEmail")} checked={immediateEmail} color={colors.gray[500]} style={style} />
      </TableItemCell> */}
      <TableItemCell>
        <Checkbox onChange={getOnChange("dailyEmail")} checked={dailyEmail} color={colors.gray[500]} style={style} />
      </TableItemCell>
      <TableItemCell>
        <Checkbox onChange={getOnChange("weeklyEmail")} checked={weeklyEmail} color={colors.gray[500]} style={style} />
      </TableItemCell>
    </TableItemRow>
  );
});

export { Row, TableHeader, Loader };
