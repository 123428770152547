import { useTransfer } from "./useTransfer";

function useTransferFleets(fleetIds?: string[]) {
  return useTransfer({
    mode: "fleets",
    transform: (fleet) =>
      fleetIds?.includes(fleet.id) ? { id: fleet.id, name: fleet.name, selected: true } : { id: fleet.id, name: fleet.name, selected: false },
  });
}

export { useTransferFleets };
