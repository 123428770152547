import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  apiCallCount: 0,
  isAutoRefresh: true,
  manualRefresh: 0,
};

// created to refresh data from server automatically at intervals
const refreshTimerSlice = createSlice({
  name: "refreshTimer",
  initialState: initialState,
  reducers: {
    autoRefresh(state) {
      state.apiCallCount++;
      state.isAutoRefresh = true;
    },
    manualRefresh(state) {
      state.apiCallCount++;
      state.isAutoRefresh = false;
    },
    refreshManual(state) {
      state.manualRefresh++;
    },
  },
});

export const { autoRefresh, manualRefresh, refreshManual } = refreshTimerSlice.actions;
export default refreshTimerSlice.reducer;
