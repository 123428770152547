import { useQuery } from "@tanstack/react-query";
import { ActivitiesV1Service, NavigationTimelineResponse, ReportsV1Service } from "@shipin/shipin-app-server-client";
import { EngineRoomReportsAmchartsObject } from "types/Reports.types";
import { addDays, differenceInHours } from "date-fns";
import { getOptions } from "components/Timelines/utils";
import { navigationTimelineColors } from "components/Timelines/TimelineColor";
import { formatDate } from "utils";
import { useAppSelector } from "hooks";

function convertEngineRoomDataForChart(data: NavigationTimelineResponse, type: string) {
  const result: EngineRoomReportsAmchartsObject[] = [];

  data.timeline.forEach((data) => {
    const { start_dttm, end_dttm, status } = data;
    const port = !!data.current_port_unlocode ? `[${data.current_port_unlocode?.substr(0, 2)}]` : "";

    result.push({
      amc_fromDate: new Date(start_dttm),
      amc_toDate: new Date(end_dttm),
      type: "Navigation Status",
      color: navigationTimelineColors[status ?? "OTHER"],
      data: { Attention: 0, Alert: 0, Routine: 0 },
      value: data.status,
      port: data.current_port ? `${data.current_port} ${port}`.trim() : "",
    });
  });
  return result;
}

function useBridgeNavigationQuery({
  vessel,
  start,
  end,
}: {
  vessel:
    | {
        name: string;
        id: string;
      }
    | undefined;
  start: string;
  end: string;
}) {
  const vesselId = vessel?.id;
  const start_dttm = formatDate(start);
  const end_dttm = formatDate(end, "yyyy-MM-dd'T'23:59:59");

  const difference = differenceInHours(addDays(new Date(end_dttm), 1), new Date(start_dttm));
  const { resolution } = getOptions(difference);

  const fallback: EngineRoomReportsAmchartsObject = {
    amc_fromDate: new Date(start),
    amc_toDate: new Date(end),
    type: "Navigation Status",
    color: "#EFEFEF",
    data: { Attention: 0, Alert: 0, Routine: 0 },
  };

  const query = useQuery({
    queryKey: ["navigation-timeline", vessel, start, end],
    queryFn: () => {
      if (!vesselId) throw new Error("");
      return ActivitiesV1Service.postApiV1ActivitiesV1NavigationTimeline({
        vessel_id: vesselId,
        resolution,
        start_dttm: start_dttm,
        end_dttm: end_dttm,
      });
    },
    enabled: !!vesselId,
    structuralSharing: false,
    staleTime: 0,
    gcTime: 0,
    select: (res) => convertEngineRoomDataForChart(res, "navigation"),
  });
  return {
    ...query,
    isFulfilled: query.isSuccess || query.isError,
    data: !query.data || !query.data.length ? [fallback] : query.data,
  };
}

function useAlertSummaryQuery() {
  const filters = useAppSelector((state) => state.reportsFilterOld);
  const {
    vessel,
    compare_to,
    dateRange: { start, end },
  } = filters;

  return useQuery({
    queryKey: ["alert-summary-old-reports", filters],
    queryFn: async ({ signal }) => {
      if (!vessel?.id) return Promise.reject("Error");

      const promise = ReportsV1Service.postApiV1ReportsV1BridgeReportStats2({
        start_dttm: formatDate(start),
        end_dttm: formatDate(end, "yyyy-MM-dd'T'23:59:59"),
        vessel_id: vessel.id,
        ...(compare_to !== "INDUSTRY_BENCHMARK" ? { fleet_id: compare_to } : {}),
      });

      signal.addEventListener("abort", () => promise.cancel());

      return promise;
    },
    staleTime: 0,
    gcTime: 0,
    structuralSharing: false,
    select: (data) => data?.activities ?? [],
  });
}

export { useBridgeNavigationQuery, useAlertSummaryQuery };
