import { createAction, createReducer } from "@reduxjs/toolkit";

export const setTagSwitch = createAction<boolean>("tagFilterSwitch/setValue");

const tagSwitchReducer = createReducer(false, (builder) => {
  builder.addCase(setTagSwitch, (_, action) => {
    return action.payload;
  });
});

export default tagSwitchReducer;
