import { Button as MuiButton } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { rem, color, rgba, Transition } from "config/variable.styles";

export const StyledButton = withStyles(
  {
    root: {
      height: rem(35),
      minWidth: rem(112),
      background: color.navyBlue,
      border: `${rem(1)} solid`,
      borderColor: color.navyBlue,
      borderRadius: rem(25),
      fontSize: rem(14),
      fontWeight: 500,
      boxShadow: "inherit",
      color: color.White,
      padding: `0 ${rem(25)}`,
      transition: Transition,
      PointerEvent: "cursor",
      "&:hover": {
        boxShadow: "inherit",
        color: color.White,
        background: color.navyBlue,
      },
      "&.Mui-disabled": {
        background: "#e1e7ed",
        borderColor: "#e1e7ed",
      },
      "&.disable_button": {
        color: rgba(color.Black, 0.26),
        PointerEvent: "none",
      },
      "& .MuiCircularProgress-colorPrimary": {
        color: color.White,
        marginLeft: rem(10),
      },
      "&.small": {
        height: rem(32),
        padding: `0 ${rem(12)}`,
        minWidth: rem(80),
      },
    },
    outlinedSecondary: {
      background: color.WhiteFour,
      borderColor: color.CloudyBlue,
      color: color.DarkBlack,
      "&:hover": {
        background: color.CloudyBlue,
        color: color.DarkBlack,
        borderColor: color.CloudyBlue,
      },
    },
    label: {
      textTransform: "initial",
    },
  },
  { name: "button" }
)(MuiButton);
