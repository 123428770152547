const Refresh = () => {
  return (
    <span className="icon icon-refresh">
      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
        <path
          fill="#000"
          fillRule="nonzero"
          d="M22.848 10.578a.894.894 0 00-1.766.282l.003.016c.91 
        5.359-2.696 10.441-8.055 11.352-5.36.91-10.442-2.696-11.352-8.055C.768
        8.813 4.374 3.73 9.733 2.82a9.842 9.842 0 018.908 3.057l-3.96 1.32a.893.893
        0 10.564 1.696l5.364-1.788a.893.893 0 00.612-.848V.894a.894.894 0 10-1.788
        0V4.16C14.829-.22 7.547-.037 3.168 4.567c-4.378 4.604-4.196 11.886.408
        16.265 4.604 4.378 11.886 4.196 16.264-.408a11.504 11.504 0 003.008-9.846z"
        ></path>
      </svg>
    </span>
  );
};

export default Refresh;
