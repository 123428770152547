import { memo, useEffect, useState } from "react";
import Spinner from "components/Loaders/Spinner";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import { CircularProgress } from "@material-ui/core";
import { CommentCardContainer } from "../NotificationContent.styles";
import { Status } from "types/ReduxState/common.types";
import { isCancel } from "config/api";
import CommentsCard from "./CommentsCard";
import {
  CommentsV1Service,
  EnrichedCommentPlaceholderWithContext,
  GetRecentCommentsRequest,
  GetRecentCommentsResponse,
} from "@shipin/shipin-app-server-client";
import { isEmptyObject } from "utils";

interface CommentsTabListProps {
  commentFilter: `${GetRecentCommentsRequest.comment_filter}`;
}
const CommentsTabList = ({ commentFilter }: CommentsTabListProps) => {
  const [commentsData, setCommentsData] = useState<GetRecentCommentsResponse["comments"]>([]);
  const { lastElementRef, pageNumber, limit, setHasMore } = useInfiniteScroll();
  const [status, setStatus] = useState<Status>("loading");

  useEffect(() => {
    const request = {
      result_range: {
        limit,
        offset: pageNumber,
      },
      order: "desc",
      comment_filter: commentFilter,
    };
    if (pageNumber === 0) {
      setStatus("loading");
    } else {
      setStatus("refetching");
    }
    //@ts-ignore
    CommentsV1Service.postApiV1CommentsV1Recent(request)
      .then((res) => {
        if (isEmptyObject(res) || res.comments.length === 0 || res.comments.length < limit) {
          setHasMore(false);
        }
        setStatus("success");
        setCommentsData((prev) => {
          if (pageNumber === 0) {
            return res.comments;
          }
          return [...prev, ...res.comments];
        });
      })
      .catch((e) => {
        if (!isCancel(e)) {
          setStatus("error");
        }
      });
  }, [commentFilter, limit, pageNumber, setHasMore]);

  if (status === "loading") {
    return <Spinner />;
  }

  // Return if no recent comments are available
  if (!commentsData || (commentsData && commentsData.length === 0 && status !== "refetching" && ["success", "error"].includes(status))) {
    return (
      <CommentCardContainer>
        <span className="text">No comments to show</span>
      </CommentCardContainer>
    );
  }

  const handleUpdateComment = (id: string, status: `${EnrichedCommentPlaceholderWithContext.read_status}`) => {
    if (commentFilter === "UNREAD") {
      const unreadComments = commentsData.filter((comment) => comment.comment.id !== id);
      setCommentsData(unreadComments);
      return;
    }
    //update comments state according to read_status
    const updatedComments = commentsData?.map((comment) => {
      if (comment.comment.id === id) {
        //@ts-ignore
        comment.read_status = status;
      }
      return comment;
    });
    setCommentsData(updatedComments);
  };

  return (
    <div className="tabsRow">
      {commentsData.length > 0 &&
        commentsData?.map((element, index) => {
          return (
            <CommentsCard
              ref={commentsData.length === index + 1 ? lastElementRef : undefined}
              key={element.comment.id}
              id={element?.conversation_info?.activity_id}
              commentElement={element}
              onUpdateComment={handleUpdateComment}
            />
          );
        })}

      {status === "refetching" && (
        <div className="small-spinner">
          <CircularProgress className="small-spinner" />
        </div>
      )}
    </div>
  );
};

export default memo(CommentsTabList);
