import { useState, MouseEvent } from "react";
import { MenuProps } from "@material-ui/core";

interface UseMenuOptions {
  onClose?: () => void;
  id?: string;
  closeOnSelect?: boolean;
  className?: string;
}
function useMenu({ onClose = () => {}, id = "", closeOnSelect = false, className = "" }: UseMenuOptions = {}): [
  MenuProps,
  (e: MouseEvent<HTMLElement>) => void,
  (e: MouseEvent<HTMLElement>) => void
] {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleOpen = (e: MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e: MouseEvent<HTMLElement>) => {
    e?.stopPropagation();
    setAnchorEl(null);
    onClose();
  };

  return [
    {
      id,
      anchorEl,
      open: Boolean(anchorEl),
      keepMounted: true,
      elevation: 0,
      getContentAnchorEl: null,
      className: `custom-dropdown custom-action-dropdown ${className}`,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "right",
      },
      onClose: handleClose,
      MenuListProps: closeOnSelect
        ? {
            onClick: handleClose,
          }
        : undefined,
    },
    handleOpen,
    handleClose,
  ];
}

export default useMenu;
