import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "config/api";
import { FleetCoverageDayQuery, FleetCoverageDayResponse } from "types/Reports.types";
import { reports } from "constants/api/reports";
import { ERROR_MESSAGE } from "constants/api/common";
import { ReduxState } from "types/ReduxState/common.types";

const initialState: ReduxState<FleetCoverageDayResponse> = {
  status: "idle",
  data: null,
  error: null,
};

export const getFleetCoverageDay = createAsyncThunk("fleetCoverageDay", async (payload: FleetCoverageDayQuery, { rejectWithValue }) => {
  try {
    const response = await axios.post<FleetCoverageDayResponse>(reports.fleetCoverageDay, payload);
    return response.data;
  } catch (e) {
    return rejectWithValue(JSON.stringify(e.response?.data ?? ERROR_MESSAGE));
  }
});

const fleetCoverageSlice = createSlice({
  name: "fleetCoverageDay",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFleetCoverageDay.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(getFleetCoverageDay.fulfilled, (state, action) => {
      state.status = "success";
      state.data = action.payload;
      state.error = null;
    });
    builder.addCase(getFleetCoverageDay.rejected, (state, action) => {
      state.error = JSON.stringify(action.payload);
      state.status = "error";
      state.data = null;
    });
  },
});

export default fleetCoverageSlice.reducer;
