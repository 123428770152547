import { createContext, ReactNode, useContext } from "react";
import { TransferContext } from "../Transfer.types";

const Context = createContext<TransferContext | null>(null);
Context.displayName = "TransferContext";

function useTransfer() {
  const context = useContext(Context);

  if (!context) {
    throw new Error("Invalid call to useTransfer. Make sure you call useTransfer inside TransferProvider");
  }

  return context;
}

interface TransferProviderProps {
  children: ReactNode;
  value: TransferContext;
}
function TransferProvider(props: TransferProviderProps) {
  return <Context.Provider {...props} />;
}

export { useTransfer, TransferProvider };
