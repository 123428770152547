import React, { ReactNode } from "react";
import Critical from "../Icon/Critical";
import Attention from "../Icon/Attention";
import Routine from "../Icon/Routine";

import Error from "../Icon/Error";
import Info from "../Icon/Info";
import Success from "../Icon/Success";

interface Proptypes {
  children: ReactNode;
}

export function AlertToast({ children }: Proptypes) {
  return (
    <div className="toast-content">
      <div className="toast-content__icon">
        <Critical />
      </div>
      <div className="toast-content__info">
        <h3>Alert</h3>
        <p>{children}</p>
      </div>
    </div>
  );
}

export function AttentionToast({ children }: Proptypes) {
  return (
    <div className="toast-content">
      <div className="toast-content__icon">
        <Attention />
      </div>
      <div className="toast-content__info">
        <h3>Attention</h3>
        <p>{children}</p>
      </div>
    </div>
  );
}

export function RoutineToast({ children }: Proptypes) {
  return (
    <div className="toast-content">
      <div className="toast-content__icon">
        <Routine />
      </div>
      <div className="toast-content__info">
        <h3>Routine</h3>
        <p>{children}</p>
      </div>
    </div>
  );
}

export function ErrorToast({ children }: Proptypes) {
  return (
    <div className="toast-content">
      <div className="toast-content__icon">
        <Error />
      </div>
      <div className="toast-content__info">
        <h3>Error</h3>
        <p>{children}</p>
      </div>
    </div>
  );
}

export function InfoToast({ children }: Proptypes) {
  return (
    <div className="toast-content">
      <div className="toast-content__icon">
        <Info />
      </div>
      <div className="toast-content__info">
        <h3>Information</h3>
        <p>{children}</p>
      </div>
    </div>
  );
}

export function SuccessToast({ children }: Proptypes) {
  return (
    <div className="toast-content">
      <div className="toast-content__icon">
        <Success />
      </div>
      <div className="toast-content__info">
        <h3>Success</h3>
        <p>{children}</p>
      </div>
    </div>
  );
}
