import { useCallback, useEffect, useMemo, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import qs from "query-string";

type Query<T> = Partial<T> & { isEmptyQuery: boolean };
/**
 * Hook to parse query string and return values from a url
 */
function useQueryString<T extends Record<string, string | undefined>>(
  initialState: Partial<T> | undefined = undefined
): [Query<T>, (args: Partial<T>) => void, () => void] {
  const { search } = useLocation();
  const history = useHistory();
  const ref = useRef({ history, initialState });

  // set initial state if provided
  useEffect(() => {
    if (ref.current.initialState) {
      const query = !!Object.keys(ref.current.initialState).length ? `?${qs.stringify(ref.current.initialState)}` : "";
      ref.current.history.replace(query);
    }
  }, []);

  const query = useMemo(() => {
    const parsed = qs.parse(search);
    return { ...parsed, isEmptyQuery: Object.values(parsed).every((e) => !e || e === "false") };
  }, [search]) as Query<T>;

  const setQuery = useCallback((searchQuery: Partial<T>) => {
    const currentQuery = {
      ...qs.parse(window.location.search),
      ...searchQuery,
    };
    const filtered = Object.fromEntries(Object.entries(currentQuery).filter(([k, v]) => !!v || v === "false")) as Partial<T>;
    ref.current.history.replace(`?${qs.stringify(filtered)}`);
  }, []);

  const clearQuery = useCallback(() => {
    if (!window.location.search) {
      return;
    }
    ref.current.history.push({
      pathname: window.location.pathname,
    });
  }, []);

  return [query, setQuery, clearQuery];
}

export default useQueryString;
