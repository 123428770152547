import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WorkflowStatus } from "@shipin/proto-activity-client/activity";

type ActivityListFilter = {
  reviewed: "reviwed" | "unreviewed" | null;
  starred: boolean;
  workflowStatus: Array<WorkflowStatus>;
};

const initialState: ActivityListFilter = {
  reviewed: null,
  starred: false,
  workflowStatus: [],
};

const activityListFilter = createSlice({
  name: "activityListFilter",
  initialState,
  reducers: {
    setActivityListReviwedFilter(state, action: PayloadAction<ActivityListFilter["reviewed"]>) {
      state.reviewed = action.payload;
    },
    setActivityListStarredFilter(state, action: PayloadAction<boolean>) {
      state.starred = action.payload;
    },
    addActivityListWorkflowStatusFilter(state, action: PayloadAction<WorkflowStatus>) {
      state.workflowStatus.push(action.payload);
    },
    removeActivityListWorkflowStatusFilter(state, action: PayloadAction<WorkflowStatus>) {
      state.workflowStatus = state.workflowStatus.filter((status) => status !== action.payload);
    },
    selectAllActivityListFilter(state) {
      state.starred = true;
      state.workflowStatus = [WorkflowStatus.TO_DO, WorkflowStatus.IN_PROGRESS, WorkflowStatus.RESOLVED];
    },
    clearAllActivityListFilter(state) {
      state.starred = false;
      state.workflowStatus = [];
    },
  },
});

export const {
  setActivityListReviwedFilter,
  setActivityListStarredFilter,
  addActivityListWorkflowStatusFilter,
  removeActivityListWorkflowStatusFilter,
  selectAllActivityListFilter,
  clearAllActivityListFilter,
} = activityListFilter.actions;
export default activityListFilter.reducer;
