const SortIcon = () => {
  return (
    <svg className="sort-icon" width="16" height="19" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        className="up-icon"
        d="M7.23421 1.41189C7.63384 0.936014 8.36616 0.936013 8.76579 1.41189L13.5484 
        7.1069C14.0947 7.75753 13.6322 8.75 12.7826 8.75H3.21742C2.36781 8.75 1.90526 
        7.75753 2.45164 7.10691L7.23421 1.41189Z"
        fill="currentColor"
      />
      <path
        className="down-icon"
        d="M8.76579 18.5881C8.36616 19.064 7.63384 19.064 7.23421 18.5881L2.45164 
        12.8931C1.90526 12.2425 2.36781 11.25 3.21743 11.25L12.7826 11.25C13.6322 
        11.25 14.0947 12.2425 13.5484 12.8931L8.76579 18.5881Z"
        fill="currentColor"
      />
    </svg>
  );
};

export { SortIcon };
