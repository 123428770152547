import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { subDays } from "date-fns";
import { isEqual } from "lodash";

import { FleetReportFilter } from "types/FilterBar.types";

const date = new Date();
const initialState: FleetReportFilter = {
  fleet: undefined,
  compare_to: [],
  dateRange: {
    start: subDays(date, 29).toISOString(),
    end: date.toISOString(),
  },
};
const reportsFilter = createSlice({
  name: "reportsFilter",
  initialState,
  reducers: {
    setFleetFilter(state, action: PayloadAction<typeof initialState.fleet>) {
      state.fleet = action.payload;
    },
    setReportsDateRange(state, action: PayloadAction<typeof initialState.dateRange>) {
      state.dateRange = action.payload;
    },
    setCompareToFilter(state, action: PayloadAction<typeof initialState.compare_to>) {
      // If current and next state are same, don't update anything in slice.
      const currentState = [...state.compare_to].sort();
      const nextState = [...action.payload].sort();
      if (isEqual(currentState, nextState)) return;

      state.compare_to = action.payload;
    },
    setReportsFilter(state, action: PayloadAction<FleetReportFilter>) {
      state.compare_to = action.payload.compare_to;
      state.fleet = action.payload.fleet;
    },
  },
});

export const { setCompareToFilter, setReportsDateRange, setFleetFilter, setReportsFilter } = reportsFilter.actions;
export default reportsFilter.reducer;
