/**
 * @deprecated Old colors, use the new colors from the design system, defined in config/colors.ts
 */
export const color = {
  Primary: "#0854a0",
  WarmGrey: "#888888",
  White: "#ffffff",
  WhiteSmoke: "#f8f8f8",
  Black: "#000000",
  DarkBlack: "#333333",
  BrownishGrey: "#666666",
  CloudyBlue: "#d6dce5",
  Auburn: "#993d00",
  TurquoiseBlue: "#02b4de",
  DarkSkyBlue: "#31c3e6",
  DarkRoyalBlue: "#00016a",
  iceBlue: "#f7fbff",
  lightBlueGrey: "#e6eff9",
  rustyOrange: "#e26614",
  darkIndigo: "#081a2c",
  duckEggBlue: "#f1f6fc",
  paleOrange: "#ffbc57",
  turquoiseBlue: "#00c8c8",
  cocoa: "#65433b",
  iceBlueTwo: "#f9faff",
  grayWhite: "#f1f1f1",
  graywhiteTwo: "#dfdfdf",
  lightBlueGreyTwo: "#c0d0e7",
  paleGreyTwo: "#f7f8fb",
  coolBlue: "#4481be",
  silver: "#d1d7de",
  pinkishGrey: "#cccccc",
  paleGreyThree: "#eef3f9",
  coral: "#f05e5c",
  aquaMarine: "#3cdaac",
  darkBlue: "#030067",
  TurquoiseBlueThree: "#00b4de",
  cloudyBlueTwo: "#b3b2d1",
  warmGreyTwo: "#999999",
  darkBlueGreen: "#003328",
  offWhite: "#ffffec",
  whiteThree: "#f6f6f6",
  aqua: "#22bce1",
  steel: "#8e8e8f",
  lightBlueGreyThree: "#cdd6e7",
  paleGreyFour: "#eff0f5",
  lightPeriwinkle: "#e3e6fc",
  warmBlue: "#4253ce",
  warmGreyThree: "#808080",
  paleGreyFive: "#e6eaf5",
  paleGreysix: "#e0d7cb",
  iceBlueThree: "#fafbff",
  SkyBlue: "#01b2dd",
  brownishGreyTwo: "#656565",
  cloudyBlueThree: "#b1bccc",
  greenColor: "#2fc3a1",
  DarkMintGreen: "#3ddaac",
  WhiteFour: "#f2f2f2",
  purplishBlue: "#6f3aff",
  iceBlueFour: "#fcfdff",
  lightPeriwinkleTwo: "#c4dcfa",
  lighterPurple: "#7f4fff",
  perrywinkle: "#75a2d7",
  lightGray: "#C3C4C5",
  oceanBlue: "#0854a0",
  bgRed: "#eb2c62",
  JazzberryJamBgColor: "#a90c71",
  bgBrightTurquoise: "#1deada",
  bgHeliotrope: "#d58cff",
  bgYellow: "#ffd37c",
  solitudeGrayBg: "#E9EAED",
  solitudeLightGrayBg: "#F5F6F9",
  bgOrange: "#FF6A38",
  bgGray: "#E0D7CB",
  bgBlue: "#638FFF",
  skyBlueLight: "#94ddee",
  tableBorderColorSolitude: "#e5ebee",
  tableRowBgColorSolitude: "#f4f5f6",
  tableListBgColorSolitude: "#e1e2e5",
  navyBlue: "#0063c1",
  graywhiteThree: "#F5F6F9",
  graywhiteFor: "#ECF4FB",
  LightSkyBlue: "#94DDEE",
  solitude: "#e5ebee",
  SapphireDark: "#06283D",
  DarkBlackTwo: "#06283d",
  DarkBlackThree: "#3c3c3c",
  CharcoalBlackBg: "#464646",
  grayDark: "#e8ebef",
  powderBlue: "#c0d2ff",
  lightGrayTwo: "#f0f3ff",
  pearlBushBg: "#dfcfc4",
  deepSkyBlueBg: "#bdcfe1",
  fogColorBg: "#d3ccec",
  darkViolet: "#7d08ac",
  sherpaBlue: "#044546",
  hawkesBlueColor: "#e2e9f1",
  brown: "#A52828",
  dodgerBlueColor: "#0759be",
  amethystColor: "#8e38b8",
  santasGreyColor: "#8a8a8e",
  tomatoredColor: "#d6140a",
  lightningYellowColor: "#de840a",
  saffronYellowColor: "#f1c20a",
  emeraldColor: "#2fa841",
  fireEngineRedColor: "#d32421",
  aliceSlateBlueColor: "#e0e8ff",
  magnoliaColor: "#f6e8ff",
  magnoliaDarkColor: "#efd6ff",
  solitudeColor: "#fbfbfc",
  solitudelightColor: "#f4f4f6",
  solitudeDarkColor: "#edeef0",
  bgDarkBlue: "#638ffe",
  bgGainsboroDark: "#dedede",
  errorColor: "#842029",
  whiteSmokeColor: "#efefef",
  pattensBlueColor: "#f6f8fb",
  marinerBlueColor: "#4963b3",
  darkCyanColor: "#888896",
  gullGreyColor: "#9facb4",
  bgCommentDark: "#7f7f7f",
  commentTextGray: "#a5a5a5",
  commentBgGray: "#F3F5FC",
  disabledTextColor: "#cdcdcd",
  disabledBgColor: "#e9ecef",
  redColor: "#ff0000",
  BlueSecondary: "#040066",
  NeutralGray: "#212121",
  YellowPrimary: "#FF931D",
  commentHeaderBg: "#c4d2eb",
  commentCloseIconBg: "#727e93",
  bgHeliotropeDark: "#d58cff33",
  bgOrangeDark: "#ff6a3833",
  greenLightColor: "#16b25d",
  greenSpringBgColor: "#aabba9",
  skyBlueDark: "#97CDFF",
  darkergraybg: "#f0f4f8",
};

export const Transition = "all 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms";
export const CollapseTransition = "all 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms";

export const xl = (content: any) => `
  @media (max-width: 1366px) {
    ${content};
  }
`;

export const xxl = (content: any) => `
  @media only screen and (min-width: 1366px) and (max-width: 1750px) {
    ${content};
  }
`;

export const rem = (pxValue: any) => {
  const ratio = 16; // Set according to the project configuration ratio
  let pxValues: any;
  if (Array.isArray(pxValue)) {
    pxValue = pxValue[0];
  }
  pxValues = parseInt(pxValue);
  return pxValues / ratio + "rem";
};

const hexToRgb = (hex: string) => {
  // http://stackoverflow.com/a/5624139
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex?.replace(shorthandRegex, function (m: string, r: string, g: string, b: string) {
    return r + r + g + g + b + b;
  });
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return {
    r: result?.[1] ? parseInt(result?.[1], 16) : 0,
    g: result?.[2] ? parseInt(result?.[2], 16) : 0,
    b: result?.[3] ? parseInt(result?.[3], 16) : 0,
  };
};

export const rgba = (hex: string, alpha: number) => {
  let rgbColor = hexToRgb(hex);
  return `rgba(${rgbColor?.r}, ${rgbColor?.g}, ${rgbColor?.b}, ${alpha})`;
};
