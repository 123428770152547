import { ReactNode, Suspense } from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import { createTheme } from "@material-ui/core/styles";

import routes from "./config/routes";
import Header from "../src/components/Header/Header";
import { PrivateRoute, RBACRoute } from "./components/Auth";
import history from "./config/history";
import ToastContainer from "./components/Toastbar";
import { images } from "config/images";
import { useCurrentUser } from "queries";
import { isEdge } from "utils";
import { RefreshConfig } from "config/AutoRefresh";
import FullStoryConfig from "config/FullStoryConfig";
import { SingleViewProvider } from "context/SingleViewContext";

declare module "react-router-dom" {
  interface RouterProps {
    children?: ReactNode;
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#0854a0",
    },
    error: {
      main: "#f05e5c",
    },
  },
  typography: {
    fontFamily: "'Inter', sans-serif",
  },
  props: {
    MuiButton: {
      disableRipple: true,
    },
    MuiInput: {
      autoComplete: "off",
    },
    MuiTextField: {
      autoComplete: "off",
    },
  },
});

function App() {
  const { isLoading, data: user, enabled } = useCurrentUser();

  // Disabled queries will have no user, so we need to check for enabled
  if (isLoading || (!user && enabled)) {
    return (
      <div className="page-loader">
        <img src={images.pageLoader} alt="Loading..." />
      </div>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <SingleViewProvider>
        <Router history={history}>
          <Suspense
            fallback={
              <div className="page-loader">
                <img src={images.pageLoader} alt="Loading..." />
              </div>
            }
          >
            <RefreshConfig />
            <Switch>
              {(Object.keys(routes) as Array<keyof typeof routes>).map((routeKey) => {
                const { component: Component, isPrivate, requiredPermissions, ...rest } = routes[routeKey];
                const render = () => (
                  <>
                    <Header />
                    <Component />
                  </>
                );

                if (isPrivate) {
                  return <PrivateRoute key={routeKey} {...rest} render={() => <Component />} />;
                }
                if (requiredPermissions) {
                  return <RBACRoute key={routeKey} {...rest} requiredPermissions={requiredPermissions} render={render} />;
                }
                return <Route key={routeKey} {...rest} render={render} />;
              })}
              <Route path="*" exact>
                {/* Do not open dashboard onn edge */}
                <Redirect to={isEdge ? routes.activitiesdashboard.path : routes.homeDashboard.path} />
              </Route>
            </Switch>
          </Suspense>
        </Router>
        <ToastContainer />
        <FullStoryConfig />
      </SingleViewProvider>
    </ThemeProvider>
  );
}

export default App;
