import { useEffect, useRef, useState } from "react";

function useTableHeadHeight() {
  const [headHeight, setHeight] = useState(0);
  const headerRef = useRef<any>(null);

  useEffect(() => {
    const setTableHeadHeight = () => {
      if (!!headerRef.current && headerRef.current?.clientHeight) {
        setHeight(headerRef.current.clientHeight);
      }
    };

    const listenScrollEvent = () => {
      if (window.scrollY <= 50) {
        setTableHeadHeight();
      }
    };

    if (!!headerRef.current && headerRef.current?.clientHeight) {
      setTimeout(() => {
        setTableHeadHeight();
      }, 1000);
    }

    window.addEventListener("resize", setTableHeadHeight);
    window.addEventListener("scroll", listenScrollEvent);

    return () => {
      window.removeEventListener("resize", setTableHeadHeight);
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  return [headerRef, headHeight] as const;
}
export { useTableHeadHeight };
