import { useCallback, useState } from "react";
import { useNotificationCountQuery } from "queries";

import Notifications from "components/Icon/Notifications";
import { ActionBadge, HeaderActionIcon, NotificationsPopover } from "./Header.styles";
import { Tooltip } from "components/Tooltip/Tooltip.styles";
import NotificationContent from "components/NotificationContent/NotificationContent";

const NotificationsPanel = () => {
  const { data: notificationCount } = useNotificationCountQuery();
  const [notificationEl, setNotificationEl] = useState<HTMLElement | null>(null);

  const notificationClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setNotificationEl(event.currentTarget);
  };

  const notificationClose = useCallback(() => {
    setNotificationEl(null);
  }, []);

  const open = Boolean(notificationEl);
  const id = open ? "notificationPopover" : undefined;

  return (
    <>
      <ActionBadge onClick={notificationClick} badgeContent={notificationCount?.total} color="primary" overlap="rectangular">
        <Tooltip placement="top-end" arrow title="Notifications">
          <HeaderActionIcon
            onClick={notificationClick}
            className={!!notificationEl ? "open" : ""}
            aria-describedby="notificationPopover"
            aria-label="notification"
          >
            <Notifications />
          </HeaderActionIcon>
        </Tooltip>
      </ActionBadge>
      <NotificationsPopover
        id={id}
        open={open}
        anchorEl={notificationEl}
        onClose={notificationClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <NotificationContent />
      </NotificationsPopover>
    </>
  );
};

export default NotificationsPanel;
