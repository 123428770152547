import Skeleton from "components/Loaders/Skeleton";
import { ErrorToast } from "components/Toastbar";
import { images } from "config/images";
import { FormikErrors } from "formik";
import { Dispatch, SetStateAction, useState } from "react";
import { toast } from "react-toastify";
import { ProfileThumbStyle } from "./ProfileThumb.styles";
import { Button } from "ui";
import { Plus } from "ui/Icons";
import { Box } from "@material-ui/core";
import { useCurrentUser } from "queries";

interface PropsType {
  image_url: string;
  loading?: boolean;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) =>
    | Promise<void>
    | Promise<
        FormikErrors<{
          image_url: string;
        }>
      >;
  setProfileEditMode: Dispatch<SetStateAction<boolean>>;
  profileEditMode: boolean;
}
const ProfileThumb = ({ image_url, setFieldValue, loading, profileEditMode, setProfileEditMode }: PropsType) => {
  const { data: userProfile } = useCurrentUser();
  const [selectedImage, setSelectedImage] = useState<string | ArrayBuffer | null>(null);
  const profileImage = profileEditMode ? selectedImage : image_url || images.placeholderProfile;

  return (
    <ProfileThumbStyle>
      <Skeleton
        loading={loading}
        animation="wave"
        height={200}
        width={200}
        style={{ transform: "inherit", borderRadius: "100%", display: "inline-block" }}
      >
        <img src={profileImage} onError={(e) => (e.currentTarget.src = images.placeholderProfile)} alt="Profile" />
      </Skeleton>
      <div className="edit-btn">
        <input
          accept="image/*"
          id="image_url-file"
          name="image_url"
          type="file"
          multiple={false}
          onChange={(event) => {
            if (event?.target?.files) {
              const file = event.target.files[0];
              if (file?.size > 2 * 1000 * 1000) {
                return toast.error(<ErrorToast>Image size can not exceed 2MB.</ErrorToast>);
              }

              if (file) {
                setFieldValue("image_url", file);
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onloadend = function () {
                  setProfileEditMode(true);
                  setSelectedImage(reader.result);
                };
              }
            }
            // Reset the input value to allow the same file to be uploaded again
            // In case user cancels the file selection and then selects the same file again
            event.target.value = "";
          }}
        />
        <div className="actions">
          {!profileEditMode ? (
            <label className="label" role="button" htmlFor="image_url-file">
              <Plus />
              Upload Photo
            </label>
          ) : (
            <>
              <Box display="flex" gridGap="8px" justifyContent="center">
                <Button type="submit" loading={loading}>
                  Save Photo
                </Button>
                <Button
                  variant="secondary"
                  type="button"
                  disabled={loading}
                  onClick={() => {
                    setProfileEditMode(false);
                    setFieldValue("image_url", userProfile?.image_url || "");
                  }}
                >
                  Cancel
                </Button>
              </Box>
              <p>Please note, your new image won’t be saved if you leave the page without confirming.</p>
            </>
          )}
        </div>
      </div>
    </ProfileThumbStyle>
  );
};

export default ProfileThumb;
