export const SUCCESS_MESSASGE = "User profile updated successfully.";
export const EDIT_FIELD_REQUIREMENT = (title: string) => `${title} must not contain more than 50 characters.`;
export const BLANK_SPACE_ERROR = "Field cannot contain black space.";
export const BLANK_SPACE_REGEX = new RegExp(/^(?!\s+$)/);
export const SPECIAL_CHARACTERS_REGEX = new RegExp(/^[^*|"!#%^`~&*:.<>[\]{}`\\()';@&$]+$/);
export const SPECIAL_CHARACTERS_ERROR = "Special characters are not allowed.";
export const EMAIL_REGEX = new RegExp(/^[A-Za-z0-9_.+-]+@[A-Za-z0-9-]+\.[A-Za-z0-9-.]+$/);

export const user = {
  signedURL: (userID: string) => `/users/v1/${userID}/image`,
  listQuery: "/users/v1/query",
  createUser: "/users/v1/create",
  permissions: "users/v1/get-user-permissions",
};
