import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AISState } from "types/ReduxState/AISState.types";
import axios from "config/api";
import { map } from "constants/api/map";
import { ERROR_MESSAGE } from "constants/api/common";
import { AISResponse, AISRequest } from "types/AIS.types";

const initialState: AISState = {
  status: "idle",
  error: null,
  data: null,
};
export const fetchAISData = createAsyncThunk("AISService", async (payload: AISRequest, thunkAPI) => {
  try {
    const response = await axios.post<AISResponse>(map.AIS, payload);
    return response.data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err?.response?.msg || ERROR_MESSAGE);
  }
});
const AISSlice = createSlice({
  name: "AIS",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAISData.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchAISData.fulfilled, (state, action) => {
      state.status = "success";
      if (action.payload) {
        state.data = action.payload;
        state.error = null;
      }
    });
    builder.addCase(fetchAISData.rejected, (state, action) => {
      state.error = JSON.stringify(action.payload);
      state.status = "error";
      state.data = null;
    });
  },
});

export default AISSlice.reducer;
