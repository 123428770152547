import { useQuery } from "@tanstack/react-query";
import { VesselsV1Service } from "@shipin/shipin-app-server-client";

import { queryKeys } from "config";
import { getCacheTime, isEdge } from "utils";

type Options = {
  vesselID: string | undefined;
  start_dttm: string | undefined;
  end_dttm: string | undefined;
};
function useWeatherQuery(options: Options) {
  const { vesselID, start_dttm, end_dttm } = options;
  const enabled = !!vesselID && !!start_dttm && !!end_dttm && !isEdge;

  return useQuery({
    ...queryKeys.weather["weather-info"](vesselID, start_dttm, end_dttm),
    queryFn: async () => {
      if (!vesselID || !start_dttm || !end_dttm) return Promise.reject("Cannot fetch");
      const data = await VesselsV1Service.postApiV1VesselsV1WeatherInfoRange({
        vessel_id: vesselID,
        start_dttm: start_dttm,
        end_dttm: end_dttm,
      });
      return data;
    },
    enabled,
    // Don't keep disabled queries in cache
    gcTime: getCacheTime(enabled),
  });
}

export { useWeatherQuery };
