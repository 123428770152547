import { DependencyList, EffectCallback, useEffect, useRef } from "react";

/**
 * Run side effects only when dependencies change, skipping componentDidMount phase.
 * @param callback Effect callback
 * @param deps Dependency List
 * @deprecated - Don't use this :)
 */
function useUpdateEffect(callback: EffectCallback, deps: DependencyList) {
  if (deps.length === 0) {
    throw new Error("DependencyList should not be empty for useUpdateEffect. use React.useEffect for such case.");
  }

  const mounted = useRef(false);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      return;
    }
    return callback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

export default useUpdateEffect;
