import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Box, SortDirection, TableContainer } from "@material-ui/core";
import { ActivityNotifConfig, UpdateNotificationConfigResponse } from "@shipin/notifs-client/service";
import { toast } from "react-toastify";
import { isEqual } from "lodash";

import { Table, TableBody, Button } from "ui";
import { notifsClient, queryKeys } from "config";
import { useNotificationConfiguration } from "queries";

import { rem } from "config/variable.styles";
import { Loader, Row, TableHeader } from "./NotificationConfigUtils";
import { ErrorToast } from "components/Toastbar";
import TablePlaceholder from "components/TablePlaceholder/TablePlaceholder";

export type NotificationSortBy = "severity" | "description" | "inApp" | "email" | "emailDaily" | "emailWeekly";
export type NotificationState = Record<string, ActivityNotifConfig>;

function comparator(a: ActivityNotifConfig, b: ActivityNotifConfig, sortBy: NotificationSortBy) {
  switch (sortBy) {
    case "severity":
      return a.severity.localeCompare(b.severity);
    case "description":
      return a.activityTitle.localeCompare(b.activityTitle);
    case "inApp":
      return a.inApp ? 1 : -1;
    case "email":
      return a.immediateEmail ? 1 : -1;
    case "emailDaily":
      return a.dailyEmail ? 1 : -1;
    case "emailWeekly":
      return a.weeklyEmail ? 1 : -1;
  }
}

const NotificationConfig = () => {
  const [height, setHeight] = useState(0);
  // Store initial state to handle reset button click
  const initialState = useRef<NotificationState>({});
  const [order, setOrder] = useState<SortDirection>("asc");
  const [state, setState] = useState<NotificationState>({});
  const [sortBy, setSortBy] = useState<NotificationSortBy>("severity");
  const values = Object.values(state);

  const client = useQueryClient();
  const { data, isFetching: isLoading, isError } = useNotificationConfiguration();
  const { mutate, isPending } = useMutation<UpdateNotificationConfigResponse, any, ActivityNotifConfig[]>({
    mutationFn: (config) =>
      notifsClient.updateNotificationConfig({
        conf: {
          activityNotifConfigs: config,
        },
      }).response,
    onSuccess: () => {
      client.setQueryData(queryKeys.notifications.config.queryKey, values);
      initialState.current = state;
    },
    onError: () => {
      setState(initialState.current);
      toast.error(<ErrorToast>Failed to update notification settings. Please try again in a few minutes.</ErrorToast>);
    },
  });

  useLayoutEffect(() => {
    const elementHeight = document.querySelector("#profile-page-root")?.clientHeight;

    if (elementHeight) {
      setHeight(elementHeight);
    }
  }, []);

  useEffect(() => {
    if (!data?.length) return;

    const newState = data.reduce<NotificationState>((acc, curr) => {
      acc[curr.activityTitle + ":" + curr.severity + ":" + curr.operationType] = curr;
      return acc;
    }, {});

    setState(newState);
    initialState.current = newState;
  }, [data]);

  const notificationsConfig = values.sort((a, b) => {
    const result = comparator(a, b, sortBy);
    return order === "asc" ? result : -result;
  });

  // Check if all notifications are checked
  // using this in the table header
  // We can't calculate this with a single loop
  // If we try it using reduce, it requires initial values to be set true
  // It keeps checkbox checked initially for a few milliseconds
  const checked = {
    dailyEmail: notificationsConfig.length > 0 && notificationsConfig.every((config) => config.dailyEmail),
    inApp: notificationsConfig.length > 0 && notificationsConfig.every((config) => config.inApp),
    immediateEmail: notificationsConfig.length > 0 && notificationsConfig.every((config) => config.immediateEmail),
    weeklyEmail: notificationsConfig.length > 0 && notificationsConfig.every((config) => config.weeklyEmail),
  };

  const onChange = useCallback((value: ActivityNotifConfig) => {
    setState((prev) => ({
      ...prev,
      [value.activityTitle + ":" + value.severity + ":" + value.operationType]: value,
    }));
  }, []);

  // eslint-disable-next-line
  const disabled = useMemo(() => isEqual(Object.values(state), Object.values(initialState.current)), [state, initialState.current]);

  const renderTable = () => {
    if (isLoading) {
      return Array.from({ length: 20 }).map((_, index) => <Loader key={index} />);
    }

    return notificationsConfig.map((rowOptions, index) => <Row key={index} {...rowOptions} onChange={onChange} />);
  };

  return (
    <>
      <TableContainer
        style={{
          maxHeight: height - 190,
          minHeight: height - 190,
        }}
      >
        <Table stickyHeader>
          <TableHeader
            loading={isLoading}
            sortBy={sortBy}
            order={order}
            onSort={(order, sortBy) => {
              setOrder(order);
              setSortBy(sortBy);
            }}
            checked={checked}
            onCheck={(key) => {
              setState((prev) => {
                // Avoiding Object.fromEntries for better code readability
                const newState = Object.values(prev).map((value) => ({
                  ...value,
                  [key]: !checked[key],
                }));

                return newState.reduce<NotificationState>((acc, curr) => {
                  acc[curr.activityTitle + ":" + curr.severity + ":" + curr.operationType] = curr;
                  return acc;
                }, {});
              });
            }}
            disabled={notificationsConfig.length === 0}
          />
          <TableBody>{renderTable()}</TableBody>
        </Table>
        {(!data || data?.length === 0) && !isLoading && <TablePlaceholder style={{ minHeight: 280 }} isError={isError} />}
      </TableContainer>
      {!!notificationsConfig.length ? (
        <Box className="btns" display="flex" my={rem(25)} justifyContent="center" gridGap={rem(21)}>
          <Button variant="secondary" disabled={disabled || isPending} onClick={() => setState(initialState.current)}>
            Cancel
          </Button>
          <Button disabled={disabled} onClick={() => mutate(Object.values(state))} loading={isPending}>
            Save Changes
          </Button>
        </Box>
      ) : null}
    </>
  );
};

export default NotificationConfig;
