import { images } from "config/images";
import { forwardRef, useState } from "react";
import { CommentCardContainer } from "../NotificationContent.styles";
import { format } from "date-fns";
import routes from "config/routes";
import { Button, Menu, MenuItem } from "@material-ui/core";
import DownArrow from "components/Icon/DownArrow";
import { useVessels } from "hooks";
import { EnrichedCommentPlaceholderWithContext } from "@shipin/shipin-app-server-client";
import { useReadStatusMutation } from "queries";

interface CommentsCardProps {
  commentElement: EnrichedCommentPlaceholderWithContext;
  id: string | undefined;
  onUpdateComment: (id: string, status: `${EnrichedCommentPlaceholderWithContext.read_status}`) => void;
}
const CommentsCard = (props: CommentsCardProps, ref: any) => {
  const { commentElement, id, onUpdateComment } = props;
  const { read_status, comment, user_info, vessel_id } = commentElement;
  const read = read_status === "READ" || !read_status;

  const { data: vesselInfo } = useVessels(vessel_id);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { mutate: setReadStatus } = useReadStatusMutation({
    onSuccess: () => {
      setAnchorEl(null);
      onUpdateComment(comment.id, read ? "KEEP_UNREAD" : "READ");
    },
  });

  const open = Boolean(anchorEl);
  const fullName = user_info?.full_name ?? user_info?.username;
  const currentDate = new Date(comment.created_at);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  const handleReadClick = () => {
    setReadStatus({
      updates: [
        {
          comment_id: comment.id,
          //@ts-ignore
          read_status: read ? "KEEP_UNREAD" : "READ",
        },
      ],
    });
  };

  const handleCommentClick = () => {
    if (!id) return;
    const path = routes.homeDashboard.path;
    window.open(`${path}?activityID=${id}&commentId=${comment.id}`, "_blank");
    // Marking comment as read when user opens the comment view.
    if (read_status === "NEW") setTimeout(handleReadClick, 5000);
  };

  return (
    <CommentCardContainer onClick={handleCommentClick} status={read_status} ref={ref}>
      <div className="notificationWrap">
        <div className="notificationCol dropdown-box">
          <div className="notificationProfile">
            <img
              src={user_info?.image_url ?? images.placeholderProfile}
              onError={(e) => (e.currentTarget.src = images.placeholderProfile)}
              alt="Profile"
            />
          </div>
          <div className="notificationProfileContent">
            <div className="profileContentTitle">
              {fullName}
              <span> {!Array.isArray(vesselInfo) ? vesselInfo?.name : "-"}</span>
            </div>
            <div className="profileContentText">
              <p>{comment?.content?.text}</p>
              <span>
                {format(currentDate, "dd-MMM-yyyy")} {format(currentDate, "HH:mm")}
              </span>
            </div>

            <div className={`down-icon-row ${open ? "active" : ""}`}>
              <Button
                disableRipple
                className="down-arrow"
                id="down-button"
                aria-controls={open ? "notification-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <DownArrow />
              </Button>
              <Menu
                id="notification-menu"
                anchorEl={anchorEl}
                open={open}
                className="custom-dropdown align-left notification-action-dropdown"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "down-button",
                }}
                onClick={(e) => e.stopPropagation()}
              >
                <MenuItem onClick={handleReadClick}>Mark as {read ? "unread" : "read"}</MenuItem>
              </Menu>
            </div>
          </div>
        </div>
      </div>
    </CommentCardContainer>
  );
};

export default forwardRef(CommentsCard);
