import { ComponentPropsWithRef } from "react";
import { StyledButton } from "./Button.styles";
import { CircularProgress } from "@material-ui/core";

interface ButtonProps extends ComponentPropsWithRef<"button"> {
  loading?: boolean;
  variant?: "primary" | "secondary";
  leftIcon?: React.ReactNode;
}
const Button = (props: ButtonProps) => {
  const { loading, className, children, disabled, variant = "primary", leftIcon = null, ...rest } = props;

  return (
    <StyledButton {...rest} className={[className, variant === "secondary" ? "secondary" : ""].join(" ").trim()} disabled={disabled || loading}>
      {leftIcon}
      {children}
      {loading && <CircularProgress size={14} />}
    </StyledButton>
  );
};

export { Button };
