const DownArrow = () => {
  return (
    <span className="icon icon-down">
      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 17">
        <defs>
          <clipPath id="lk8i4lfzza">
            <path d="M1920 0v1080H0V0h1920z"></path>
          </clipPath>
        </defs>
        <g clipPath="url(#lk8i4lfzza)" transform="translate(-761 -22)">
          <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M763 27.5l6 6 6-6"></path>
        </g>
      </svg>
    </span>
  );
};

export default DownArrow;
