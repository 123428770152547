import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, createMigrate, MigrationManifest } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "./reducers";

const migration: MigrationManifest = {
  // @ts-ignore
  2: (state) => {
    //@ts-ignore
    const newState: Pick<RootState, "tableFilterBar" | "dashboard"> = {
      ...state,
    };
    const result: Pick<RootState, "tableFilterBar" | "dashboard"> = {
      ...newState,
      tableFilterBar: {
        ...newState.tableFilterBar,
        activeFilters: {
          ...newState.tableFilterBar.activeFilters,
          beaufort: false,
          distance: false,
          navigationStatus: false,
          phaseOfDays: false,
          speed: false,
          visibility: false,
        },
        beaufortFilter: [],
        distanceFilter: [],
        navigationStatusFilter: [],
        phaseOfDaysFilter: [],
        speedFilter: [],
        visibilityFilter: [],
      },
    };

    return result;
  },
};

const persistConfig = {
  key: "root",
  version: 2,
  storage,
  whitelist: ["tableFilterBar", "dashboard"],
  migrate: createMigrate(migration, { debug: false }),
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST"],
      },
    });
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
