import styled from "styled-components";
import { rem, color } from "config/variable.styles";

export const TransferBase = styled.div`
  display: flex;
  margin: 0 -${rem(9)};
  .spinner {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 150px;
  }
  .left-col {
    width: 50%;
    max-width: 50%;
    flex: 0 0 50%;
    padding: 0 ${rem(9)};
    position: relative;
    &:after {
      content: "";
      position: absolute;
      top: ${rem(30)};
      right: 0;
      height: calc(100% - ${rem(30)});
      width: ${rem(1)};
      background: ${color.CloudyBlue};
    }
  }
  .right-col {
    width: 50%;
    max-width: 50%;
    flex: 0 0 50%;
    padding: 0 ${rem(9)};
  }
  .transfer-label {
    font-size: ${rem(16)};
    display: block;
    margin: 0 0 ${rem(6)};
    color: ${color.DarkBlack};
  }
  .nodata-placeholder {
    min-height: ${rem(330)};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p {
      font-size: ${rem(13)};
      font-weight: 900;
      letter-spacing: -0.1px;
      color: ${color.DarkBlack};
      margin: ${rem(13)} 0 0 0;
      text-transform: uppercase;
    }
  }
`;
