import { ReactNode } from "react";
import { images } from "config/images";

interface NoDataIllustrationProps {
  children: ReactNode;
}
const NoDataIllustration = (props: NoDataIllustrationProps) => {
  const { children } = props;
  return (
    <div className="nodata-placeholder">
      <img className="icon" src={images.vesselsPlaceholder} alt={typeof children === "string" ? children : "No Data Available"} />
      <p>{children}</p>
    </div>
  );
};

export default NoDataIllustration;
