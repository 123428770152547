import { ToastContainer as ToastifyToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

export * from "./content";

const CloseButton = ({ closeToast }: { closeToast: any }) => (
  <IconButton className="MuiAlert-action" aria-label="close" color="inherit" onClick={closeToast}>
    <CloseIcon />
  </IconButton>
);

export const toastTypeClasses = {
  alert: "alert-toast",
  attention: "attention-toast",
  routine: "routine-toast",
};

function ToastContainer() {
  return (
    <ToastifyToastContainer
      position="top-right"
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      closeButton={CloseButton}
    />
  );
}

export default ToastContainer;
