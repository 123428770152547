export const fonts = {
  interBlackEOT: require("../assets/fonts/Inter-Black.eot"),
  interBlackWOFF2: require("../assets/fonts/Inter-Black.woff2"),
  interBlackWOFF: require("../assets/fonts/Inter-Black.woff"),
  interBlackTTF: require("../assets/fonts/Inter-Black.ttf"),
  interExtraBoldEOT: require("../assets/fonts/Inter-ExtraBold.eot"),
  interExtraBoldWOFF2: require("../assets/fonts/Inter-ExtraBold.woff2"),
  interExtraBoldWOFF: require("../assets/fonts/Inter-ExtraBold.woff"),
  interExtraBoldTTF: require("../assets/fonts/Inter-ExtraBold.ttf"),
  interBoldEOT: require("../assets/fonts/Inter-Bold.eot"),
  interBoldWOFF2: require("../assets/fonts/Inter-Bold.woff2"),
  interBoldWOFF: require("../assets/fonts/Inter-Bold.woff"),
  interBoldTTF: require("../assets/fonts/Inter-Bold.ttf"),
  interExtraLightEOT: require("../assets/fonts/Inter-ExtraLight.eot"),
  interExtraLightWOFF2: require("../assets/fonts/Inter-ExtraLight.woff2"),
  interExtraLightWOFF: require("../assets/fonts/Inter-ExtraLight.woff"),
  interExtraLightTTF: require("../assets/fonts/Inter-ExtraLight.ttf"),
  interLightEOT: require("../assets/fonts/Inter-Light.eot"),
  interLightWOFF2: require("../assets/fonts/Inter-Light.woff2"),
  interLightWOFF: require("../assets/fonts/Inter-Light.woff"),
  interLightTTF: require("../assets/fonts/Inter-Light.ttf"),
  interMediumEOT: require("../assets/fonts/Inter-Medium.eot"),
  interMediumWOFF2: require("../assets/fonts/Inter-Medium.woff2"),
  interMediumWOFF: require("../assets/fonts/Inter-Medium.woff"),
  interMediumTTF: require("../assets/fonts/Inter-Medium.ttf"),
  interSemiBoldEOT: require("../assets/fonts/Inter-SemiBold.eot"),
  interSemiBoldWOFF2: require("../assets/fonts/Inter-SemiBold.woff2"),
  interSemiBoldWOFF: require("../assets/fonts/Inter-SemiBold.woff"),
  interSemiBoldTTF: require("../assets/fonts/Inter-SemiBold.ttf"),
  interRegularEOT: require("../assets/fonts/Inter-Regular.eot"),
  interRegularWOFF2: require("../assets/fonts/Inter-Regular.woff2"),
  interRegularWOFF: require("../assets/fonts/Inter-Regular.woff"),
  interRegularTTF: require("../assets/fonts/Inter-Regular.ttf"),
  interThinEOT: require("../assets/fonts/Inter-Thin.eot"),
  interThinWOFF2: require("../assets/fonts/Inter-Thin.woff2"),
  interThinWOFF: require("../assets/fonts/Inter-Thin.woff"),
  interThinTTF: require("../assets/fonts/Inter-Thin.ttf"),
};
