/**
 * This is a custom chip developed from scratch.
 * MUI Chip is not used because it has a lot of unnecessary overhead.
 */

import { CSSProperties, ComponentProps, PropsWithChildren } from "react";
import styled from "styled-components";
import { color, rem } from "config/variable.styles";

const ChipBase = styled.span<Required<Pick<ChipProps, "background" | "radius" | "whitespace">>>`
  border-radius: ${({ radius }) => radius};
  background-color: ${({ background }) => background};
  padding: ${rem(3)} ${rem(10)} ${rem(4)} ${rem(10)};
  font-weight: 400;
  font-size: ${rem(14)};
  line-height: ${rem(17)};
  white-space: ${({ whitespace }) => whitespace};
`;

interface ChipProps extends Pick<ComponentProps<"span">, "role" | "style"> {
  className?: string;
  background?: CSSProperties["backgroundColor"];
  radius?: CSSProperties["borderRadius"];
  whitespace?: CSSProperties["whiteSpace"];
}
const Chip = (props: PropsWithChildren<ChipProps>) => {
  const { children, className, role, background = color.paleGreyThree, style, radius = "5%", whitespace = "normal" } = props;

  return (
    <ChipBase role={role} background={background} className={className} style={style} radius={radius} whitespace={whitespace}>
      {children}
    </ChipBase>
  );
};

export { Chip };
