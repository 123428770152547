import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GroupBy } from "@shipin/analytics-client/service";

export type Point = {
  name: string;
  id: string;
};

type State = {
  performanceAnalyticsFleet: (Point & { groupBy: GroupBy.FLEET | GroupBy.VESSEL_TYPE }) | null | "showAll";
  performanceAnalyticsVessel: Point | null;
};
const initialState: State = {
  performanceAnalyticsFleet: null,
  performanceAnalyticsVessel: null,
};
const performanceAnalyticsSelector = createSlice({
  name: "reportsSelector",
  initialState,
  reducers: {
    setPerformanceAnalyticsFleet(state, action: PayloadAction<State["performanceAnalyticsFleet"]>) {
      state.performanceAnalyticsFleet = action.payload;
    },
    setPerformanceAnalyticsVessel(state, action: PayloadAction<State["performanceAnalyticsVessel"]>) {
      state.performanceAnalyticsVessel = action.payload;
    },
  },
});

export const { setPerformanceAnalyticsFleet, setPerformanceAnalyticsVessel } = performanceAnalyticsSelector.actions;
export default performanceAnalyticsSelector.reducer;
