import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BenchmarkType, GroupBy, ScoreGroupName } from "@shipin/analytics-client/service";
import { ranges } from "components/PerformanceAnalytics/Filters/DateFilter";

// 3M range
const range = ranges[2];

const initialState: {
  dateRange: {
    start: string;
    end: string;
  };
  fleet: string[];
  vessel: string[];
  benchmark: BenchmarkType[];
  scoreCard:
    | {
        score: ScoreGroupName | "All";
        type: "group";
        name: string;
      }
    | {
        kpiId: number;
        type: "kpi";
        name: string;
        activityTitles: string[];
      };
  vesselType: string[];
  // omitting UNSPECIFIED
  groupBy: GroupBy.FLEET | GroupBy.VESSEL_TYPE;
  showAverageLine: boolean;
} = {
  dateRange: {
    start: range.startDate,
    end: range.endDate,
  },
  fleet: [],
  vessel: [],
  benchmark: [BenchmarkType.P25, BenchmarkType.P75],
  scoreCard: {
    type: "group",
    score: "All",
    name: "Overall Performance Score",
  },
  vesselType: [],
  groupBy: GroupBy.FLEET,
  showAverageLine: true,
};

const performanceAnalyticsFilter = createSlice({
  name: "performanceAnalyticsFilter",
  initialState,
  reducers: {
    setPerformanceAnalyticsDateRange(state, action: PayloadAction<typeof initialState.dateRange>) {
      state.dateRange = action.payload;
    },
    setPerformanceAnalyticsFleetsFilter(state, action: PayloadAction<typeof initialState.fleet>) {
      state.fleet = action.payload;
    },
    setPerformanceAnalyticsVesselsFilter(state, action: PayloadAction<typeof initialState.vessel>) {
      state.vessel = action.payload;
    },
    setPerformanceAnalyticsBenchmark(state, action: PayloadAction<typeof initialState.benchmark>) {
      state.benchmark = action.payload;
    },
    setPerformanceAnalyticsScoreCard(state, action: PayloadAction<typeof initialState.scoreCard>) {
      state.scoreCard = action.payload;
    },
    setVesselType(state, action: PayloadAction<typeof initialState.vesselType>) {
      state.vesselType = action.payload;
    },
    setGroupBy(state, action: PayloadAction<typeof initialState.groupBy>) {
      state.groupBy = action.payload;
    },
    setShowAverageLine(state, action: PayloadAction<boolean>) {
      if (state.showAverageLine === action.payload) return;
      state.showAverageLine = action.payload;
    },
    removeFromFleetandVessel(state, action: PayloadAction<{ fleet: string[]; vessel: string[] }>) {
      if (!!action.payload.vessel.length) {
        state.vessel = state.vessel.filter((vessel) => !action.payload.vessel.includes(vessel));
      }
      if (!!action.payload.fleet.length) {
        state.fleet = state.fleet.filter((fleet) => !action.payload.fleet.includes(fleet));
      }
    },
    removeFromVesselType(state, action: PayloadAction<string[]>) {
      state.vesselType = state.vesselType.filter((vesselType) => !action.payload.includes(vesselType));
    },
  },
});

export const {
  setPerformanceAnalyticsDateRange,
  setPerformanceAnalyticsFleetsFilter,
  setPerformanceAnalyticsVesselsFilter,
  setPerformanceAnalyticsBenchmark,
  setPerformanceAnalyticsScoreCard,
  setVesselType,
  setGroupBy,
  setShowAverageLine,
  removeFromFleetandVessel,
  removeFromVesselType,
} = performanceAnalyticsFilter.actions;
export default performanceAnalyticsFilter.reducer;
