import { PropsWithChildren } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { GlobalPermissions } from "@shipin/shipin-app-server-client";

import { routes, images } from "config";
import { useCurrentUser, usePermissions, usePermissionsQuery } from "queries";

interface RBACRendererProps {
  /**
   * Array of permissions that are required to render the children
   */
  requiredPermissions: Array<keyof GlobalPermissions>;
  /**
   * If true, all permissions must be met.
   * If false, only one permission must be met.
   * Defaults to true.
   */
  matchAll?: boolean;
}
const RBACRenderer = (props: PropsWithChildren<RBACRendererProps>) => {
  const { requiredPermissions, children, matchAll = true } = props;
  const permissions = usePermissions();

  const operation = matchAll ? "every" : "some";
  const match = requiredPermissions[operation]((permission) => permissions?.[permission]);

  if (match) {
    return <>{children}</>;
  }

  return null;
};

/**
 * Here, private route will not be used for authentication.
 * It will be used to redirect user to home dashboard if user is already logged in.
 * If user is not logged in, it will render the component.
 * We protect /error route.
 */
const PrivateRoute = ({ component: Component, ...rest }: RouteProps) => {
  const { data: user } = useCurrentUser();

  if (!!user) {
    return <Redirect to={routes.homeDashboard.path} />;
  }

  return <Route {...rest} />;
};

const RBACRoute = ({ component: Component, requiredPermissions, ...rest }: RouteProps & Pick<RBACRendererProps, "requiredPermissions">) => {
  const { isLoading: isUserLoading } = useCurrentUser();
  const { data, isPending: isPermissionLoading } = usePermissionsQuery();
  const match = requiredPermissions.every((permission) => data?.permissions?.[permission]);

  if (isUserLoading || isPermissionLoading) {
    return (
      <div className="page-loader">
        <img src={images.pageLoader} alt="Loading..." />
      </div>
    );
  }

  if (!match) {
    return <Redirect to={routes.homeDashboard.path} />;
  }

  return <Route {...rest} />;
};

export { RBACRenderer, PrivateRoute, RBACRoute };
