export const images = {
  shipImage: require("../assets/images/ship-image.png"),
  vesselIcon: require("../assets/images/vessel-icon.svg").default,
  vesselIconSimple: require("../assets/images/vessel-icon-simple.svg").default,
  barCircle: require("../assets/images/bar-circle-icon.svg").default,
  barPin: require("../assets/images/bar-pin-icon.svg").default,
  barTriangle: require("../assets/images/bar-triangle-blue.svg").default,
  polygonRight: require("../assets/images/polygon-right.svg").default,
  calendarIcon: require("../assets/images/calendar-icon.svg").default,
  profileImage: require("../assets/images/profile-image.jpg"),
  pageLoader: require("../assets/images/page-loader.gif"),
  placeholderProfile: require("../assets/images/placeholder-profile.jpg"),
  imagePlaceholder: require("../assets/images/placeholder-image.jpg"),
  daigramImage: require("../assets/images/daigram-image.png"),
  mapPlaceholderImage: require("../assets/images/map-placeholder-image.png"),
  crossArrowIcon: require("../assets/images/cross-arrow-icon.svg").default,
  downArrowIcon: require("../assets/images/down-arrow-icon.svg").default,
  scrollbarGrip: require("../assets/images/grip.svg").default,
  scrollbarGripHighlighted: require("../assets/images/grip-highlited.svg").default,
  vesselsPlaceholder: require("../assets/images/vessels-placeholder-icon.svg").default,
  messageImage: require("../assets/images/chat-icon.svg").default,
  vesselArrow: require("../assets/images/trail-arrow.svg").default,
  yellowBlackArrow: require("../assets/images/trail-yellowblack-arrow.svg").default,
  vesselDot: require("../assets/images/map-dot-circle.svg").default,
  yellowBlackDot: require("../assets/images/map-yellowblack-circle.svg").default,
  yellowArrow: require("../assets/images/trail-yellow-arrow.svg").default,
  redArrow: require("../assets/images/trail-red-arrow.svg").default,
  greenArrow: require("../assets/images/trail-green-arrow.svg").default,
  yellowDot: require("../assets/images/map-yellow-circle.svg").default,
  redDot: require("../assets/images/map-red-circle.svg").default,
  greenDot: require("../assets/images/map-green-circle.svg").default,
  loginBanner: require("../assets/images/login-banner.svg").default,
};

export const timelineLegends = {
  navigation: { image: require("../assets/TimelineLegends/Navigation.jpg"), style: { width: "290px", height: "184px" } },
  daynight: { image: require("../assets/TimelineLegends/DayNight.png"), style: { height: "102px", width: "151px" } },
  visibility: { image: require("../assets/TimelineLegends/Visibility.jpg"), style: { height: "146px", width: "143px" } },
  beaufort: { image: require("../assets/TimelineLegends/Beaufort.jpg"), style: { height: "281px", width: "127px" } },
};

export const vesselsIcon: { [key: string]: string } = {
  TANKER: require("../assets/VesselTypes/tanker.svg").default,
  PASSENGER: require("../assets/VesselTypes/passenger.svg").default,
  SERVICE: require("../assets/VesselTypes/service.svg").default,
  OTHER: require("../assets/VesselTypes/other.svg").default,
  CARGO: require("../assets/VesselTypes/dry-cargo.svg").default,
  ALL: require("../assets/VesselTypes/all.svg").default,
};

export const icons: Record<string, any> = {
  "0": {
    default: {
      "0": require("assets/VesselMapIcons/Severity/_default_underway.svg").default,
      dimmed: require("assets/VesselMapIcons/Dimmed/_default_underway.svg").default,
      selected: require("assets/VesselMapIcons/Selected/_default_underway.svg").default,
      ghosted: require("assets/VesselMapIcons/Severity/_default_underway.svg").default,
    },
    Alert: {
      dimmed: require("assets/VesselMapIcons/Dimmed/_alert_underway.svg").default,
      ghosted: require("assets/VesselMapIcons/Severity/_alert_underway.svg").default,
      hovered: require("assets/VesselMapIcons/Severity/_alert_underway.svg").default,
      selected: require("assets/VesselMapIcons/Selected/_alert_underway.svg").default,
      default: require("assets/VesselMapIcons/Severity/_alert_underway.svg").default,
    },
    Attention: {
      dimmed: require("assets/VesselMapIcons/Dimmed/_attention_underway.svg").default,
      ghosted: require("assets/VesselMapIcons/Severity/_attention_underway.svg").default,
      hovered: require("assets/VesselMapIcons/Severity/_attention_underway.svg").default,
      selected: require("assets/VesselMapIcons/Selected/_attention_underway.svg").default,
      default: require("assets/VesselMapIcons/Severity/_attention_underway.svg").default,
    },
    Routine: {
      dimmed: require("assets/VesselMapIcons/Dimmed/_routine_underway.svg").default,
      ghosted: require("assets/VesselMapIcons/Severity/_routine_underway.svg").default,
      hovered: require("assets/VesselMapIcons/Severity/_routine_underway.svg").default,
      selected: require("assets/VesselMapIcons/Selected/_routine_underway.svg").default,
      default: require("assets/VesselMapIcons/Severity/_routine_underway.svg").default,
    },
  },
  "5": {
    default: {
      "0": require("assets/VesselMapIcons/Severity/_default_moored.svg").default,
      dimmed: require("assets/VesselMapIcons/Dimmed/_default_moored.svg").default,
      selected: require("assets/VesselMapIcons/Selected/_default_moored.svg").default,
    },
    Alert: {
      dimmed: require("assets/VesselMapIcons/Dimmed/_alert_moored.svg").default,
      ghosted: require("assets/VesselMapIcons/Severity/_alert_moored.svg").default,
      hovered: require("assets/VesselMapIcons/Severity/_alert_moored.svg").default,
      selected: require("assets/VesselMapIcons/Selected/_alert_moored.svg").default,
      default: require("assets/VesselMapIcons/Severity/_alert_moored.svg").default,
    },
    Attention: {
      dimmed: require("assets/VesselMapIcons/Dimmed/_attention_moored.svg").default,
      ghosted: require("assets/VesselMapIcons/Severity/_attention_moored.svg").default,
      hovered: require("assets/VesselMapIcons/Severity/_attention_moored.svg").default,
      selected: require("assets/VesselMapIcons/Selected/_attention_moored.svg").default,
      default: require("assets/VesselMapIcons/Severity/_attention_moored.svg").default,
    },
    Routine: {
      dimmed: require("assets/VesselMapIcons/Dimmed/_routine_moored.svg").default,
      ghosted: require("assets/VesselMapIcons/Severity/_routine_moored.svg").default,
      hovered: require("assets/VesselMapIcons/Severity/_routine_moored.svg").default,
      selected: require("assets/VesselMapIcons/Selected/_routine_moored.svg").default,
      default: require("assets/VesselMapIcons/Severity/_routine_moored.svg").default,
    },
  },
  "1": {
    default: {
      "0": require("assets/VesselMapIcons/Severity/_default_anchored.svg").default,
      dimmed: require("assets/VesselMapIcons/Dimmed/_default_anchored.svg").default,
      selected: require("assets/VesselMapIcons/Selected/_default_anchored.svg").default,
    },
    Alert: {
      dimmed: require("assets/VesselMapIcons/Dimmed/_alert_anchored.svg").default,
      ghosted: require("assets/VesselMapIcons/Severity/_alert_anchored.svg").default,
      hovered: require("assets/VesselMapIcons/Severity/_alert_anchored.svg").default,
      selected: require("assets/VesselMapIcons/Selected/_alert_anchored.svg").default,
      default: require("assets/VesselMapIcons/Severity/_alert_anchored.svg").default,
    },
    Attention: {
      dimmed: require("assets/VesselMapIcons/Dimmed/_attention_anchored.svg").default,
      ghosted: require("assets/VesselMapIcons/Severity/_attention_anchored.svg").default,
      hovered: require("assets/VesselMapIcons/Severity/_attention_anchored.svg").default,
      selected: require("assets/VesselMapIcons/Selected/_attention_anchored.svg").default,
      default: require("assets/VesselMapIcons/Severity/_attention_anchored.svg").default,
    },
    Routine: {
      dimmed: require("assets/VesselMapIcons/Dimmed/_routine_anchored.svg").default,
      ghosted: require("assets/VesselMapIcons/Severity/_routine_anchored.svg").default,
      hovered: require("assets/VesselMapIcons/Severity/_routine_anchored.svg").default,
      selected: require("assets/VesselMapIcons/Selected/_routine_anchored.svg").default,
      default: require("assets/VesselMapIcons/Severity/_routine_anchored.svg").default,
    },
  },
};
