/**
 * Contains reusable validation schemas.
 */
import * as yup from "yup";

const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;

const name = (type = "Name") => yup.string().max(50, `${type} must not exceed 50 characters.`);
const email = () => yup.string().email("Please enter a valid email.").required("Email is required.");
const activityIDs = () =>
  yup
    .string()
    .required("Activity IDs are required.")
    .test("is-uuid", "List must contain valid Activity IDs only.", function (value) {
      const uuids = value?.split("\n");

      for (let uuid of uuids ?? []) {
        if (!uuidRegex.test(uuid.trim())) {
          return false;
        }
      }
      return true;
    })
    .test("max-1000", "List must contain up to 1000 Activity IDs only.", function (value) {
      return (value?.split("\n").filter((id) => id.trim()).length ?? 0) <= 1000;
    });

const nameSchema = yup.object().shape({
  name: name()
    .matches(/^[0-9a-zA-Z( /)]+$/, "Please enter a valid Fleet name")
    .required("Please enter a Fleet Name"),
});

export const validation = { name, email, nameSchema, activityIDs };
