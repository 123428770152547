/* eslint-disable max-lines */
import { Button, Tabs } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { rem, color } from "config/variable.styles";
import styled from "styled-components";

export const NotificationContentStyle = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 100%;
  .spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .small-spinner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .title {
    font-size: ${rem(21)};
    font-weight: 400;
    margin: 0 0 ${rem(14)};
    color: ${color.Primary};
  }
  .tab-head {
    padding: ${rem(10)} ${rem(10)} 0;
    margin-bottom: ${rem(10)};
  }
  .tab-content {
    height: calc(100% - ${rem(85)});
    flex: 1 1 auto;
    overflow: hidden;
    position: relative;
    .day {
      font-size: ${rem(12)};
      font-weight: 700;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: ${rem(0.33)};
      color: ${color.DarkRoyalBlue};
      text-transform: uppercase;
      margin: 0 0 ${rem(20)};
    }
    .alertToday {
      margin: 0 0 ${rem(67)};
    }
    .tabPanelRow {
      overflow: auto;
      height: 100%;
      padding: ${rem(10)};
      &[hidden] {
        display: none;
      }
    }
    .tabsRow {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
`;
interface Props {
  read?: boolean;
}
export const ReadDot = styled.div<Props>`
  position: absolute;
  z-index: 10;
  top: ${rem(6)};
  right: 0;
  height: ${rem(10)};
  width: ${rem(10)};
  border-radius: 50%;
  cursor: pointer;
  background-color: ${(props) => (props.read ? color.lightBlueGrey : color.Primary)};
`;
export const NotificationTab = withStyles(
  {
    root: {
      padding: 0,
      minHeight: "inherit",
      "& .MuiTabs-flexContainer": {
        borderBottom: `${rem(4)} solid ${color.CloudyBlue}`,
      },
      "& .MuiButtonBase-root": {
        minWidth: "inherit",
        padding: `${rem(5)} 0`,
        overflow: "visible",
        lineHeight: "1",
        minHeight: "inherit",
        fontSize: rem(16),
        letterSpacing: rem(0.33),
        fontWeight: 400,
        color: color.DarkBlack,
        opacity: 1,
        marginRight: rem(25),
        position: "relative",
        textTransform: "inherit",
        "&:last-child": {
          marginRight: rem(0),
        },
        "&:after": {
          content: '""',
          position: "absolute",
          bottom: rem(-4),
          left: 0,
          height: rem(4),
          opacity: 0,
          background: color.DarkRoyalBlue,
          width: "100%",
        },
        "&.Mui-selected": {
          color: color.DarkRoyalBlue,
          fontWeight: 700,
          "&:after": {
            opacity: 1,
          },
        },
      },
    },
    indicator: {
      display: "none",
    },
  },
  { name: "notification-tab" }
)(Tabs);

export const CommentAction = withStyles(
  {
    root: {
      height: "auto",
      width: "auto",
      minWidth: "auto",
      padding: 0,
      margin: `0 ${rem(10)} 0 0`,
      background: "transparent",
      fontSize: rem(12),
      lineHeight: rem(15),
      fontWeight: "normal",
      textTransform: "inherit",
      color: color.TurquoiseBlueThree,
      "&:hover": {
        background: "transparent",
        "& svg path": {
          fill: `${color.Primary}`,
        },
        "& svg g": {
          fill: `${color.Primary}`,
        },
      },
    },
  },
  { name: "comment-action" }
)(Button);

export const AddBtn = withStyles(
  {
    root: {
      height: rem(32),
      minWidth: rem(70),
      backgroundImage: `linear-gradient(to right, ${color.coolBlue}, ${color.Primary} 73%)`,
      borderRadius: rem(25),
      fontSize: rem(14),
      fontWeight: 500,
      boxShadow: "inherit",
      padding: `0 ${rem(10)}`,
      color: color.White,
      "&:hover": {
        boxShadow: "inherit",
      },
      "&.Mui-disabled": {
        opacity: "0.5",
        color: color.White,
      },
    },
    label: {
      textTransform: "initial",
    },
  },
  { name: "add-comment-btn" }
)(Button);

interface CommentCardContainerProps {
  status?: string | null;
}

export const CommentCardContainer = styled.div<CommentCardContainerProps>`
  .text {
    display: flex;
    height: 32px;
    font-size: 13px;
    margin-top: 60px;
    justify-content: center;
    align-items: center;
    color: ${color.WarmGrey};
  }
  .notificationWrap {
    border: ${rem(1)} solid ${color.whiteSmokeColor};
    border-bottom: none;
    margin-bottom: ${rem(-1)};
    .notificationCol {
      cursor: pointer;
      display: flex;
      align-items: flex-start;
      border-bottom: ${rem(1)} solid ${color.whiteSmokeColor};
      padding: ${rem(15)};
      background: ${(props) => (props.status !== "READ" ? color.White : color.commentBgGray)};
      transition: all 0.5s ease-out;
      position: relative;
      .notificationProfile {
        width: ${rem(40)};
        flex: 0 0 ${rem(40)};
        max-width: ${rem(40)};
        margin-right: ${rem(15)};
        img {
          width: ${rem(40)};
          border-radius: ${rem(10)};
        }
      }
      .notificationProfileContent {
        flex: 1 1 auto;
        .profileContentTitle {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: ${color.marinerBlueColor};
          font-size: ${rem(16)};
          margin-bottom: ${rem(15)};
          span {
            text-transform: uppercase;
          }
        }
        .profileContentText {
          display: flex;
          align-items: center;
          justify-content: space-between;
          p {
            color: ${(props) => (props.status === "READ" ? color.bgCommentDark : color.DarkBlack)};
            font-size: ${rem(16)};
            margin: 0 ${rem(15)} 0 0;
            white-space: nowrap;
            width: ${rem(330)};
            overflow: hidden;
            text-overflow: ellipsis;
            transition: all 0.1s ease-out;
            font-weight: ${(props) => (props.status === "READ" ? 400 : 500)};
          }
          span {
            font-size: ${rem(14)};
            color: ${color.darkCyanColor};
          }
        }
        .down-icon-row {
          position: absolute;
          top: ${rem(0)};
          right: ${rem(0)};
          display: none;
          z-index: 9;
          &.active {
            display: block;
          }
          .down-arrow {
            display: block;
            background: transparent;
            border: none;
            padding: 0;
            margin-left: auto;
            min-width: ${rem(40)};
            svg {
              width: ${rem(18)};
              height: ${rem(18)};
              color: ${color.gullGreyColor};
            }
          }
        }
      }
      &.dropdown-box {
        &:hover {
          .down-icon-row {
            display: block;
          }
        }
        p {
          color: ${(props) => (props.status === "READ" ? color.bgCommentDark : color.DarkBlack)};
          font-weight: ${(props) => (props.status === "READ" ? 400 : 500)};
        }
      }
    }
  }
`;
