import { rem } from "config/variable.styles";
import styled from "styled-components";
import { colors } from "ui";

export const ProfileThumbStyle = styled.div`
  width: 308px;
  height: auto;
  text-align: center;

  img {
    width: 200px;
    height: 200px;
    border-radius: 100%;
    object-fit: cover;
  }
  input {
    display: none;
  }

  .label {
    display: inline-flex;
    align-items: center;
    gap: ${rem(8)};
    border-radius: ${rem(8)};
    padding: ${rem(4)} ${rem(12)};
    font-size: ${rem(14)};
    line-height: ${rem(20)};
    transition: all 0.2s ease;
    cursor: pointer;

    &:disabled {
      pointer-events: none;
    }

    background-color: ${colors.gray[50]};
    border: 1px solid ${colors.gray[300]};
    color: black;

    :active {
      background-color: rgba(68, 63, 255, 0.2);
      border: 1px solid ${colors.primary[300]};
    }

    :hover {
      background-color: rgba(167, 174, 255, 0.2);
      border: 1px solid ${colors.primary[400]};
    }

    :disabled {
      color: ${colors.gray[400]};
      border: 1px solid ${colors.gray[200]};
      background-color: ${colors.gray[50]};
    }
  }
  .actions {
    margin-top: 20px;
    p {
      color: ${colors.gray[700]};
      font-size: 14px;
      margin-top: 13px;
      line-height: 20px;
    }
  }
`;
