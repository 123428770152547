const Critical = () => {
  return (
    <span className="icon icon-critical">
      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32">
        <defs>
          <filter id="i7lao8r30a" colorInterpolationFilters="auto">
            <feColorMatrix in="SourceGraphic" values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"></feColorMatrix>
          </filter>
        </defs>
        <g fill="none" fillRule="evenodd">
          <g transform="translate(-.007)">
            <circle cx="16.007" cy="16" r="16"></circle>
            <circle cx="16" cy="16.142" r="16" fill="#F05E5C"></circle>
          </g>
          <g filter="url(#i7lao8r30a)" transform="translate(-.007)">
            <g fill="#000" fillRule="nonzero">
              <path
                d="M9.416 21.948a.764.764 0 01-.02 1.078.763.763 0 01-1.077-.022A10.61 
                10.61 0 015.34 15.62c0-2.766 1.058-5.388 2.977-7.386A.761.761 0 119.415 9.29a9.098
                9.098 0 00-2.55 6.329c0 2.369.906 4.617 2.55 6.33zM23.698 8.234a10.613 10.613
                0 012.976 7.385c0 2.765-1.057 5.387-2.976 7.385a.763.763 0 11-1.098-1.056 9.094
                9.094 0 002.55-6.329 9.094 9.094 0 00-2.551-6.33.761.761 0 111.099-1.056z"
              ></path>
              <path
                d="M16.008 8c-4.201 0-7.62 3.418-7.62 7.619s3.419 7.62 7.62 7.62c4.2 
                0 7.619-3.419 7.619-7.62 0-4.201-3.418-7.619-7.62-7.619zm0 13.714a6.102 6.102
                0 01-6.096-6.095 6.103 6.103 0 016.096-6.095 6.102 6.102 0 016.095 6.095 6.103 6.103 0 01-6.095 6.095z"
              ></path>
              <path
                d="M15.246 16.381V11.81a.761.761 0 111.524 0v4.57a.762.762 0 01-1.524 
                0zm1.905 3.048a1.143 1.143 0 11-2.286-.002 1.143 1.143 0 012.286.002z"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    </span>
  );
};

export default Critical;
