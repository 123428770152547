import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { subDays } from "date-fns";
import { VesselReportFilter } from "types/FilterBar.types";
import { isEqual } from "lodash";

const initialState: VesselReportFilter = {
  vessel: undefined,
  dateRange: {
    start: subDays(new Date(), 29).toISOString(),
    end: new Date().toISOString(),
  },
  compare_to: "INDUSTRY_BENCHMARK",
};

const reportsFilterOld = createSlice({
  name: "reportsFilterOld",
  initialState,
  reducers: {
    setVesselFilter(state, action: PayloadAction<typeof initialState.vessel>) {
      state.vessel = action.payload;
    },
    setDateFilter(state, action: PayloadAction<typeof initialState.dateRange>) {
      state.dateRange = action.payload;
    },
    setCompareToFilter(state, action: PayloadAction<typeof initialState.compare_to>) {
      // If current and next state are same, don't update anything in slice.
      const currentState = [...state.compare_to].sort();
      const nextState = [...action.payload].sort();
      if (isEqual(currentState, nextState)) return;
      state.compare_to = action.payload;
    },
    setReportsFilter(state, action: PayloadAction<VesselReportFilter>) {
      state.compare_to = action.payload.compare_to;
      state.vessel = action.payload.vessel;
    },
  },
});

export const { setCompareToFilter, setDateFilter, setVesselFilter, setReportsFilter } = reportsFilterOld.actions;
export default reportsFilterOld.reducer;
