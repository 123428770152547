import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { color, rem } from "../../config/variable.styles";

export const FilterButtonStyle = withStyles(
  {
    root: {
      padding: `${rem(7)} ${rem(15)} ${rem(7)} ${rem(15)}`,
      margin: 0,
      background: color.navyBlue,
      minHeight: rem(32),
      borderRadius: rem(16),
      fontSize: rem(14),
      color: color.White,
      lineHeight: rem(17),
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none",
        background: color.navyBlue,
      },
      "& .icon-chevron": {
        position: "static",
        display: "flex",
        margin: `0 0 0 ${rem(5)}`,
        paddingTop: rem(2),
        fontSize:'0.8rem'
      },
      "& .close-tag": {
        position: "static",
        display: "flex",
        margin: `0 0 0 ${rem(5)}`,
        "& svg": {
          width: rem(18),
          height: rem(18),
        },
      },
      "& strong": {
        paddingLeft: rem(5),
      },
      "& .icon-list": {
        display: "flex",
        alignItems: "center",
        "& .icon": {
          display: "flex",
          fontSize: rem(18),
          paddingLeft: rem(5),
        },
      },
    },
    label: {
      padding: "0",
      textTransform: "capitalize",
    },
  },
  { name: "topbar-action-btn" }
)(Button);
