import { createAction, createReducer } from "@reduxjs/toolkit";

export const setFleetSwitch = createAction<boolean>("fleetFilterSwitch/setValue");

// feature is postponed for now, so keeping it false and commented out
const fleetSwitchReducer = createReducer(false, (builder) => {
  builder.addCase(setFleetSwitch, (_, action) => action.payload);
});

export default fleetSwitchReducer;
