import { QueryClient } from "@tanstack/react-query";

import { queryKeys } from "./queryKeys";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000,
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

export const autoRefreshQueries = () => {
  const { queryKey: manualRefreshKey } = { ...queryKeys.manualRefresh.all };

  // If a query key contains `manualRefresh`, we don't do auto refresh.
  queryClient.invalidateQueries({
    predicate: ({ queryKey }) => !queryKey.includes(manualRefreshKey[0]),
  });
};
