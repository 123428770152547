const ClickLine = () => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_33_17)">
      <path
        d="M7.39591 54.9693C4.93657 47.1389 9.23541 26.3582 32.1895 27.8783C72.0355 30.5171 64.1434 9.95283 64.3266 4.00003"
        stroke="#040066"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle cx="35.7081" cy="27.3734" r="6" fill="#00B4DE" />
      <path
        d="M37.7932 65.3084L46.4315 57.6286L56.8571 75.7528L62.8145 71.7593L53.2826 53.0208L63.7081 49.0273L35.7081 28.7528L37.7932 65.3084Z"
        fill="white"
        stroke="#040066"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_33_17">
        <rect width="80" height="80" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const ClickLineBox = () => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="35.708" cy="27.3734" r="6" fill="#00B4DE" />
    <rect x="8" y="11" width="56" height="34" rx="7" stroke="#040066" strokeWidth="2" />
    <path
      d="M37.7931 65.3084L46.4314 57.6286L56.8569 75.7528L62.8144 71.7593L53.2825 
  53.0208L63.708 49.0273L35.708 28.7528L37.7931 65.3084Z"
      fill="white"
      stroke="#040066"
      strokeWidth="2"
      strokeLinejoin="round"
    />
  </svg>
);

export { ClickLine, ClickLineBox };
