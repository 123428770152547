import { useCallback } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ActivitiesV1Service, AppActivityFilter } from "@shipin/shipin-app-server-client";

import { activityClient, queryKeys } from "config";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { autoRefresh } from "services/redux/reducers/RefreshTimer/RefreshTimer.slice";
import { useInvalidateTagsForActivities, useInvalidateFilters, TIMELINE_KEY } from "./activity";
import { GetTagsResponse, Tag } from "@shipin/proto-activity-client/activity";

export const RESERVED_TAGS = {
  TP: "a2d029ff-ec9e-49a5-a5f6-911ef4040e31",
  FP: "98203dc5-d90a-4da5-9be3-f728cfbeccbc",
};

const sortTags = (a: Tag, b: Tag) => a?.name?.localeCompare(b?.name ?? "") ?? -1;

function useTags() {
  const select = useCallback((response: GetTagsResponse): GetTagsResponse => {
    return {
      privateTags: [...(response.privateTags ?? [])].sort(sortTags),
      publicTags: [...(response.publicTags ?? [])].sort(sortTags),
    };
  }, []);

  return useQuery({
    ...queryKeys.tags.getTags,
    queryFn: () => activityClient.getTags({}).response,
    select,
  });
}

function useInvalidateTags({ disableAppRefresh = false }: { disableAppRefresh: boolean } = { disableAppRefresh: false }) {
  const client = useQueryClient();
  const dispatch = useAppDispatch();
  const invalidateFilters = useInvalidateFilters();
  const tagFilter = useAppSelector((state) => state.tableFilterBar.tagsFilter);

  return () => {
    const { queryKey: summaryQueryKey } = {
      ...queryKeys.activities.summary({ start_dttm: "", end_dttm: "", relative_time: AppActivityFilter.relative_time.NONE }),
    };

    // This will invalidate filters query
    invalidateFilters();
    // List of unassigned tags inside the main menu
    client.invalidateQueries({ ...queryKeys.tags.getTags });

    if (tagFilter?.length && !disableAppRefresh) {
      client.invalidateQueries({ queryKey: [summaryQueryKey[0]] });
      client.invalidateQueries({ queryKey: [TIMELINE_KEY] });
      dispatch(autoRefresh());
    }
  };
}

function useUpdateTagsMutation({ onSuccess }: { onSuccess?: () => void } = {}) {
  const invalidateTags = useInvalidateTags();
  const { updateTag } = useInvalidateTagsForActivities();

  return useMutation({
    mutationFn: ActivitiesV1Service.putApiV1ActivitiesV1TagsUpdate,
    onSuccess: (res) => {
      invalidateTags();
      onSuccess?.();
      updateTag({
        tagId: res.tag_id,
        name: res.name ?? "",
        color: res.color ?? 0,
        public: res.public ?? false,
        userId: res.created_by ?? "",
      });
    },
  });
}

export { useTags, useInvalidateTags, useUpdateTagsMutation };
