import { useState } from "react";
import { Paper, IconButton, InputBase, TableContainer, Table, TableBody } from "@material-ui/core";
// import { Search } from "@material-ui/icons";
import { TransferItem } from "../Transfer.types";
import { TransferTableBase } from "./TransferTable.styles";
import { useTransfer } from "../utils";
import TransferTableRow from "./TransferTableRow";
import TransferTableHead from "./TransferTabHead";
import NoDataIllustration from "../NoDataIllustration";
import Search from "components/Icon/Search";

interface TransferTableProps {
  items?: TransferItem[];
  align?: "left" | "right";
}
const TransferTable = (props: TransferTableProps) => {
  const { items, align } = props;
  const [text, setText] = useState("");
  const { label, onChange } = useTransfer();
  const allChecked = align === "right" && !!items?.length;
  const filtered = items?.filter((el) => el.name.toLowerCase().includes(text.trim().toLowerCase()));
  const handleChange = () => {
    const selected = align === "left" ? true : false;
    onChange?.((values) => values.map((e) => ({ ...e, selected })));
  };

  if (!items?.length) {
    return (
      <NoDataIllustration>
        {align === "left" ? "There are no Vessels available for selection" : "You have no Vessels in this Fleet"}
      </NoDataIllustration>
    );
  }
  return (
    <TransferTableBase>
      <Paper>
        <form className="search-form-row">
          <IconButton aria-label="search">
            <Search />
          </IconButton>
          <InputBase
            value={text}
            onChange={(e) => setText(e.target.value)}
            placeholder={`Search ${label}…`}
            inputProps={{ "aria-label": `Search ${label}…` }}
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          />
        </form>
        <TableContainer>
          <Table aria-labelledby="tableTitle" aria-label="enhanced table">
            <TransferTableHead onChange={handleChange} checked={allChecked} disabled={!items || items?.length === 0} align={align} />
            <TableBody>
              {filtered?.map((rowProps) => (
                <TransferTableRow key={rowProps.id} {...rowProps} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </TransferTableBase>
  );
};

export default TransferTable;
