import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { SignedURLState } from "../../../../types/ReduxState/signedURLState.types";
import { UserUpdateGetSignedURL, UserData } from "../../../../types/User.types";
import axio from "axios";
import axios from "../../../../config/api";
import { user } from "../../../../constants/api/userProfile";
import { ERROR_MESSAGE } from "../../../../constants/api/common";

const initialState: SignedURLState = {
  data: null,
  status: "idle",
  error: null,
};

export const getSignedUrl = createAsyncThunk("signedUrl/fetch", async (userdata: UserData, { rejectWithValue }) => {
  try {
    const res = await axios.post<UserUpdateGetSignedURL>(user.signedURL(userdata.userId));
    const data = res?.data?.fields;
    const formData = new FormData();
    formData.append("key", data.key);
    formData.append("AWSAccessKeyId", data.AWSAccessKeyId);
    formData.append("x-amz-security-token", data["x-amz-security-token"]);
    formData.append("policy", data.policy);
    formData.append("signature", data.signature);
    formData.append("file", userdata.imageURl);

    var instance: any = axio.create();
    delete instance.defaults.headers.common["Authorization"];
    await instance.post(res?.data?.post_url, formData);

    return res.data;
  } catch (error) {
    return rejectWithValue(error.response?.data?.error ? error.response.data.error : ERROR_MESSAGE);
  }
});

const userProfileSlice = createSlice({
  name: "userProfile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSignedUrl.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getSignedUrl.fulfilled, (state, action) => {
      localStorage.setItem("signedUrl", JSON.stringify(action.payload));
      state.status = "success";
      state.data = action.payload;
    });
    builder.addCase(getSignedUrl.rejected, (state, action) => {
      state.status = "error";
      state.error = action.payload as string;
    });
  },
});

export default userProfileSlice.reducer;
