/*eslint max-lines: ["error", {"max": 450, "skipComments": true}]*/
import { TextField, Box } from "@material-ui/core";
// import Edit from "components/Icon/Edit";
import { useFormik } from "formik";
import * as yup from "yup";
import { EditIconBtn, FormStyles, ProfileNameForm } from "./ProfileForm.styles";
import { ReactNode, useState } from "react";
import Skeleton from "components/Loaders/Skeleton";
import { toast } from "react-toastify";
import { SuccessToast } from "components/Toastbar";
import { SUCCESS_MESSASGE } from "constants/api/userProfile";
import { useCurrentUser, useInvalidateCurrentUser, useUpdateUserMutation } from "queries";
import { validation } from "utils";
import { format } from "date-fns";
import { Button } from "ui";
import { Edit } from "ui/Icons";

interface FieldProps {
  label: string;
  children?: ReactNode;
  isLoading: boolean;
}
const Field = ({ label, children, isLoading }: FieldProps) => {
  return (
    <div className="field">
      <label className="field-label">{label}</label>
      <div className="field-value">
        <Skeleton loading={isLoading}>{children ?? "-"}</Skeleton>
      </div>
    </div>
  );
};

interface NameFormProps {
  onCancel: () => void;
}

const schema = yup.object().shape({
  profile_name: validation.name(),
});

const NameForm = (props: NameFormProps) => {
  const { onCancel } = props;
  const { data: userProfile } = useCurrentUser();
  const invalidateCurrentUser = useInvalidateCurrentUser();
  const { mutate: updateUserProfile, isPending: isProfileUpdating } = useUpdateUserMutation({
    onSuccess: (data) => {
      onCancel();
      toast.success(<SuccessToast>{SUCCESS_MESSASGE}</SuccessToast>);
      invalidateCurrentUser(data);
    },
  });

  const { getFieldProps, values, handleSubmit, isValid } = useFormik({
    initialValues: {
      profile_name: userProfile?.full_name || "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      if (!userProfile?.id) return;
      updateUserProfile({ id: userProfile.id, full_name: values.profile_name.trim() });
    },
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
  });

  const hasErrors = !isValid || values?.profile_name?.trim() === (userProfile?.full_name ?? "");

  return (
    <ProfileNameForm onSubmit={handleSubmit}>
      <TextField autoFocus {...getFieldProps("profile_name")} type="text" className="name-input" placeholder="Name" />
      <Button type="submit" disabled={hasErrors} loading={isProfileUpdating}>
        Save
      </Button>
      <Button type="button" variant="secondary" onClick={onCancel}>
        Cancel
      </Button>
    </ProfileNameForm>
  );
};

const UserProfileTab = () => {
  const [editMode, setEditMode] = useState(false);
  const { data: userProfile, isLoading } = useCurrentUser();

  return (
    <FormStyles>
      <Field label="Email" isLoading={isLoading}>
        {userProfile?.email}
      </Field>
      <Field label="Name" isLoading={isLoading}>
        {editMode ? (
          <NameForm onCancel={() => setEditMode(false)} />
        ) : (
          <Box display="flex" justifyContent="space-between" position="relative">
            {userProfile?.full_name ?? "-"}
            <EditIconBtn className="edit-control" onClick={() => setEditMode(true)}>
              <Edit />
            </EditIconBtn>
          </Box>
        )}
      </Field>
      <Field label="Role" isLoading={isLoading}>
        {userProfile?.role}
      </Field>
      <Field label="Last Login" isLoading={isLoading}>
        {userProfile?.last_login ? format(new Date(userProfile.last_login), "d MMM, yyyy HH:mm 'UTC'") : "-"}
      </Field>
    </FormStyles>
  );
};

export default UserProfileTab;
