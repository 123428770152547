import { withStyles } from "@material-ui/styles";
import { rem, color } from "config/variable.styles";
import { Chip } from "@material-ui/core";
import styled from "styled-components";
import { CSSProperties } from "react";
export const DefaultChip = withStyles(
  {
    root: {
      padding: `${rem(3)} ${rem(10)} ${rem(4)} ${rem(10)}`,
      margin: `${rem(1)} ${rem(5)} ${rem(1)} ${rem(5)}`,
      background: color.paleGreyThree,
      borderRadius: "5%",
      fontWeight: 400,
      height: "auto",
      fontSize: rem(14),
      lineHeight: rem(17),
      position: "relative",
      "&.chip-filter": {
        maxWidth: rem(250),
        padding: `${rem(3)} ${rem(8)}`,
        width: "auto",
        margin: `0 0 ${rem(5)} 0`,
        "&.chip-filter-width": {
          width: "auto",
        },
        "&:hover, &:focus": {
          background: color.paleGreyThree,
        },
      },
      "&:hover, &:focus": {
        background: color.lightBlueGreyThree,
      },
      "& .MuiSvgIcon-root": {
        height: rem(16),
        width: rem(16),
        margin: 0,
        color: "rgba(0,0,0,0.26)",
        cursor: "pointer",
        marginLeft: rem(5),
      },
    },
    label: {
      padding: "0",
    },
  },
  { name: "custom-chip" }
)(Chip);

export const RoundedChip = withStyles({
  root: {
    padding: `${rem(6)} ${rem(28)} ${rem(8)} ${rem(10)}`,
    margin: `${rem(2)} 0 ${rem(2)} 0`,
    background: color.lightBlueGreyThree,
    borderRadius: rem(25.5),
    fontWeight: 700,
    fontSize: rem(14),
    lineHeight: rem(17),
    position: "relative",
    "&:hover, &:focus": {
      background: color.lightBlueGreyThree,
    },
    "& .MuiSvgIcon-root": {
      display: "flex",
      position: "absolute",
      top: "50%",
      transform: "translate(0, -50%)",
      right: rem(2),
      fontSize: rem(28),
      cursor: "pointer",
    },
  },
  label: {
    padding: "0",
  },
})(Chip);

export const CustomChip = styled.span.attrs({
  className: "customChip",
}) <{ backgroundcolor?: CSSProperties["backgroundColor"] }>`
  padding: ${rem(3)} ${rem(10)} ${rem(4)};
  border-radius: ${rem(6)};
  background-color: ${(props) => props.backgroundcolor ?? color.paleGreyThree};
  font-size: ${rem(14)};
  font-weight: 400;
  line-height: normal;
  color: ${color.DarkBlack};
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  margin: ${rem(2)} 0;
`;
