import { useQuery } from "@tanstack/react-query";
import { notifsClient, queryKeys } from "config";

function useNotificationConfiguration() {
  return useQuery({
    ...queryKeys.notifications.config,
    queryKey: ["notification-config"],
    queryFn: () => notifsClient.getNotificationConfig({}).response,
    select: (data) => data.conf?.activityNotifConfigs ?? [],
    staleTime: 0,
    gcTime: 0,
  });
}

export { useNotificationConfiguration };
