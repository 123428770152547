const Error = () => {
  return (
    <span className="icon icon-error">
      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
        <defs>
          <filter id="xv8ymexcja" colorInterpolationFilters="auto">
            <feColorMatrix in="SourceGraphic" values="0 0 0 0 0.941176 0 0 0 0 0.368627 0 0 0 0 0.360784 0 0 0 1.000000 0" />
          </filter>
        </defs>
        <g fill="none" fillRule="evenodd">
          <g>
            <g>
              <g
                filter="url(#xv8ymexcja)"
                transform="translate(-933.000000, -110.000000) translate(911.000000, 80.000000) translate(22.000000, 30.000000)"
              >
                <path
                  fill="#000"
                  fillRule="nonzero"
                  d="M20 0C8.954 0 0 8.954 0 20s8.954 20 20 20 20-8.954 20-20C39.987 8.96 31.04.013 20 
                0zm7.713 25.144c.472.456.662 1.132.496 1.768-.167.636-.663 1.132-1.299 1.298-.636.167-1.312-.023-1.768-.495L20 
                22.57l-5.142 5.144c-.713.689-1.847.679-2.549-.023-.7-.7-.71-1.835-.022-2.548L17.43 
                20l-5.142-5.144c-.472-.456-.662-1.132-.496-1.768.167-.636.663-1.132 
                1.299-1.298.636-.167 1.312.023 1.768.495L20 17.43l5.142-5.144c.456-.472 
                1.132-.662 1.768-.495.636.166 1.132.662 1.299 1.298.166.636-.024 1.312-.496 1.768L22.57 20l5.142 5.144z"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </span>
  );
};

export default Error;
