import { DateState } from "./MonthSelect.types";

const parseDateString = (date: string): DateState => {
  const parsedDate = new Date(date);

  return {
    month: parsedDate.getMonth(),
    year: parsedDate.getFullYear(),
  };
};

const months = Array.from({ length: 12 }, (_, i) => ({
  name: new Date(0, i).toLocaleString("default", { month: "short" }),
  value: i,
}));

const currentYear = new Date().getFullYear();
const years = Array.from({ length: currentYear - 2022 }, (_, i) => {
  const year = 2023 + i;

  return {
    name: year.toString(),
    value: year,
  };
});

export { parseDateString, months, years };
