import { CSSProperties, ComponentProps } from "react";
import { Box, makeStyles } from "@material-ui/core";

import { Chip } from "./Chip";
import { Tooltip } from "components/Tooltip/Tooltip.styles";
import { rem } from "config/variable.styles";

const useStyles = makeStyles(() => ({
  popper: {
    padding: 0,
    marginRight: "1.25rem",
    "& .MuiTooltip-tooltip": {
      padding: "0 !important",
      "& .chip-list": {
        maxHeight: "200px",
        overflowY: "auto",
        overflowX: "hidden",
        padding: `${rem(12)} ${rem(18)} ${rem(12)} ${rem(20)}`,
      },
    },
  },
}));

// Below pattern is called as "Discriminated Union" in TypeScript
// If disableTooltip is true, it won't let tooltipAnchorCursor to be passed
type ChipGroupTooltipProps =
  | {
      /**
       * Disable tooltip
       * @default false
       */
      disableTooltip: true;
      tooltipAnchorCursor?: never;
    }
  | {
      disableTooltip?: false;
      /**
       * Cursor to be shown on tooltip anchor
       * @default inherit
       */
      tooltipAnchorCursor?: CSSProperties["cursor"];
    };

export type ChipGroupProps = ChipGroupTooltipProps & {
  /**
   * List of chips to be displayed
   */
  chips: string[];
  /**
   * Number of chips to be displayed, rest will be hidden
   * and shown in a tooltip and a +{number} label
   * If visibleChips is 0 or less, it will show all chips.
   * By default it shows all chips
   * @default 0
   */
  visibleChips?: number;
  /**
   * Gap between chips in pixels
   * @default 8
   */
  gap?: number;
  /**
   * Props to be passed to Chip component
   */
  chipProps?: Omit<ComponentProps<typeof Chip>, "children">;
};
const ChipGroup = (props: ChipGroupProps) => {
  const { chips, visibleChips = 0, gap = 8, disableTooltip = false, tooltipAnchorCursor = "inherit", chipProps = {} } = props;
  const styles = useStyles();
  const parsedVisibleChips = visibleChips <= 0 ? chips.length : visibleChips;
  const chipsToShow = chips.slice(0, parsedVisibleChips);
  const hiddenChips = chips.slice(parsedVisibleChips);

  const renderChips = (chips: string[]) => {
    return chips.map((chip, index) =>
      !!chip ? (
        <Chip key={index} {...chipProps} role="listitem">
          {chip}
        </Chip>
      ) : null
    );
  };

  if (!chips.length) return <>-</>;

  return (
    <Box display="flex" alignItems="center" gridGap={`${gap}px`} role="list">
      {renderChips(chipsToShow)}
      {hiddenChips.length > 0 && (
        <Tooltip
          interactive
          classes={styles}
          placement="top-end"
          arrow
          title={
            !disableTooltip && !!hiddenChips.length ? (
              <Box display="flex" alignItems="flex-start" className="chip-list" flexDirection="column" gridGap={4}>
                {renderChips(hiddenChips)}
              </Box>
            ) : (
              ""
            )
          }
        >
          <Box style={{ cursor: tooltipAnchorCursor }}>+{hiddenChips.length}</Box>
        </Tooltip>
      )}
    </Box>
  );
};

export { ChipGroup };
