import { useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import {
  ApiError,
  UserSortBy,
  UsersV1Service,
  UsersQueryRequest,
  UpdateUserInfoRequest,
  UserQuerySingleResponse,
  UserResponse,
} from "@shipin/shipin-app-server-client";
import { ResetPasswordRequest } from "@shipin/users-client/service";
import { InfiniteData, useInfiniteQuery, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { isEdge } from "utils";
import { UserStatus } from "types";
import { useAppSelector, useObserver, useFleets } from "hooks";
import { queryKeys, routes, history, userClient } from "config";

const cacheConfig = {
  staleTime: Infinity,
  gcTime: Infinity,
};

const usePermissionsQuery = () =>
  useQuery({
    ...queryKeys.user.permissions,
    ...cacheConfig,
    queryFn: () => UsersV1Service.getApiV1UsersV1GetUserPermissions(),
    enabled: !isEdge,
  });

const usePermissions = () => usePermissionsQuery().data?.permissions;

// Do not fetch user info if user info for Unauthenticated routes
// Such routes will be opened only if user is not logged in
const useCurrentUser = () => {
  const enabled = useMemo(
    () => !Object.values(routes).some((route) => route.isPrivate && history.location.pathname.includes(route.path)) && !isEdge,
    []
  );

  const query = useQuery({
    ...queryKeys.user.info,
    ...cacheConfig,
    queryFn: () => UsersV1Service.getApiV1UsersV1My(),
    enabled,
  });

  // If query is disabled, isLoading will always be true in React Query,
  // Here making it false if query is disabled, so we can prevent UI
  // from showing loading state for unauthenticated routes
  return { ...query, isLoading: enabled ? query.isLoading : false, enabled };
};

/**
 * Used in profile page to invalidate user info after user updates their profile
 * Profile has 2 sections, one for user info and another for profile picture update
 * Using this logic inside useUpdateUserMutation onSuccess callback will create
 * Fake entries in FullStory and Smartlook Analytics.
 * See: https://shipin.atlassian.net/browse/FV-3883
 */
const useInvalidateCurrentUser = () => {
  const client = useQueryClient();

  return (data: UserResponse) => {
    const { queryKey } = { ...queryKeys.user.info };
    client.invalidateQueries({ ...queryKeys.user.info });
    client.setQueryData(queryKey, data);
  };
};

const useUpdateUserMutation = ({ onSuccess, onError }: { onSuccess?: (data: UserResponse) => void; onError?: (e: ApiError) => void } = {}) => {
  return useMutation({
    mutationFn: ({ id, ...rest }: UpdateUserInfoRequest & { id: string }) => UsersV1Service.patchApiV1UsersV1(id, rest),
    onSuccess,
    onError,
  });
};

const fetchItem = async (
  request: Required<UsersQueryRequest>
): Promise<{
  items: UserQuerySingleResponse[];
  nextPageParam: number | undefined;
}> => {
  const { users_query_result: users = [] } = await UsersV1Service.postApiV1UsersV1Query(request);

  if (!users.length || users.length < request.result_range.limit) {
    return {
      items: users,
      nextPageParam: undefined,
    };
  }

  return {
    items: users,
    nextPageParam: request.result_range.offset + request.result_range.limit,
  };
};

/**
 * Process filter items to get value for API payload
 * If filter is None, we need to pass empty array to API
 * If filter is empty, we need to pass undefined to API
 * If filter has values, we need to pass those values to API
 * @param items Filter items
 * @returns Processed filter value for API payload
 */
const getFilterValue = (items: string[]) => {
  const isNone = items.length === 1 && items[0] === "None";
  const filtered = items.filter((e) => e !== "None");
  return isNone ? [] : filtered.length ? filtered : undefined;
};

const useUsersQuery = (order: `${UserSortBy.order}`, sort: `${UserSortBy.sort}`) => {
  const location = useLocation();
  const permissions = usePermissions();
  const { data: fleetNames, loading } = useFleets((data) =>
    (data?.fleets ?? []).reduce<Record<string, string>>((acc, item) => {
      acc[item.id] = item.name;
      return acc;
    }, {})
  );

  const filters = useAppSelector((state) => state.usersFilter);

  // Sorting filters before passing in Query Keys
  // to avoid cache miss when fields are in different order
  const fleet = useMemo(() => [...filters.fleet].sort(), [filters.fleet]);
  const designations = useMemo(() => [...filters.designation].sort(), [filters.designation]);
  const companies = useMemo(() => [...filters.company].sort(), [filters.company]);
  const shipManagers = useMemo(() => [...filters.shipManager].sort(), [filters.shipManager]);

  // This is not an expensive operation as the array is of max length 4
  const role = [...filters.role].sort();

  // Asserting the type with as keyword.
  // But, it is safe here because query is
  // conditional based on 3 allowed status values.
  const status = location.pathname.split("/")?.[2]?.toUpperCase() as UserStatus;

  const query = useInfiniteQuery({
    ...queryKeys.manualRefresh.userList({
      status,
      fleet,
      role,
      sort,
      order,
      company: companies,
      designaion: designations,
      ship_manager: shipManagers,
    }),
    initialPageParam: 0,
    queryFn: ({ pageParam }) => {
      const fleet_id = getFilterValue(fleet);
      const company = getFilterValue(companies);
      const designation = getFilterValue(designations);
      const ship_manager = getFilterValue(shipManagers);

      return fetchItem({
        result_range: {
          offset: pageParam,
          // Table will load this much number of rows at a time
          limit: 20,
        },
        filters: {
          status: [status],
          role: role.length ? role : undefined,
          fleet_id,
          company,
          designation,
          ship_manager,
        },
        sort: [
          {
            //@ts-ignore
            sort,
            // @ts-ignore
            order,
          },
        ],
      });
    },
    getNextPageParam: (lastPage) => lastPage.nextPageParam,
    enabled: ["ACTIVE", "PENDING", "SUSPENDED"].includes(status) && permissions?.can_view_all_users,
    // API returns an array of fleet_ids, we need to convert it to fleet_names
    // Adding an additional array of fleet_names in each page.
    select: useCallback(
      (
        data: InfiniteData<{
          items: UserQuerySingleResponse[];
          nextPageParam: number | undefined;
        }>
      ): InfiniteData<{
        items: Array<
          UserQuerySingleResponse & {
            fleet_names: string[];
          }
        >;
        nextPageParam: number | undefined;
      }> => {
        const pages = data.pages.map((page) => {
          const items = page.items.map((item) => {
            return {
              ...item,
              fleet_names: (item.memberships ?? [])?.map((e) => fleetNames[e.fleet_id]).filter(Boolean),
            };
          });
          return {
            ...page,
            items,
          };
        });
        return {
          ...data,
          pages,
        };
      },
      [fleetNames]
    ),
  });

  const ref = useObserver({
    onIntersect: () => query.fetchNextPage(),
  });

  // We are combining fleets info API data to the response.
  // So, altering isLoading fields to have fleets info status as well.
  return [ref, { ...query, isLoading: loading || query.isLoading, isFetching: loading || query.isFetching }] as const;
};

const useInvalidateUsers = () => {
  const client = useQueryClient();

  return () => {
    // This is for type safe query key
    const {
      queryKey: [manualRefresh, userList],
    } = {
      ...queryKeys.manualRefresh.userList({ status: "", role: [], fleet: [], designaion: [], company: [], ship_manager: [], sort: "", order: "" }),
    };
    const { queryKey: userCountKey } = { ...queryKeys.user.userCount };

    // Query key structure for users is [user, list, { status, role, fleet }].
    // We have to invalidateQueries for all type of statuses,
    // because we don't know on what status tab the superuser will be after creation
    // Passing initial matchers to invalidate all 3 types of statuses
    // However, it will invalidate only active queries with observers > 0
    client.invalidateQueries({ queryKey: [manualRefresh, userList] });
    // After user is changed, we need to refresh the count as well
    client.invalidateQueries({ queryKey: userCountKey });
  };
};

const useUserCountQuery = () =>
  useQuery({
    ...cacheConfig,
    ...queryKeys.user.userCount,
    queryFn: () => userClient.userSummary({}).response,
    select: (data) => data.byStatus,
  });

const useUserInfoByID = (id: string) =>
  useQuery({
    ...cacheConfig,
    ...queryKeys.user.byID(id),
    queryFn: () =>
      userClient.userDetails({
        userId: id,
      }).response,
    enabled: !!id,
  });

const useResetPasswordMutation = () => {
  return useMutation({
    mutationFn: (req: ResetPasswordRequest) => {
      return userClient.adminResetUserPassword(req).response;
    },
  });
};

const useUserFilterOptions = () =>
  useQuery({
    ...cacheConfig,
    ...queryKeys.user.userFilterOptions,
    queryFn: () => UsersV1Service.getApiV1UsersV1FilterOptions(),
  });

export {
  usePermissions,
  usePermissionsQuery,
  useCurrentUser,
  useInvalidateUsers,
  useUpdateUserMutation,
  useUsersQuery,
  useUserCountQuery,
  useUserInfoByID,
  useResetPasswordMutation,
  useUserFilterOptions,
  useInvalidateCurrentUser,
};
