import { NestedRouteConfig } from "components/NestedRoute";
import routes from "config/routes";

import UserInformation from "components/Profile/UserInformation";
import NotificationConfig from "components/Profile/NotificationConfig";

const getPath = (path: string) => `${routes.userprofile.path}/${path}`;

export const userProfileRoutes: NestedRouteConfig<"profileInformation" | "notificationSettings"> = {
  profileInformation: {
    path: getPath("profile-information"),
    component: UserInformation,
  },
  notificationSettings: {
    path: getPath("notification-settings"),
    component: NotificationConfig,
  },
};
