/* eslint-disable max-lines */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getRoundedDates } from "utils";
import {
  TableFilterBarState,
  FleetsFilter,
  Activity,
  ActivityFilter as ActivityFilterOld,
  SeverityFilter,
  VesselsFilter,
  VesselTypeFilter,
  ActiveFilters,
  ActivityNameFilter,
  Severity,
  ActiveFiltersState,
} from "types/ReduxState/TableFilterBar.types";
import { ActivityFilter } from "@shipin/shipin-app-server-client";
import { subDays, endOfWeek, endOfMonth, startOfMonth, format } from "date-fns";
import { firstDayOfLastWeek, previousMonth, previousThreeMonths } from "components/Filters/DateRangePicker/utils/utils";

export type Screen = "vesselDB" | "sensor" | "activities" | "reports" | "fleet" | "vessel";

const { parsedDate } = getRoundedDates();

// When adding a new filter to the App, make sure of following.
// 1. Add the filter to the TableFilterBarState interface.
// 2. Add the filter to the initialState.
//    2 (a). Add in activeFilters object.
//    2 (b). Add the actual state. Name of state should be the `filterName` + "Filter",
//           where filterName is the key used in the activeFilters object.
//           For example, if the filterName is "fleets", the state should be fleetsFilter.
//           This is necessary to make setMySelectedFilter reducer work as expected.
// 3. Add the filter to the filterProperties array in the setMySelectedFilter reducer.
// 4. Add the filter to the clearFilterState reducer.
// 5. Make necessary changes in the useFilters hook.
// 6. Check queries which doesn't depend on useFilters, and integrate filters there manually eg sensors.
// 7. Check useIsActive hook.
// NOTE: If you add something non-filter state, destructure it in setMySelectedFilter reducer.

const initialState: TableFilterBarState = {
  fleetsFilter: "All",
  vesselsFilter: undefined,
  vesselTypeFilter: [],
  tagsFilter: undefined,
  areaNameFilter: undefined,
  compartmentNameFilter: undefined,
  cameraFilter: undefined,
  dateRange: {
    start: parsedDate.end,
    end: parsedDate.end,
    live: true,
    zoom: false,
  },
  activityFilter: [],
  activityNameFilter: [],
  severityFilter: [],
  navigationStatusFilter: [],
  visibilityFilter: [],
  beaufortFilter: [],
  distanceFilter: [],
  speedFilter: [],
  phaseOfDaysFilter: [],
  activeFilters: {
    fleets: true,
    severity: true,
    activity: true,
    camera: false,
    vessels: true,
    vesselType: false,
    areaName: true,
    compartmentName: true,
    activityName: true,
    tags: true,
    navigationStatus: true,
    visibility: false,
    beaufort: true,
    distance: false,
    speed: false,
    phaseOfDays: false,
  },
  blacklist: false,
  mySelectedFilter: null,
};

export const TableFilterBarSlice = createSlice({
  name: "FilterBar",
  initialState,
  reducers: {
    setFleetsFilter(state, action: PayloadAction<FleetsFilter>) {
      state.fleetsFilter = action.payload;
      state.mySelectedFilter = null;
    },
    setNavigationStatusFilter(state, action: PayloadAction<typeof initialState.navigationStatusFilter>) {
      state.navigationStatusFilter = action.payload;
      state.mySelectedFilter = null;
    },
    setVisibilityFilter(state, action: PayloadAction<typeof initialState.visibilityFilter>) {
      state.visibilityFilter = action.payload;
      state.mySelectedFilter = null;
    },
    setBeaufortFilter(state, action: PayloadAction<typeof initialState.beaufortFilter>) {
      state.beaufortFilter = action.payload;
      state.mySelectedFilter = null;
    },
    setPhaseOfDaysFilter(state, action: PayloadAction<typeof initialState.phaseOfDaysFilter>) {
      state.phaseOfDaysFilter = action.payload;
      state.mySelectedFilter = null;
    },
    setDistanceFilter(state, action: PayloadAction<typeof initialState.distanceFilter>) {
      state.distanceFilter = action.payload;
      state.mySelectedFilter = null;
    },
    setSpeedFilter(state, action: PayloadAction<typeof initialState.speedFilter>) {
      state.speedFilter = action.payload;
      state.mySelectedFilter = null;
    },
    setMySelectedName(state, action: PayloadAction<{ id: string; name: string }>) {
      state.mySelectedFilter = { id: action.payload.id, name: action.payload.name };
    },
    setMySelectedFilter(state, action: PayloadAction<ActivityFilter>) {
      const { activity_filter: filter, id, name } = action.payload;
      const formatedStartDate = filter?.start_dttm ? format(new Date(filter.start_dttm), "HH:mm:ss") : undefined;
      const formatedEndDate = filter?.end_dttm ? format(new Date(filter.end_dttm), "HH:mm:ss") : undefined;
      const today = new Date();
      const relativeTime = filter?.relative_time;
      state.mySelectedFilter = { id, name };
      let startDate = today;
      let endDate = today;

      switch (relativeTime) {
        case "TODAY":
          startDate = today;
          endDate = today;
          break;
        case "YESTERDAY":
          startDate = subDays(today, 1);
          endDate = subDays(today, 1);
          break;
        case "LAST_24_HOURS":
          startDate = today;
          endDate = today;
          break;
        case "LAST_7_DAYS":
          startDate = subDays(today, 6);
          endDate = today;
          break;
        case "LAST_30_DAYS":
          startDate = subDays(today, 29);
          endDate = today;
          break;
        case "PREVIOUS_WEEK":
          startDate = firstDayOfLastWeek;
          endDate = endOfWeek(firstDayOfLastWeek);
          break;
        case "PREVIOUS_MONTH":
          startDate = startOfMonth(previousMonth);
          endDate = endOfMonth(previousMonth);
          break;
        case "PREVIOUS_3_MONTHS":
          startDate = startOfMonth(previousThreeMonths);
          endDate = endOfMonth(previousMonth);
          break;
        default:
          break;
      }

      const pattern = "yyyy-MM-dd'T'00:00:00";
      const patternEnd = "yyyy-MM-dd'T'23:59:59";
      state.mySelectedFilter = {
        id: action.payload.id,
        name: action.payload.name,
      };
      state.severityFilter = filter?.severity;
      state.fleetsFilter = filter?.fleet_id ?? [];
      state.vesselsFilter = filter?.vessel_id;
      state.vesselTypeFilter = filter?.vessel_type;
      state.areaNameFilter = filter?.area_name;
      state.compartmentNameFilter = filter?.compartment_name;
      state.tagsFilter = filter?.my_tags;
      state.cameraFilter = filter?.camera_id;
      state.activityFilter = filter?.operation_type;
      state.activityNameFilter = filter?.title;
      state.blacklist = !!filter?.blacklist;
      state.navigationStatusFilter = filter?.navigation_status ?? [];
      state.visibilityFilter = filter?.visibility ?? [];
      state.beaufortFilter = filter?.beaufort ?? [];
      state.distanceFilter = filter?.distance_from_shore ?? [];
      state.speedFilter = filter?.vessel_speed ?? [];
      state.phaseOfDaysFilter = (filter?.phase_of_days ?? []).map(Number);
      state.dateRange = {
        start: relativeTime === "NONE" && filter?.start_dttm ? filter?.start_dttm : format(startDate, pattern),
        end: relativeTime === "NONE" && filter?.end_dttm ? filter?.end_dttm : format(endDate, patternEnd),
        live: relativeTime === "LAST_24_HOURS",
        zoom:
          (relativeTime === "NONE" && formatedStartDate === "00:00:00" && formatedEndDate === "23:59:59") || relativeTime !== "NONE" ? false : true,
      };
      const { dateRange, activeFilters, blacklist, mySelectedFilter, ...rest } = state;

      // When adding a new filter, make sure to add it to the filterProperties array
      const filterProperties: Array<keyof ActiveFiltersState> = [
        "fleets",
        "severity",
        "areaName",
        "compartmentName",
        "vesselType",
        "tags",
        "camera",
        "activityName",
        "activity",
        "vessels",
      ];

      // Calculate what filters will be visible on filter bar.
      state.activeFilters = {
        ...state.activeFilters,
        ...filterProperties.reduce<Partial<ActiveFiltersState>>((acc, key) => {
          // We need extract value in a variable to eliminate undefined from type.
          // For same, converting to explicit return format in the reduce.
          const value = rest[(key + "Filter") as keyof typeof rest];
          acc[key] = !!value && value.length > 0 ? true : state.activeFilters[key];
          return acc;
        }, {}),
      };
    },
    setDateRange(state, action: PayloadAction<{ start: string; end: string; live?: boolean; zoom?: boolean }>) {
      state.dateRange = { ...state.dateRange, ...action.payload, zoom: action.payload.zoom ?? false };
      state.mySelectedFilter = null;
    },
    setVesselsFilter(state, action: PayloadAction<VesselsFilter>) {
      state.vesselsFilter = action.payload;
      state.mySelectedFilter = null;
    },
    setVesselsTypeFilter(state, action: PayloadAction<VesselTypeFilter>) {
      state.vesselTypeFilter = action.payload;
      state.mySelectedFilter = null;
    },
    setTagsFilter(state, action: PayloadAction<VesselsFilter>) {
      state.tagsFilter = action.payload;
      state.mySelectedFilter = null;
    },
    setTagsFilterValue(state, action: PayloadAction<string>) {
      if (state.tagsFilter?.includes(action.payload)) {
        state.tagsFilter = state.tagsFilter?.filter((el) => el !== action.payload);
        return;
      }
      state.tagsFilter = [...(state.tagsFilter ?? []), action.payload];
      state.mySelectedFilter = null;
    },
    setAreaNameFilter(state, action: PayloadAction<VesselsFilter>) {
      state.areaNameFilter = action.payload;
      state.mySelectedFilter = null;
    },
    setCameraFilter(state, action: PayloadAction<VesselsFilter>) {
      state.cameraFilter = action.payload;
      state.mySelectedFilter = null;
    },
    setCompartmentNameFilter(state, action: PayloadAction<VesselsFilter>) {
      state.compartmentNameFilter = action.payload;
      state.mySelectedFilter = null;
    },
    setActivityTitleFilter(state, action: PayloadAction<ActivityNameFilter>) {
      state.activityNameFilter = action.payload;
      state.mySelectedFilter = null;
    },
    enableFilter(state, action: PayloadAction<keyof typeof initialState.activeFilters>) {
      state.activeFilters[action.payload] = true;
    },
    setSeverityFilter(state, action: PayloadAction<SeverityFilter>) {
      state.severityFilter = action.payload;
      state.mySelectedFilter = null;
    },
    toggleBlacklist(state) {
      state.blacklist = !state.blacklist;
      state.mySelectedFilter = null;
    },
    setBlacklistFilter(state, action: PayloadAction<boolean>) {
      state.blacklist = action.payload;
      state.mySelectedFilter = null;
    },
    clearFilterState(state, action: PayloadAction<{ activeFilters: ActiveFilters; isResetFilters: boolean }>) {
      if (action.payload.isResetFilters) {
        state.activeFilters[action.payload.activeFilters] = false;
      }
      state.mySelectedFilter = initialState.mySelectedFilter;
      switch (action.payload.activeFilters) {
        case "severity":
          state.severityFilter = initialState.severityFilter;
          break;
        case "activity":
          state.activityFilter = initialState.activityFilter;
          break;
        case "fleets":
          state.fleetsFilter = initialState.fleetsFilter;
          break;
        case "vessels":
          state.vesselsFilter = initialState.vesselsFilter;
          break;
        case "areaName":
          state.areaNameFilter = initialState.areaNameFilter;
          break;
        case "compartmentName":
          state.compartmentNameFilter = initialState.compartmentNameFilter;
          break;
        case "camera":
          state.cameraFilter = initialState.cameraFilter;
          break;
        case "tags":
          state.tagsFilter = initialState.tagsFilter;
          break;
        case "activityName":
          state.activityNameFilter = initialState.activityNameFilter;
          break;
        case "vesselType":
          state.vesselTypeFilter = initialState.vesselTypeFilter;
          break;
        case "navigationStatus":
          state.navigationStatusFilter = initialState.navigationStatusFilter;
          break;
        case "visibility":
          state.visibilityFilter = initialState.visibilityFilter;
          break;
        case "beaufort":
          state.beaufortFilter = initialState.beaufortFilter;
          break;
        case "distance":
          state.distanceFilter = initialState.distanceFilter;
          break;
        case "speed":
          state.speedFilter = initialState.speedFilter;
          break;
        case "phaseOfDays":
          state.phaseOfDaysFilter = initialState.phaseOfDaysFilter;
          break;
        default:
          break;
      }
    },
    toggleActivityFilter(state, action: PayloadAction<Activity>) {
      state.mySelectedFilter = null;
      if (!state.activityFilter) {
        state.activityFilter = [action.payload];
        return;
      }
      if (state.activityFilter?.includes(action.payload)) {
        state.activityFilter = state.activityFilter?.filter((el) => el !== action.payload);
      } else {
        state.activityFilter?.push(action.payload);
      }
    },
    toggleTagFilter(state, action: PayloadAction<string>) {
      if (state.tagsFilter?.includes(action.payload)) {
        state.tagsFilter = state.tagsFilter?.filter((el) => el !== action.payload);
      }
      state.mySelectedFilter = null;
    },
    toggleSeverityFilter(state, action: PayloadAction<Severity>) {
      state.mySelectedFilter = null;
      if (!state.severityFilter) {
        state.severityFilter = [action.payload];
        return;
      }
      if (state.severityFilter?.includes(action.payload)) {
        state.severityFilter = state.severityFilter.filter((el) => el !== action.payload);
      } else {
        state.severityFilter?.push(action.payload);
      }
    },
    setActivityFilter(state, action: PayloadAction<ActivityFilterOld>) {
      state.activityFilter = action.payload;
      state.mySelectedFilter = null;
    },
    setActivityNameFilter(state, action: PayloadAction<ActivityNameFilter>) {
      state.activityNameFilter = action.payload;
      state.mySelectedFilter = null;
    },
    toggleFilter(state, action: PayloadAction<keyof TableFilterBarState["activeFilters"]>) {
      state.activeFilters = { ...state.activeFilters, [action.payload]: !state.activeFilters[action.payload] };
    },
    clearFilter(state) {
      state.fleetsFilter = initialState.fleetsFilter;
      state.vesselsFilter = initialState.vesselsFilter;
      state.vesselTypeFilter = initialState.vesselTypeFilter;
      state.activityFilter = initialState.activityFilter;
      state.severityFilter = initialState.severityFilter;
      state.cameraFilter = initialState.cameraFilter;
      state.compartmentNameFilter = initialState.compartmentNameFilter;
      state.activityNameFilter = initialState.activityNameFilter;
      state.areaNameFilter = initialState.areaNameFilter;
      state.tagsFilter = initialState.tagsFilter;
      state.mySelectedFilter = initialState.mySelectedFilter;
      state.distanceFilter = initialState.distanceFilter;
      state.speedFilter = initialState.speedFilter;
      state.beaufortFilter = initialState.beaufortFilter;
      state.navigationStatusFilter = initialState.navigationStatusFilter;
      state.visibilityFilter = initialState.visibilityFilter;
      state.phaseOfDaysFilter = initialState.phaseOfDaysFilter;
    },
  },
});

export const {
  setActivityFilter,
  setActivityNameFilter,
  setSeverityFilter,
  toggleBlacklist,
  setFleetsFilter,
  setMySelectedFilter,
  setMySelectedName,
  toggleFilter,
  toggleTagFilter,
  clearFilter,
  setVesselsFilter,
  setVesselsTypeFilter,
  clearFilterState,
  enableFilter,
  setDateRange,
  toggleActivityFilter,
  toggleSeverityFilter,
  setAreaNameFilter,
  setCompartmentNameFilter,
  setCameraFilter,
  setTagsFilter,
  setActivityTitleFilter,
  setTagsFilterValue,
  setBlacklistFilter,
  setNavigationStatusFilter,
  setVisibilityFilter,
  setPhaseOfDaysFilter,
  setBeaufortFilter,
  setDistanceFilter,
  setSpeedFilter,
} = TableFilterBarSlice.actions;

// eslint-disable-next-line max-lines
export default TableFilterBarSlice.reducer;
