import { ChangeEvent, memo, ReactNode } from "react";
import { CheckboxLabel } from "./FormUtils.styles";
import { MenuItem, FormControlLabel, Checkbox as MuiCheckbox, ListItemIcon } from "@material-ui/core";

export type CheckboxFields = {
  value?: string;
  name?: string;
  checked?: boolean;
};

export interface IFilterItem<T extends string = string> {
  id: T;
  selected: boolean;
  name: T;
  icon?: ReactNode;
}

const Label = (props: Pick<IFilterItem, "name" | "icon">) => {
  const { name, icon } = props;

  return (
    <CheckboxLabel className="checkbox-label">
      <ListItemIcon>{icon}</ListItemIcon>
      <span className="label-text">{name}</span>
    </CheckboxLabel>
  );
};

const FilterCheckbox = memo(
  //onChange: (id?: any) => void;
  (props: IFilterItem & { onChange: (id?: any) => void }) => {
    const { name, id, selected, onChange, icon } = props;

    return (
      <MenuItem key={id} value={id} className="width-checkbox">
        <FormControlLabel
          value={id}
          // Don't change this to controlled component, otherwise FilterItem will not work properly
          defaultChecked={selected}
          onChange={(e: ChangeEvent<CheckboxFields>) => {
            onChange({ name: e.target.name, value: e.target.value, checked: e.target.checked });
          }}
          control={<MuiCheckbox defaultChecked={selected} name={name} color="primary" />}
          label={<Label name={name} icon={icon} />}
        />
      </MenuItem>
    );
  }
);

export { FilterCheckbox };
