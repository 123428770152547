const NoData = () => {
  return (
    <span className="icon icon-nodata">
      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" className="ant-empty-img-default" viewBox="0 0 184 152">
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              cx="67.797"
              cy="106.89"
              className="ant-empty-img-default-ellipse"
              rx="67.797"
              ry="12.668"
              fill="#f5f5f5"
              fillOpacity="0.8"
            ></ellipse>
            <path
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 
              2.225L13.56 69.674v15.383h108.475V69.674z"
              fill="#aeb8c2"
              className="ant-empty-img-default-path-1"
            ></path>
            <path
              d="M115.097 86.214L94.19 61.102c-1.001-1.207-2.507-1.867-4.048-1.867H45.284c-1.54 
              0-3.047.66-4.048 1.867L20.329 86.214v13.792h94.768V86.214z"
              className="ant-empty-img-default-path-2"
              fill="url(#linearGradient-1)"
            ></path>
            <path
              d="M33.83 0h67.933a4 4 0 014 4v93.344a4 4 0 01-4 4H33.83a4 4 0 01-4-4V4a4 4 0 014-4z"
              className="ant-empty-img-default-path-3"
              fill="#f5f5f7"
            ></path>
            <path
              d="M42.678 9.953h50.237a2 2 0 012 2V36.91a2 2 0 01-2 2H42.678a2 2 0 01-2-2V11.953a2 2 0 
              012-2zm.262 39.814h49.713a2.262 2.262 0 110 4.524H42.94a2.262 2.262 0 010-4.524zm0
              11.763h49.713a2.262 2.262 0 110 4.525H42.94a2.262 2.262 0 010-4.525zm78.873
              43.502c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309
              7.309 0 01-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37
              5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426
              5.25-5.426h26.318v33.569a7.33
              7.33 0 01-.221 1.789z"
              fill="#dce0e6"
              className="ant-empty-img-default-path-4"
            ></path>
          </g>
        </g>
      </svg>
    </span>
  );
};

export default NoData;
