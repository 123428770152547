import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  fullScreen: false,
  tooltipVisible: true,
  timelines: {
    Navigation: true,
    Beaufort: false,
    Visibility: false,
    "Day/Night": false,
  },
};

export const dashboardSlice = createSlice({
  name: "dashboardSlice",
  initialState,
  reducers: {
    setFullScreen: (state, action) => {
      state.fullScreen = action.payload;
    },
    setTooltipVisibility: (state, action) => {
      state.tooltipVisible = action.payload;
    },
    toggleTimelineVisibility(state, { payload }: PayloadAction<keyof typeof initialState.timelines>) {
      state.timelines = { ...state.timelines, [payload]: !state.timelines[payload] };
    },
  },
});

export const { setFullScreen, setTooltipVisibility, toggleTimelineVisibility } = dashboardSlice.actions;

export default dashboardSlice.reducer;
