import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ReduxState } from "types/ReduxState/common.types";
import axios from "config/api";
import { FleetBridgeSingleActivityQuery, FleetBridgeSingleActivityResponse } from "types/Reports.types";
import { reports } from "constants/api/reports";
import { ERROR_MESSAGE } from "constants/api/common";

const initialState: ReduxState<FleetBridgeSingleActivityResponse> = {
  status: "idle",
  data: null,
  error: null,
};

export const getFleetBridgeSingleActivity = createAsyncThunk(
  "getFleetBridgeSingleActivity",
  async (payload: FleetBridgeSingleActivityQuery, { rejectWithValue }) => {
    try {
      const res = await axios.post<FleetBridgeSingleActivityResponse>(reports.fleetBridgeSingleActivity, payload);
      return res.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.msg || ERROR_MESSAGE);
    }
  }
);

const fleetBridgeSingleActivitySlice = createSlice({
  name: "bridgeSingleActivity",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFleetBridgeSingleActivity.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(getFleetBridgeSingleActivity.fulfilled, (state, action) => {
      state.status = "success";
      state.data = action.payload;
      state.error = null;
    });
    builder.addCase(getFleetBridgeSingleActivity.rejected, (state, action) => {
      state.error = JSON.stringify(action.payload);
      state.status = "error";
      state.data = null;
    });
  },
});
export default fleetBridgeSingleActivitySlice.reducer;
