interface ChevronIconProps {
  size?: number;
}
const ChevronIcon = ({ size = 1 }: ChevronIconProps) => {
  return (
    <span className="icon icon-chevron">
      <svg xmlns="http://www.w3.org/2000/svg" width={`${size}em`} height={`${size}em`} viewBox="0 0 16 16">
        <path
          fill="none"
          fillRule="evenodd"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M2 5l6 6 6-6"
        ></path>
      </svg>
    </span>
  );
};

export default ChevronIcon;
