import { ComponentPropsWithRef, ReactNode, forwardRef, useState } from "react";

interface ImageProps extends Omit<ComponentPropsWithRef<"img">, "src"> {
  /**
   * Image source URL, when null or undefined, fallback will be rendered
   */
  src: string | undefined | null;
  /**
   * Fallback component to render when src is null or undefined or image
   * fails to load from URL provided
   */
  fallback?: ReactNode;
}
const Image = forwardRef<HTMLImageElement, ImageProps>((props, ref) => {
  const { src, fallback, ...rest } = props;
  const [hasError, setHasError] = useState(false);

  if (!src || hasError) {
    return <>{fallback}</>;
  }

  return (
    <img
      ref={ref}
      src={src}
      {...rest}
      alt={rest.alt}
      onError={(e) => {
        setHasError(true);
        if (rest.onError) {
          rest.onError(e);
        }
      }}
    />
  );
});

export { Image };
