import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MapPoints } from "types/AIS.types";

interface BoundingRect {
  top: number;
  left: number;
  bottom: number;
  right: number;
  width: number;
  height: number;
}

interface GlobalDBTooltipReferenceState {
  boundingRect: BoundingRect | null;
  vesselLocation: MapPoints | null;
  vesselIcon: any;
}

const initialState: GlobalDBTooltipReferenceState = {
  boundingRect: null,
  vesselLocation: null,
  vesselIcon: null,
};

export const VesselTooltipReferenceSlice = createSlice({
  name: "GlobalDashboardTooltip",
  initialState,
  reducers: {
    setTooltipReferenceElement(
      state,
      action: PayloadAction<{ boundingRect: BoundingRect | null; vesselLocation: MapPoints | null; vesselIcon: any }>
    ) {
      state.boundingRect = action.payload.boundingRect;
      state.vesselLocation = action.payload.vesselLocation;
      state.vesselIcon = action.payload.vesselIcon;
    },
  },
});

export const { setTooltipReferenceElement } = VesselTooltipReferenceSlice.actions;

export default VesselTooltipReferenceSlice.reducer;
