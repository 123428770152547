import { useMemo, useRef } from "react";
import { TransferProvider } from "./utils";
import { TransferBase } from "./Transfer.styles";
import { TransferProps, TransferItem } from "./Transfer.types";
import TransferSection from "./TransferSection";
import TransferTable from "./TransferTable";
import Spinner from "components/Loaders/Spinner";

const Transfer = (props: TransferProps) => {
  const { items, onChange, label = "Vessels", loading } = props;
  const onChangeRef = useRef(onChange);
  const separatedItems = useMemo(
    () =>
      items.reduce<[TransferItem[], TransferItem[]]>(
        (acc, field) => {
          const [available, selected] = acc;
          if (field.selected) {
            selected.push(field);
          } else {
            available.push(field);
          }
          return acc;
        },
        [[], []]
      ),
    [items]
  );
  const value = useMemo(
    () => ({ onChange: onChangeRef.current, label, items: { available: separatedItems[0], selected: separatedItems[1] } }),
    [label, separatedItems]
  );

  if (loading) {
    return (
      <TransferBase>
        <Spinner />
      </TransferBase>
    );
  }

  return (
    <TransferProvider value={value}>
      <TransferBase>
        <TransferSection align="left">
          <TransferTable />
        </TransferSection>
        <TransferSection align="right">
          <TransferTable />
        </TransferSection>
      </TransferBase>
    </TransferProvider>
  );
};

export { Transfer };
