import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AISState } from "types/ReduxState/AISState.types";
import { ERROR_MESSAGE } from "constants/api/common";
import axios from "config/api";
import { AISRequest, AISResponse } from "types/AIS.types";
import { map } from "constants/api/map";

const initialState: AISState = {
  status: "idle",
  data: null,
  error: null,
};

export const fetchVesselLocations = createAsyncThunk("map/fetchVesselLocations", async (payload: AISRequest, thunkAPI) => {
  try {
    const res = await axios.post<AISResponse>(map.AIS, payload);
    return res.data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err?.response?.data?.msg || ERROR_MESSAGE);
  }
});

export const mapSlice = createSlice({
  name: "map",
  initialState,
  reducers: {
    cleanup(state) {
      state.data = null;
      state.error = null;
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchVesselLocations.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(fetchVesselLocations.fulfilled, (state, action) => {
      state.status = "success";
      state.data = action.payload;
      state.error = null;
    });
    builder.addCase(fetchVesselLocations.rejected, (state, action) => {
      state.error = action.payload as string;
      state.data = null;
      state.status = "error";
    });
  },
});

export const { cleanup } = mapSlice.actions;
export default mapSlice.reducer;
