import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "config/api";
import { ReduxState } from "types/ReduxState/common.types";
import { FleetCoverageRequest, FleetCoverageResponse } from "types/Reports.types";
import { reports } from "constants/api/reports";
import { ERROR_MESSAGE } from "constants/api/common";

const initialState: ReduxState<FleetCoverageResponse> = {
  status: "idle",
  data: null,
  error: null,
};

export const getCoverageOverview = createAsyncThunk("reportsCoverage", async (payload: FleetCoverageRequest, { rejectWithValue }) => {
  try {
    const response = await axios.post<FleetCoverageResponse>(reports.fleetCoverage, payload);
    return response.data;
  } catch (e) {
    return rejectWithValue(JSON.stringify(e.response?.data ?? ERROR_MESSAGE));
  }
});

const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCoverageOverview.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(getCoverageOverview.fulfilled, (state, action) => {
      state.status = "success";
      state.data = action.payload;
      state.error = null;
    });
    builder.addCase(getCoverageOverview.rejected, (state, action) => {
      state.error = JSON.stringify(action.payload);
      state.status = "error";
      state.data = null;
    });
  },
});

export default reportsSlice.reducer;
