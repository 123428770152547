import { secondsToMilliseconds } from "date-fns";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { CommentsV1Service, GetActivityConversationResponse, CommentThread } from "@shipin/shipin-app-server-client";
import { queryKeys } from "config";
import { CommentProps, TransformedDataProps } from "components/CommentDrawer/Comments.types";

type CommentMutationParam = { onSuccess?: () => void };

const useNotificationCountQuery = () =>
  useQuery({
    ...queryKeys.notifications.all,
    queryFn: CommentsV1Service.getApiV1CommentsV1ReadStatusMyCounts,
    refetchInterval: secondsToMilliseconds(30),
  });

const commentData = (thread: CommentThread): CommentProps => {
  return {
    id: thread.comment?.id,
    created_at: thread.comment?.created_at,
    comment: thread.comment?.content?.text,
    comment_read_status: thread.comment?.read_status,
    comment_status: thread.comment?.status,
  };
};

const selectTransformedData = (data: GetActivityConversationResponse) => {
  const threads = data.threads?.sort((a, b) => a?.comment?.created_at?.localeCompare(b?.comment?.created_at ?? ""));
  const users = data.users;
  const transformedData: TransformedDataProps[] = [];
  threads.forEach((thread) => {
    if (transformedData.length > 0 && thread.comment.user_id === transformedData[transformedData.length - 1].user_id) {
      let comments = transformedData[transformedData.length - 1].comments || [];
      comments.push(commentData(thread));
    } else {
      transformedData.push({
        user_id: thread.comment.user_id,
        comments: [commentData(thread)],
      });
    }
    transformedData[transformedData.length - 1].users = users;
  });
  return transformedData;
};

const useCommentsQuery = (activityID: string | undefined) => {
  return useQuery({
    ...queryKeys.useComments.comments(activityID),
    queryFn: async () => {
      if (!activityID) return Promise.reject("Cannot fetch");
      return await CommentsV1Service.getApiV1CommentsV1ByActivity(activityID);
    },
    select: selectTransformedData,
    staleTime: 0,
    // Do not change cache time without prior discussion with the team.
    // It was used to fix a rare bug where Keep unread comments were marked as read.
    // For more info, please visit https://shipin.atlassian.net/browse/FV-2491
    gcTime: 0,
    enabled: !!activityID,
  });
};

const useReadStatusMutation = ({ onSuccess }: CommentMutationParam = {}) => {
  const client = useQueryClient();

  return useMutation({
    mutationFn: CommentsV1Service.postApiV1CommentsV1ReadStatus,
    onSuccess: () => {
      client.invalidateQueries({ ...queryKeys.notifications.all });
      onSuccess?.();
    },
  });
};

export { useNotificationCountQuery, useCommentsQuery, useReadStatusMutation };
