import { withStyles } from "@material-ui/styles";
import { Tooltip as MuiTooltip } from "@material-ui/core";
import { rem, color, rgba } from "config/variable.styles";

export const StyledTooltip = withStyles(
  {
    popper: {
      padding: 0,
      margin: `0 0 ${rem(20)}`,
      paddingLeft: rem(20),
      minWidth: rem(171),
      filter: `drop-shadow(0 ${rem(2)} ${rem(4)} ${rgba(color.Black, 0.15)})`,
      "&.MuiTooltip-popper .MuiTooltip-tooltip": {
        padding: `${rem(12)} ${rem(5)}`,
        backgroundColor: color.White,
        borderRadius: 0,
        fontSize: rem(14),
        fontWeight: "normal",
        margin: 0,
        textAlign: "center",
        "& .settings-tooltip": {
          textAlign: "center",
        },
        "&:has(.timelegends)": {
          transform: "translate3d(10px, 0px, 0px) !important",
        },
        "& .vertical": {
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          padding: "0 10px",
          "& .MuiChip-root": {
            marginBottom: rem(5),
            padding: `${rem(3)} ${rem(8)}`,
            "&.fleetTooltipBlock": {
              whiteSpace: "inherit",
              textAlign: "left",
              "& .MuiChip-label": {
                whiteSpace: "inherit",
              }
            }
          },
          "&.tooltip-scroll": {
            "& .chip-filter": {
              padding: `${rem(3)} ${rem(8)}`,
            },
            height: "200px",
            overflowY: "scroll",
            overflowX: "hidden",
          },
          "& .yellow": {
            background: "#f4ebca",
            color: `${color.saffronYellowColor}`,
          },
          "& .blue": {
            background: "#c5dbf6",
            color: `${color.dodgerBlueColor}`,
          },
          "& .purple": {
            background: "#e9cef6",
            color: `${color.amethystColor}`,
          },
          "& .green": {
            background: "#d7f5dc",
            color: ` ${color.emeraldColor}`,
          },
          "& .grey": {
            background: "#e7e7e7",
            color: `${color.santasGreyColor}`,
          },
          "& .red": {
            color: `${color.tomatoredColor}`,
            background: "#fbc1be",
          },
          "& .orange": {
            color: `${color.lightningYellowColor}`,
            background: "#f9dcb6",
          },
        },
        "& .MuiTooltip-arrow": {
          overflow: "hidden",
          height: rem(22),
          width: rem(33),
          left: "0 !important",
          margin: `0 0 0 0`,
          bottom: rem(-22),
          "&::before": {
            content: `""`,
            position: "absolute",
            width: 0,
            height: 0,
            borderTop: `${rem(17)} solid ${color.White}`,
            borderRight: `${rem(20)} solid transparent`,
            left: 0,
            backgroundColor: "transparent",
            transform: "inherit",
          },
        },
      },
      "&[x-placement='top-end']": {
        paddingRight: rem(20),
        paddingLeft: 0,
        "&.MuiTooltip-popper .MuiTooltip-tooltip": {
          "& .MuiTooltip-arrow": {
            left: "inherit !important",
            right: "0 !important",
            "&::before": {
              borderTop: `${rem(17)} solid ${color.White}`,
              borderLeft: `${rem(20)} solid transparent`,
              borderRight: "0px",
              right: 0,
              left: "auto",
            },
          },
        },
      },
      "&[x-placement='bottom-end']": {
        margin: `${rem(20)} 0 0`,
        "&.MuiTooltip-popper .MuiTooltip-tooltip": {
          "& .MuiTooltip-arrow": {
            left: "inherit !important",
            right: "0 !important",
            top: rem(-16),
            bottom: "auto",
            "&::before": {
              borderBottom: `${rem(17)} solid ${color.White}`,
              borderLeft: `${rem(20)} solid transparent`,
              borderTop: "0px",
              borderRight: "0px",
              right: 0,
              left: "auto",
            },
          },
        },
      },
      "&[x-placement='bottom-start']": {
        margin: `${rem(20)} 0 0`,
        "&.MuiTooltip-popper .MuiTooltip-tooltip": {
          "& .MuiTooltip-arrow": {
            left: "0 !important",
            right: "inherit !important",
            top: rem(-16),
            bottom: "auto",
            "&::before": {
              borderBottom: `${rem(17)} solid ${color.White}`,
              borderRight: `${rem(20)} solid transparent`,
              borderTop: "0px",
              borderLeft: "0px",
              right: "auto",
              left: 0,
            },
          },
        },
      },
    },
  },
  { name: "flags-tooltip" }
)(MuiTooltip);

export const Tooltip = withStyles(
  {
    popper: {
      padding: 0,
      margin: `0 0 ${rem(20)}`,
      paddingRight: rem(20),
      filter: `drop-shadow(0 ${rem(2)} ${rem(4)} ${rgba(color.Black, 0.26)})`,
      "&.MuiTooltip-popper .MuiTooltip-tooltip": {
        padding: `${rem(12)} ${rem(14)} ${rem(12)} ${rem(20)}`,
        backgroundColor: color.White,
        borderRadius: 0,
        fontSize: rem(14),
        fontWeight: "normal",
        margin: 0,
        "& .MuiTooltip-arrow": {
          overflow: "hidden",
          height: rem(22),
          width: rem(33),
          left: "inherit !important",
          right: "0 !important",
          margin: `0 0 0 0`,
          bottom: rem(-22),
          "&::before": {
            content: `""`,
            position: "absolute",
            width: 0,
            height: 0,
            borderTop: `${rem(17)} solid ${color.White}`,
            borderLeft: `${rem(20)} solid transparent`,
            right: 0,
            backgroundColor: "transparent",
            transform: "inherit",
          },
        },
      },
      "&[x-placement='bottom-start']": {
        margin: `${rem(20)} 0 0`,
        "&.MuiTooltip-popper .MuiTooltip-tooltip": {
          "& .MuiTooltip-arrow": {
            left: "inherit !important",
            right: "0 !important",
            top: rem(-16),
            bottom: "auto",
            "&::before": {
              borderBottom: `${rem(17)} solid ${color.White}`,
              borderLeft: `${rem(20)} solid transparent`,
              borderTop: "0px",
              borderRight: "0px",
              right: 0,
              left: "auto",
            },
          },
        },
      },
      "&[x-placement='bottom-end']": {
        margin: `${rem(16)} 0 0`,
        "&.MuiTooltip-popper .MuiTooltip-tooltip": {
          "& .MuiTooltip-arrow": {
            left: "inherit !important",
            right: "0 !important",
            top: rem(-16),
            bottom: "auto",
            "&::before": {
              borderBottom: `${rem(17)} solid ${color.White}`,
              borderLeft: `${rem(20)} solid transparent`,
              borderTop: "0px",
              borderRight: "0px",
              right: 0,
              left: "auto",
            },
          },
        },
      },
    },
  },
  { name: "promotion-tooltip" }
)(MuiTooltip);
