import { Skeleton as Loader, SkeletonProps } from "@material-ui/lab";
import { ReactNode } from "react";

interface Props extends SkeletonProps {
  loading: boolean | undefined;
  children: ReactNode;
  whiteBg?: boolean;
}
const Skeleton = ({ loading, children, whiteBg, className, ...rest }: Props) => {
  return loading ? (
    <Loader
      className={whiteBg ? (className ? `${className} whiteBgskeleton` : "whiteBgskeleton") : className}
      animation="wave"
      height={20}
      width={60}
      {...rest}
    />
  ) : (
    <>{children}</>
  );
};

export default Skeleton;
