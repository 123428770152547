interface ArrowProps {
  color?: string;
  size?: string;
}

const Chevron = ({ color = "currentColor", size = "12px" }: ArrowProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L6 7L11 1" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export { Chevron };
