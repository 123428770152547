import { NavigationTimelineItem } from "@shipin/shipin-app-server-client";
import { color } from "config/variable.styles";

export const beaufortTimelineColors = [
  "#292573",
  "#30325a",
  "#636ca0",
  "#818fb7",
  "#9eb3cd",
  "#bbd6e4",
  "#d9fafb",
  "#dbbcbd",
  "#dd7d7e",
  "#942f2f",
  "#300f11",
];

export const visibilityTimelineColor = ["#ffffff", "#c0d8ff", "#8ab8ff", "#1f60c4", "#1f60c4", "#0a2c5d", "#0a2c5d"];

export const dayNightColor = {
  MORNING_TWILIGHT: { color: "#969696", text: "Morning Twilight" },
  DAYLIGHT: { color: color.White, text: " Daylight" },
  EVENING_TWILIGHT: { color: "#969696", text: "Evening Twilight" },
  NIGHT: { color: "#3a3a3a", text: "Night" },
};

export const navigationTimelineColors: Record<`${NavigationTimelineItem.status}`, string> = {
  UNDERWAY_50: "#0370c0",
  UNDERWAY_12: "#4ea6e2",
  UNDERWAY_RESTRICTED_WATER: "#6fe0f7",
  UNDERWAY_NEAR_PORT: "#ed7d31",
  UNDERWAY_HEAVY_TRAFFIC: "#feff00",
  MOORED: "#7031a0",
  AT_ANCHOR: "#956741",
  OTHER: "#afabab",
  CONFLICTING_INFORMATION: "#afabab",
  NOT_UNDER_COMMAND: "#bf2601",
  UNDERWAY: "#0370c0",
};
