import { useMemo } from "react";

import { parseDateRange } from "components/Filters/DateRangePicker";
import { useAppSelector } from "./reduxHooks";

/**
 * Hook that returns current start / end date and
 * helper variables and functions.
 */
function useDateRange() {
  const range = useAppSelector((state) => state.tableFilterBar.dateRange);

  const options = useMemo(() => parseDateRange(range.start, range.end, range.live, range.zoom), [range.end, range.live, range.start, range.zoom]);

  return {
    range,
    ...options,
  };
}

export default useDateRange;
