import { Checkbox } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { colors } from "ui/Theme/colors";

const StyledCheckbox = withStyles(
  {
    root: {
      color: colors.gray[950],
      "&$checked": {
        color: colors.primary[500],
      },
      "&.MuiIconButton-colorSecondary:hover": {
        backgroundColor: "transparent",
      },
    },
    checked: {},
  },
  {
    name: "styled-checkbox",
  }
)(Checkbox);

export { StyledCheckbox };
