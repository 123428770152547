import { Vessel } from "types/Fleet.types";
import useFleets, { Fleets } from "./useFleets";

const transform = (data: Fleets) => {
  const map: Record<string, Array<Vessel & { fleet_name: string; fleet_id: string }>> = {};
  data?.fleets.forEach((fleet) => {
    fleet.vessels.forEach((vessel) => {
      if (map[vessel.id]) {
        map[vessel.id].push({ fleet_id: fleet.id, fleet_name: fleet.name, ...vessel });
      } else {
        map[vessel.id] = [{ fleet_id: fleet.id, fleet_name: fleet.name, ...vessel }];
      }
    });
  });
  return map;
};

/**
 * Returns a list of all the fleets associated to a vessel.
 * Accepts an optional argument vessel id. When passed,
 * it will return particular fleets associated with given vesselid.
 */
function useFleetsfromVessel(): { data: Record<string, Array<Vessel & { fleet_name: string; fleet_id: string }>>; loading: boolean; error: boolean };
function useFleetsfromVessel(vesselId: string | undefined): {
  data: Array<Vessel & { fleet_name: string; fleet_id: string }>;
  loading: boolean;
  error: boolean;
};
function useFleetsfromVessel(vesselId?: string | undefined) {
  const { data: fleetsInfo, loading, error } = useFleets(transform);
  const fleetData = vesselId ? fleetsInfo[vesselId] : fleetsInfo;
  return { data: fleetData, loading, error };
}

export default useFleetsfromVessel;
