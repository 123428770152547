import { combineReducers } from "@reduxjs/toolkit";
import mapReducer from "./Map/Map.slice";
import activityDetailsReducer from "./Activities/activityDetails.slice";
import fetchFleets from "./Fleets/Fleets.slice";
import TableFilterBarReducer from "./TableFilterBar/TableFilterBar.slice";
import VesselTooltipReferenceReducer from "./Map/VesselTooltipReference.slice";
import PointVesselTooltipReferenceReducer from "./Map/PointVessel.slice";
import RefreshTimerSlice from "./RefreshTimer/RefreshTimer.slice";
import GlobalDBTooltipReferenceReducer from "./Map/GlobalDBTooltipReference.slice";
import signedUrlReducer from "./User/signedUrl.slice";
import ghostVesselSlice from "./Map/GhostVessel.slice";
import AISSlice from "./Voyage/AIS.slice";
import reports from "./Reports/Reports.slice";
import reportsFilter from "./TableFilterBar/ReportsFilter.slice";
import reportStats from "./Reports/ReportStats.slice";
import bridgeSingleActivity from "./Reports/BridgeSingleActivity.slice";
import fleetCoverageDay from "./Reports/FleetCoverageDay.slice";
import reportsSelection from "./Reports/ReportsSelector.slice";
import reportsFilterOld from "./TableFilterBar/ReportsFilter.old.slice";
import dashboardSlice from "./Dashboard/Dashboard.slice";
import sidebar from "./Activities/sidebar.slice";
import tagSwitchReducer from "./TableFilterBar/TagFilterSwitch.reducer";
import editCommentSlice from "./Comments/editComment.slice";
import usersFilterSlice from "./TableFilterBar/UsersFilter.slice";
import performanceAnalyticsSelector from "./Reports/PerformanceAnalyticsSelector.slice";
import fleetSwitchReducer from "./TableFilterBar/FleetFilterSwitch.slice";
import performanceAnalyticsFilter from "./Filters/PerformanceAnalyticsFilter.slice";
import activityListFilter from "./TableFilterBar/ActivityListFilters.slice";

export default combineReducers({
  map: mapReducer,
  activityDetails: activityDetailsReducer,
  fleetInfo: fetchFleets,
  tableFilterBar: TableFilterBarReducer,
  VesselTooltipReference: VesselTooltipReferenceReducer,
  PointVesselTooltipReference: PointVesselTooltipReferenceReducer,
  refreshTimer: RefreshTimerSlice,
  GlobalDBTooltipReference: GlobalDBTooltipReferenceReducer,
  signedUrl: signedUrlReducer,
  ghostVessel: ghostVesselSlice,
  ais: AISSlice,
  reports,
  reportsFilter,
  reportStats,
  bridgeSingleActivity,
  fleetCoverageDay,
  reportsSelection,
  reportsFilterOld,
  dashboard: dashboardSlice,
  sidebar,
  tagSwitch: tagSwitchReducer,
  editComment: editCommentSlice,
  usersFilter: usersFilterSlice,
  performanceAnalyticsSelector,
  fleetFilterSwitch: fleetSwitchReducer,
  performanceAnalyticsFilter,
  activityListFilter,
});
