import { CircularProgress, CircularProgressProps } from "@material-ui/core";

const Spinner = (props: CircularProgressProps) => {
  return (
    <div className="spinner">
      <CircularProgress {...props} />
    </div>
  );
};

export default Spinner;
