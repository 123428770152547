const Help = () => {
  return (
    <span className="icon icon-help">
      <svg xmlns="http://www.w3.org/2000/svg" width="24.368" height="24" version="1.1" viewBox="0 0 23.368 23.368" xmlSpace="preserve">
        <path
          stroke="none"
          strokeDasharray="none"
          strokeOpacity="1"
          strokeWidth="0.779"
          d="M11.684 0C5.068 0 0 5.068 0 11.684s5.068 11.684 11.684
           11.684S23.368 18.3 23.368 11.684 18.3 0 11.684 0zm0 1.684a9.985
            9.985 0 0110 10c0 5.535-4.465 10-10 10s-10-4.465-10-10 4.465-10
             10-10zm0 4c-2.197 0-4 1.803-4 4h2c0-1.117.883-2 2-2s2 .883 2 2c0
              .767-.491 1.445-1.219 1.687l-.406.125a2.023 2.023
               0 00-1.375 1.907v1.281h2v-1.281l.406-.125a3.808 3.808 0 002.594-3.594c0-2.197-1.803-4-4-4zm-1 10v2h2v-2z"
          color="#000"
          fontFamily="'Bitstream Vera Sans'"
          overflow="visible"
        ></path>
      </svg>
    </span>
  );
};

export default Help;
