import { memo } from "react";
import { TableRow, TableCell, Checkbox } from "@material-ui/core";
import { useTransfer } from "../../utils";
import { TransferItem } from "components/Transfer/Transfer.types";
import { isPreviewEnabled } from "utils";

const TransferRow = (props: TransferItem) => {
  const { id, name, selected } = props;
  const { onChange } = useTransfer();

  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={selected}
      tabIndex={-1}
      key={id}
      selected={selected}
      onClick={() => onChange?.((values) => values.map((e) => (e.id === id ? { ...e, selected: !selected } : e)))}
    >
      <TableCell padding="checkbox">
        <Checkbox checked={selected} inputProps={{ "aria-labelledby": id }} />
      </TableCell>
      <TableCell>{name}</TableCell>
      {isPreviewEnabled && <TableCell>Technical Manager</TableCell>}
    </TableRow>
  );
};

export default memo(TransferRow);
