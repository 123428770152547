import { Grid } from "@material-ui/core";
import ProfileLeftForm from "components/ProfileForm/ProfileLeftForm";
import UserProfileTab from "components/ProfileForm/UserProfileTab";

const UserInformation = () => {
  return (
    <Grid container>
      <Grid item xs={6} style={{ marginTop: "60px" }}>
        <UserProfileTab />
      </Grid>
      <Grid item xs={5} style={{ marginTop: "30px" }}>
        <ProfileLeftForm />
      </Grid>
    </Grid>
  );
};

export default UserInformation;
