import { Fleet, FleetsInfoResponse, Vessel } from "types/Fleet.types";

/**
 * Removes duplicate vessels from fleets info.
 * @param data fleets info API response.
 */
export function removeDuplicateVessels(data: FleetsInfoResponse | null) {
  const vessels: Record<string, number> = {};
  const fleetsResponse = data?.fleets.map((f) => {
    return {
      ...f,
      vessels: f.vessels.filter((v) => {
        if (v.id in vessels) return false;
        vessels[v.id] = 1;
        return true;
      }),
    };
  }) as FleetsInfoResponse["fleets"];
  return { ...data, fleets: fleetsResponse };
}

interface Options {
  removeDuplicates: boolean;
}
export function forEachVessels<T>(
  response: FleetsInfoResponse | null,
  callback: (fleet: Fleet, vessel: Vessel) => T | T[] | void,
  options?: Options
) {
  const transformedItems: T[] = [];
  forEachFleets(
    response,
    (fleet) => {
      fleet.vessels.forEach((vessel) => {
        const value = callback(fleet, vessel);
        if (!value) return;
        if (Array.isArray(value)) {
          transformedItems.push(...value);
        } else {
          transformedItems.push(value);
        }
      });
    },
    options
  );
  return transformedItems;
}

export function forEachFleets<T>(response: FleetsInfoResponse | null, callback: (fleet: Fleet) => T | T[] | void, options?: Options) {
  if (!response) return;
  let fleets = [...response.fleets];
  if (options?.removeDuplicates) {
    fleets = removeDuplicateVessels(response).fleets;
  }
  const transformedItems: T[] = [];
  fleets.forEach((fleet) => {
    const value = callback(fleet);
    if (!value) return;
    if (Array.isArray(value)) {
      transformedItems.push(...value);
    } else {
      transformedItems.push(value);
    }
  });
  return transformedItems;
}
