import { useMemo, useRef } from "react";
import { useAppSelector } from "hooks/reduxHooks";
import { FleetsInfoResponse } from "types/Fleet.types";

export type Fleets = FleetsInfoResponse | null;
interface ReturnType<T> {
  data: T;
  loading: boolean;
  error: boolean;
}
function useFleets(): ReturnType<Fleets>;
function useFleets<T>(format?: (data: Fleets) => T): ReturnType<T>;
function useFleets<T>(format?: (data: Fleets) => T) {
  const formatRef = useRef(format);
  const { data: fleets, status } = useAppSelector((state) => state.fleetInfo);

  const data = useMemo(() => {
    if (!formatRef.current) return fleets;
    return formatRef.current?.(fleets);
  }, [fleets]);

  return { data, loading: status === "loading", error: status === "error" };
}

export default useFleets;
