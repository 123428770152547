import { FormControl, Select } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import styled from "styled-components";
import { rem, color } from "../../config/variable.styles";

export const dff = styled.div`
  .custome-select {
    &.multi-select .MuiSelect-root {
      white-space: normal;
      text-overflow: inherit;
    }

    .MuiSelect-root {
    }
  }
`;
export const FormControlStyle = withStyles(
  {
    root: {
      width: "100%",
      "& .MuiInput-formControl": {
        "&:before, &:after": {
          display: "none",
        },
      },
      "& .icon-chevron": {
        height: "16px",
        top: "auto",
        color: color.DarkBlack,
        cursor: "pointer",
        pointerEvents: "none",
        right: 0,
        position: "absolute",
        marginRight: rem(15),
      },
    },
  },
  { name: "formcontrol-select" }
)(FormControl);

export const FleetsDropdownStyle = withStyles(
  {
    select: {
      background: color.White,
      fontSize: rem(14),
      lineHeight: rem(28),
      padding: `${rem(6)} ${rem(4)}`,
      paddingRight: `${rem(22)} !important`,
      color: color.DarkBlack,
      whiteSpace: "inherit",
      maxHeight: rem(250),
      overflowY: "auto",
      "&:focus": {
        background: color.White,
      },
      "& .placeholder": {
        color: color.DarkBlack,
        fontSize: rem(16),
        marginLeft: rem(8),
      },
      "&.Mui-disabled": {
        backgroundColor: color.disabledBgColor,
      },
    },
    disabled: {
      "& .placeholder": {
        color: color.warmGreyTwo,
        opacity: 0.5,
      },
    },
  },
  { name: "custome-select" }
)(Select);

export const AssociatedFleetLabelStyle = styled.div`
  display: flex;
  align-items: center;
  .icon {
    flex: 0 0 ${rem(17)};
    width: ${rem(17)};
    height: ${rem(17)};
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .label-text {
    font-size: ${rem(14)};
    color: ${color.DarkBlack};
    line-height: ${rem(17)};
    flex: 1 1 auto;
  }
`;

export const DropdownSearchbox = styled.div`
  padding: 0 ${rem(16)} ${rem(13)};
  width: ${rem(410)};
  .search-group {
    display: flex;
    position: relative;
    background: ${color.White};
    padding: 0 ${rem(10)};
    .MuiButtonBase-root {
      padding: ${rem(10)};
      font-size: ${rem(17)};
      background: transparent !important;
      .MuiTouchRipple-root {
        display: none;
      }
    }
    .MuiInputBase-root {
      font-size: ${rem(14)};
      color: ${color.DarkBlack};
    }
  }
  &:focus {
    outline: none;
    box-shadow: inherit;
    border: none;
  }
`;

export const NoData = styled.div`
  padding: 0 ${rem(16)} ${rem(13)};
  width: ${rem(410)};
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
