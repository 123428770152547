import { CSSProperties } from "react";
import { PlaceholderStyle } from "./TablePlaceholder.styles";
import NoData from "../Icon/NoData";

interface TablePlaceholderProps {
  isError?: boolean;
  style?: CSSProperties;
}
const TablePlaceholder = (props: TablePlaceholderProps) => {
  const { isError = false, style = {} } = props;
  const message = isError ? "Error Fetching Data" : "No Data";

  return (
    <PlaceholderStyle
      style={{
        ...style,
        ...(isError ? { color: "darkred" } : {}),
      }}
    >
      {!isError ? <NoData /> : null}
      {message}
    </PlaceholderStyle>
  );
};

export default TablePlaceholder;
