import { useCallback, useRef, useState } from "react";
import { AxiosResponse, CancelToken } from "axios";

import { Status } from "types/ReduxState/common.types";

type State<T> = {
  status: Status;
  data: T | null;
};
type Options<TRequest, TResponse> = {
  fetcher: (req: TRequest, cancelToken?: CancelToken) => Promise<AxiosResponse<TResponse>>;
  onSuccess?: (data: TResponse) => void;
  onError?: (err: any) => void;
};
function useAsync<TRequest extends unknown, TResponse extends unknown>(options: Options<TRequest, TResponse>) {
  const [{ status, data }, setState] = useState<State<TResponse>>({ status: "idle", data: null });
  const optionsRef = useRef(options);

  const fetch = useCallback(async (request: TRequest, cancelToken?: CancelToken) => {
    setState((state) => ({ ...state, status: "loading" }));
    try {
      const { data } = await optionsRef.current.fetcher(request, cancelToken);
      setState({ data, status: "success" });
      optionsRef.current.onSuccess?.(data);
    } catch (e) {
      setState({ data: null, status: "error" });
      optionsRef.current?.onError?.(e);
    }
  }, []);

  return {
    loading: status === "loading",
    error: status === "error",
    fetch,
    data,
  };
}

export default useAsync;
