import { createContext, useContext } from "react";

/**
 * Context for storing the activity ID.
 * Use it to pass activtiyID to the component tree without prop drilling.
 */
const ActivityIDContext = createContext<string | null>(null);

export const useActivityIDContext = () => {
  const context = useContext(ActivityIDContext);
  if (context === null) {
    throw new Error("useActivityIDContext must be used within a ActivityIDProvider");
  }
  return context;
};

export const ActivityIDProvider = ActivityIDContext.Provider;
