import { createSlice } from "@reduxjs/toolkit";
import { UserRoleType } from "types";

const initialState: {
  role: UserRoleType[];
  fleet: string[];
  shipManager: string[];
  company: string[];
  designation: string[];
} = {
  role: [],
  fleet: [],
  shipManager: [],
  company: [],
  designation: [],
};

const usersFilterSlice = createSlice({
  name: "UsersFilter",
  initialState,
  reducers: {
    setUserRoleFilter: (state, action) => {
      state.role = action.payload;
    },
    setUserFleetFilter: (state, action) => {
      state.fleet = action.payload;
    },
    setShipManagerFilter: (state, action) => {
      state.shipManager = action.payload;
    },
    setCompanyFilter: (state, action) => {
      state.company = action.payload;
    },
    setDesignationFilter: (state, action) => {
      state.designation = action.payload;
    },
    clearUserFilter: () => initialState,
  },
});

export const { setUserRoleFilter, setUserFleetFilter, setShipManagerFilter, setDesignationFilter, setCompanyFilter, clearUserFilter } =
  usersFilterSlice.actions;
export default usersFilterSlice.reducer;
