import { rem } from "config/variable.styles";
import styled from "styled-components";
import { colors } from "ui/Theme/colors";

const UnstyledButton = styled.button`
  all: unset;
  cursor: pointer;
`;

const ButtonBaseOld = styled(UnstyledButton)`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  border-radius: ${rem(8)};
  padding: ${rem(12)} ${rem(28)};
  background-color: ${colors.primary[500]};
  color: white;
  font-weight: 500;
  font-size: ${rem(14)};
  text-transform: uppercase;
  letter-spacing: 1.25px;
  transition: all 0.2s ease;

  .MuiCircularProgress-colorPrimary {
    color: white;
  }

  &.small {
    font-weight: 400;
    padding: ${rem(8)} ${rem(16)};
  }

  &:hover {
    background-color: #6f68f6;
  }

  &:active {
    background-color: #3f37c4;
  }

  &:disabled {
    background-color: ${colors.gray[400]};
    pointer-events: none;
  }
`;

const ButtonBase = styled(UnstyledButton)`
  display: inline-flex;
  align-items: center;
  gap: ${rem(8)};
  border-radius: ${rem(8)};
  padding: ${rem(4)} ${rem(12)};
  font-size: ${rem(14)};
  line-height: ${rem(20)};
  transition: all 0.2s ease;

  &:disabled {
    pointer-events: none;
  }
`;

const StyledButton = styled(ButtonBase)`
  background-color: ${colors.primary[500]};
  color: white;
  border: 1px solid ${colors.primary[500]};

  .MuiCircularProgress-colorPrimary {
    color: black;
  }

  :active {
    background-color: #3f37c4;
    border: 1px solid ${colors.primary[500]};
  }

  :hover {
    background-color: #6f68f6;
    border: 1px solid ${colors.primary[400]};
  }

  :disabled {
    color: ${colors.gray[400]};
    border: 1px solid ${colors.gray[300]};
    background-color: ${colors.gray[100]};
  }

  &.secondary {
    background-color: ${colors.gray[50]};
    border: 1px solid ${colors.gray[300]};
    color: black;

    :active {
      background-color: rgba(68, 63, 255, 0.2);
      border: 1px solid ${colors.primary[300]};
    }

    :hover {
      background-color: rgba(167, 174, 255, 0.2);
      border: 1px solid ${colors.primary[400]};
    }

    :disabled {
      color: ${colors.gray[400]};
      border: 1px solid ${colors.gray[200]};
      background-color: ${colors.gray[50]};
    }
  }
`;

// This is not clickable, it's just a label with button styles, so keeping it as a span
const LabelButton = styled.span`
  display: inline-block;
  padding: ${rem(2)} ${rem(12)};
  font-size: ${rem(14)};
  line-height: ${rem(24)};
  color: black;
  border: none;
  border-radius: ${rem(8)};
  white-space: nowrap;
  text-align: center;

  &.bold {
    font-weight: 600;
  }

  &.white {
    color: white;
  }
`;

const ManagementButton = styled(ButtonBase)`
  color: black;
  white-space: nowrap;
  background-color: ${colors.gray[50]};
  outline: 1px solid ${colors.gray[300]};
  transition: all 0.2s ease;

  &:disabled {
    opacity: 0.5;
  }

  &.to-do,
  &.in-progress,
  &.resolved {
    outline: 1px solid ${colors.gray[800]};
  }

  &.to-do {
    background-color: ${colors.red[200]};
  }

  &.in-progress {
    background-color: ${colors.orange[200]};
  }

  &.resolved {
    background-color: ${colors.green[200]};
  }
`;

export { UnstyledButton, StyledButton, ButtonBaseOld, LabelButton, ManagementButton };
