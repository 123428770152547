import {
  TableCell,
  TableRow,
  withStyles,
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableHead as MuiTableHead,
  TableSortLabel,
} from "@material-ui/core";
import { colors } from "ui/Theme/colors";

export const TableHeadCell = withStyles(
  {
    root: {
      fontSize: 14,
      backgroundColor: colors.gray[100],
      fontWeight: 600,
      color: colors.gray[800],
      border: "none",
      padding: "12px 16px",
      cursor: "pointer",
      "&:hover": {
        color: colors.gray[800],
      },
      "&:hover .sort-icon": {
        opacity: 1,
      },
      "&[aria-sort='ascending'] .sort-icon": {
        opacity: 1,
        "& .up-icon": {
          color: colors.gray[400],
        },
      },
      "&[aria-sort='descending'] .sort-icon": {
        opacity: 1,
        "& .down-icon": {
          color: colors.gray[400],
        },
      },
    },
  },
  {
    name: "table-head-cell",
  }
)(TableCell);

export const TableSort = withStyles(
  {
    root: {
      color: colors.gray[800],
      fontWeight: 600,
      "&:hover": {
        color: `${colors.gray[800]} !important`,
      },
      "& .sort-icon": {
        color: "white",
        marginLeft: 4,
        opacity: 0,
      },
    },
  },
  { name: "table-sort-label" }
)(TableSortLabel);

export const TableItemCell = withStyles(
  {
    root: {
      fontSize: 12,
      padding: "9.5px 16px",
      color: colors.gray[950],
      borderBottom: `1px solid ${colors.gray[200]}`,
    },
  },
  { name: "table-cell" }
)(TableCell);

export const TableHeadRow = withStyles(
  {
    root: {},
  },
  { name: "table-head-row" }
)(TableRow);

export const TableItemRow = withStyles({}, { name: "table-item-row" })(TableRow);

export const TableBody = withStyles({ root: {} }, { name: "table-body" })(MuiTableBody);

export const TableHead = withStyles(
  {
    root: {},
  },
  { name: "table-head" }
)(MuiTableHead);

export const Table = withStyles(
  {
    root: {
      "& .MuiTableHead-root .MuiTableRow-root": {
        "& .MuiTableCell-root:hover": {
          backgroundColor: `${colors.gray[100]} !important`,
        },
        "& .MuiTableCell-root[aria-sort='ascending']": {
          backgroundColor: `${colors.gray[100]} !important`,
          "&:hover": {
            backgroundColor: `${colors.gray[100]} !important`,
          },
        },
        "& .MuiTableCell-root[aria-sort='descending']": {
          backgroundColor: `${colors.gray[100]} !important`,
          "&:hover": {
            backgroundColor: `${colors.gray[100]} !important`,
          },
        },
      },
    },
  },
  { name: "table" }
)(MuiTable);
