import routes from "config/routes";
import { NestedRouteConfig } from "components/NestedRoute";
import UserTable from "components/User/UserTable/UserTable";

const getPath = (path: string) => `${routes.users.path}/${path}`;

type Routes = "active" | "pending" | "suspended";

export const usersRoutes: NestedRouteConfig<Routes> = {
  active: {
    path: getPath("active"),
    component: UserTable,
  },
  pending: {
    path: getPath("pending"),
    component: UserTable,
  },
  suspended: {
    path: getPath("suspended"),
    component: UserTable,
  },
};
