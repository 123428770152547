import { useState, useCallback } from "react";

/**
 * Implement form with dynamic number of fields for modals.
 */
function useModalForm() {
  const [rows, setRows] = useState([1]);
  const [error, setError] = useState(false);

  const handleDeleteRow = useCallback((id: number) => {
    setRows((r) => r.filter((row) => row !== id));
  }, []);

  const handleAddRow = () => {
    if (error) return;
    setRows((r) => [...r, r[r.length - 1] + 1]);
  };

  return {
    rows,
    handleDeleteRow,
    handleAddRow,
    error,
    setError,
  };
}

export { useModalForm };
