import { color, rem } from "config/variable.styles";
import styled from "styled-components";

export const NoDataPlaceholder = styled.div`
  padding: 0 ${rem(16)} ${rem(13)};
  width: ${rem(256)};
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SearchBar = styled.div`
  padding: 0 ${rem(16)} ${rem(13)};
  width: 100%;
  .search-group {
    display: flex;
    position: relative;
    background: ${color.White};
    padding: 0 ${rem(15)};
    .MuiButtonBase-root {
      padding: ${rem(10)} ${rem(0)};
      font-size: ${rem(17)};
      background: transparent !important;
      .MuiTouchRipple-root {
        display: none;
      }
    }
    .MuiInputBase-root {
      font-size: ${rem(14)};
      color: ${color.DarkBlack};
    }
  }
  &:focus {
    outline: none;
    box-shadow: inherit;
    border: none;
  }
`;

export const CheckboxLabel = styled.div<{ italic?: boolean }>`
  display: flex;
  align-items: center;
  .icon {
    flex: 0 0 ${rem(17)};
    width: ${rem(17)};
    height: ${rem(17)};
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .label-text {
    font-size: ${rem(14)};
    color: ${color.DarkBlack};
    line-height: ${rem(17)};
    /* padding: 0 0 0 ${rem(10)}; */
    flex: 1 1 auto;
    ${({ italic }) =>
      italic &&
      `
      font-style: italic;
    `}
  }
`;

// Created this to have disabled class to avoid inline styling
export const MultiSelectLabelRoot = styled.div`
  border-radius: ${rem(100)};
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
  }
`;
