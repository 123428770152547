const Search = () => {
  return (
    <span className="icon icon-search">
      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
        <path
          fill="#000"
          fillRule="nonzero"
          d="M24 22.94l-8.134-8.134A8.956 8.956 0 0018 9c0-4.963-4.037-9-9-9S0 
          4.037 0 9s4.037 9 9 9a8.954 8.954 0 005.806-2.134L22.939 24 24 22.94zM9
          16.5c-4.135 0-7.5-3.365-7.5-7.5S4.865 1.5 9 1.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5z"
        ></path>
      </svg>
    </span>
  );
};

export default Search;
