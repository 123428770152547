import { TableHead, TableRow, Checkbox, TableCell } from "@material-ui/core";
import { useTransfer } from "components/Transfer/utils";
import { isPreviewEnabled } from "utils";

interface TransferTableHeadProps {
  checked?: boolean;
  disabled?: boolean;
  onChange?: () => void;
  align?: string;
}
const TransferTableHead = (props: TransferTableHeadProps) => {
  const { onChange, align } = props;
  const { label } = useTransfer();
  const flagEnabled = label === "Vessels";
  const fleetsLabel = align === "left" ? "All" : "Fleets";
  const labelHead = label === "Fleets" ? fleetsLabel : label;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox {...props} onChange={onChange} inputProps={{ "aria-label": `select all ${label}s` }} />
        </TableCell>
        {flagEnabled && <TableCell>Flag</TableCell>}
        <TableCell>{labelHead}</TableCell>
        {isPreviewEnabled && <TableCell>Technical Manager</TableCell>}
      </TableRow>
    </TableHead>
  );
};

export default TransferTableHead;
