import { useState, ChangeEvent } from "react";
import { Tab } from "@material-ui/core";
import { NotificationContentStyle, NotificationTab } from "./NotificationContent.styles";
import { TabPanel, getTabProps } from "./TabPanel";
import Comments from "./Comments";
import { usePermissions } from "queries";

const NotificationContent = () => {
  const [value, setValue] = useState(0);
  const permissons = usePermissions();

  const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <NotificationContentStyle>
      <div className="tab-head">
        <h2 className="title">Notifications</h2>
        <NotificationTab value={value} onChange={handleChange} aria-label="notification tabs">
          <Tab label="All Comments" {...getTabProps(0)} />
          <Tab label="Unread Comments" {...getTabProps(1)} />
          {permissons?.can_add_modify_comments && <Tab label="My Comments" {...getTabProps(2)} />}
        </NotificationTab>
      </div>
      <div className="tab-content">
        <TabPanel value={value} index={0}>
          <Comments commentFilter="ALL" />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Comments commentFilter="UNREAD" />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Comments commentFilter="MY" />
        </TabPanel>
      </div>
    </NotificationContentStyle>
  );
};

export default NotificationContent;
