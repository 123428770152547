const Success = () => {
  return (
    <span className="icon icon-success">
      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
        <defs>
          <filter id="yf1xw1kw8a" colorInterpolationFilters="auto">
            <feColorMatrix in="SourceGraphic" values="0 0 0 0 0.235294 0 0 0 0 0.854902 0 0 0 0 0.674510 0 0 0 1.000000 0" />
          </filter>
        </defs>
        <g fill="none" fillRule="evenodd">
          <g>
            <g>
              <g
                filter="url(#yf1xw1kw8a)"
                transform="translate(-933.000000, -350.000000) translate(911.000000, 320.000000) translate(22.000000, 30.000000)"
              >
                <g>
                  <path
                    fill="#000"
                    fillRule="nonzero"
                    d="M34.148 5.852c-7.802-7.803-20.494-7.803-28.296 0-7.803 
                         7.8-7.803 20.496 0 28.296C9.752 38.05 14.876 40 20 40c5.124
                          0 10.247-1.95 14.148-5.852 7.803-7.8 7.803-20.496 0-28.296zm-2.964
                           8.657L19.51 26.181c-.325.325-.752.489-1.178.489-.427 0-.854-.164-1.18-.489l-6.67-6.67c-.651-.651-.651-1.705 
                           0-2.357.653-.652 1.707-.652 2.359 0l5.49 5.49 10.494-10.493c.652-.652 1.706-.652 2.358 0 .652.652.652 1.706
                            0 2.358z"
                    transform="translate(0.000000, 0.000342)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </span>
  );
};

export default Success;
