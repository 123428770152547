const TagIcon = () => (
  <svg width={15} height={15} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.89123 13.7816L13.7812 9.89164C13.9216 9.75101 14.0005 9.56039 
      14.0005 9.36164C14.0005 9.16289 13.9216 8.97226 13.7812 8.83164L6.06123 
      1.11165C6.02415 1.07285 5.97886 1.04285 5.92868 1.02385C5.87851 1.00475 
      5.82471 0.997247 5.77123 1.00165L1.94123 1.59165C1.84996 1.59645 1.76371 
      1.63485 1.69909 1.69945C1.63446 1.76415 1.59604 1.85035 1.59123 1.94165L1.00123 
      5.77164C0.996795 5.82512 1.00437 5.87892 1.0234 5.92909C1.04243 5.97927 1.07244 
      6.02456 1.11123 6.06164L8.83123 13.7816C8.97185 13.9221 9.16248 14.001 9.36123 
      14.001C9.55998 14.001 9.7506 13.9221 9.89123 13.7816Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.11133 4.61133C5.11133 4.88747 4.88747 5.11133 4.61133 5.11133C4.33519 
      5.11133 4.11133 4.88747 4.11133 4.61133C4.11133 4.33517 4.33519 4.11137 4.61133 
      4.11137C4.88747 4.11137 5.11133 4.33517 5.11133 4.61133Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export { TagIcon };
