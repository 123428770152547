import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isEditing: false,
};

export const editCommentSlice = createSlice({
  name: "editCommentSlice",
  initialState,
  reducers: {
    setEditMode: (state, action) => {
      state.isEditing = action.payload;
    },
  },
});

export const { setEditMode } = editCommentSlice.actions;

export default editCommentSlice.reducer;
