const Logo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="109.53" height="30" fill="none" viewBox="0 0 300 82">
      <path
        fill="#040066"
        fillRule="evenodd"
        d="M93.635 67.753c13.325 0 21.951-6.562 21.951-16.731 0-12.652-9.696-15.786-18.263-18.564-6.008-1.951-11.243-3.666-11.243-8.396 0-3.547
         2.855-5.675 7.674-5.675 4.878 0 8.09 2.424 9.518 7.153l11.303-4.316c-2.32-7.922-10.054-12.83-20.226-12.83-12.493 0-20.286 6.386-20.286
          16.673 0 11.588 9.4 14.663 17.668 17.323 6.306 2.07 11.72 3.843 11.72 8.986 0 3.903-3.213 6.268-8.626
           6.268-6.068 0-10.41-3.134-11.957-8.573l-11.244 4.316c2.915 9.105 10.887 14.366 22.011 14.366zM141.999 32.753c5.413 0 8.983 3.548 8.983
            8.987v25.008h11.838V39.316c0-10.228-6.841-17.086-17.014-17.086-4.818 0-9.28 1.596-12.492
             4.434l-.417.354V6.326H121v60.422h11.897V41.03c.06-4.67
             4.045-8.277 9.102-8.277zM169.364 23.353h11.897v43.395h-11.897V23.353zM175.372 2.424c-4.164 0-7.317 3.015-7.317
              7.035s3.153 7.036 7.317 7.036c4.105 0 7.198-3.016 7.198-7.036.06-4.02-3.093-7.035-7.198-7.035zM212.612 67.813c12.373
               0 21.713-9.755 21.713-22.762 0-13.184-8.983-22.762-21.356-22.762-5.592 0-10.351 2.01-13.742
                5.735l-.417.473v-5.203h-11.005v58.294h11.898V62.67l.416.414c3.272 3.193 7.436 4.73 12.493 4.73zM199.227 45.11c0-7.331 4.818-12.416
                 11.719-12.416 6.722 0 11.54 5.203 11.54 12.416 0 7.154-4.878 12.297-11.54 12.297-7.02 0-11.719-4.966-11.719-12.297z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#00B4DE"
        fillRule="evenodd"
        d="M239.679 9.341h12.017v57.408h-12.017V9.34zM279.179 32.754c5.414 0 8.983 3.547 8.983
         8.986v25.009H300V39.316c0-10.228-6.841-17.086-17.014-17.086-5.294 0-9.934 1.832-13.147
          5.084l-.416.414v-4.434H258.18v43.395h11.897V41.031c.06-4.671 4.046-8.277 9.102-8.277zM43.664
           13.775V5.971S32.421 0 28.554 0c-3.866 0-15.11 5.971-15.11 5.971v7.804S26.056 7.45 28.554 7.45c2.44 0 15.11 6.326 15.11 6.326z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#00B4DE"
        fillRule="evenodd"
        d="M57.05 32.103V27.67S34.026 13.066 28.553 13.066C23.022 13.006 0 27.669 0 27.669v4.434c0 1.36.06 2.72.178 4.02 7.496-6.857
         17.43-10.996 28.376-10.996 10.946 0 20.88 4.198 28.376 10.997.06-1.301.12-2.66.12-4.02z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#00B4DE"
        fillRule="evenodd"
        d="M28.495 30.802c-10.827 0-20.524 4.73-27.186 12.298 1.13 4.257 2.855 7.863 4.937 10.937 4.462-7.626 12.79-12.77 22.249-12.77 9.518
         0 17.787 5.144 22.248 12.83 2.082-3.075 3.808-6.74 4.938-10.938-6.603-7.568-16.36-12.357-27.186-12.357z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#00B4DE"
        fillRule="evenodd"
        d="M28.495 46.943c-8.21 0-15.23 4.966-18.263 12.002 2.915 3.015 6.068 5.32 8.923 7.035.357-4.848 4.402-8.632 9.34-8.632 4.937 0 8.983
         3.784 9.34 8.632 2.855-1.714 6.008-4.02 8.923-7.035-2.975-7.036-10.054-12.002-18.263-12.002z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#00B4DE"
        fillRule="evenodd"
        d="M28.792 63.143h-.238c-2.022 0-3.628 1.596-3.628 3.606s1.606 3.606 3.628 3.606c2.023 0 3.63-1.596 3.63-3.606a3.587 3.587 0 00-3.392-3.606z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default Logo;
