import { ReactNode, Children, cloneElement, ReactElement, isValidElement } from "react";
import { useTransfer } from "../utils";

interface TransferSectionProps {
  children?: ReactNode;
  align: "left" | "right";
}
const TransferSection = (props: TransferSectionProps) => {
  const { children, align } = props;
  const {
    items: { selected, available },
    label,
  } = useTransfer();
  const items = align === "left" ? available : selected;
  const title =
    label === "Vessels" ? (align === "left" ? label : "Vessels in This Fleet") : align === "left" ? label : "Fleets This Vessel Belongs to";

  return (
    <div className={`${align}-col`}>
      <label className="transfer-label">{title}</label>
      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          return cloneElement(child as ReactElement, { items, align });
        } else {
          return child;
        }
      })}
    </div>
  );
};

export default TransferSection;
