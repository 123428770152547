import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TimelineAmchartObject } from "types/EventTimeline.types";

type State = {
  dashboard: (Pick<TimelineAmchartObject, "from_dttm" | "to_dttm" | "type"> & { activityCount: number }) | null;
  vesselPath: Pick<TimelineAmchartObject, "from_dttm" | "to_dttm"> | null;
};

const initialState: State = {
  dashboard: null,
  vesselPath: null,
};

// Need to store state globally because when user unselects vessel when
// Drawer is open, we need to close the drawer.
const sidebarSlice = createSlice({
  name: "sidepanel",
  initialState,
  reducers: {
    setDashboardPanel(state, action: PayloadAction<State["dashboard"]>) {
      if (state.dashboard === action.payload) return;
      state.dashboard = action.payload;
    },
    closeSidepanels(state) {
      if (!state.dashboard) return;
      state.dashboard = initialState.dashboard;
    },
    setHighlightVesselPath(state, action: PayloadAction<State["vesselPath"]>) {
      if (state.vesselPath === action.payload) return;
      state.vesselPath = action.payload;
    },
    clearHighlightVesselPath(state) {
      if (!state.vesselPath) return;
      state.vesselPath = initialState.vesselPath;
    },
  },
});

export const { setDashboardPanel, closeSidepanels, setHighlightVesselPath, clearHighlightVesselPath } = sidebarSlice.actions;
export default sidebarSlice.reducer;
