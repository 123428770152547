import { rem } from "config/variable.styles";
import styled from "styled-components";
import { colors } from "ui";

const FilterButtonRoot = styled.button`
  all: unset;
  cursor: pointer;
  font-size: ${rem(14)};
  line-height: ${rem(20)};
  display: flex;
  border-radius: ${rem(8)};
  border: none;
  display: flex;
  align-items: center;
  gap: ${rem(8)};
  padding: ${rem(4)} ${rem(12)};
  transition: border 0.2s ease-in-out;
  color: black;

  :hover {
    outline: 1px solid ${colors.primary[300]};
  }

  &.hasCount {
    gap: ${rem(4)};
    .filter-button-content {
      margin-left: ${rem(4)};
    }
  }

  &.active {
    background-color: ${colors.primary[50]};
    font-weight: 600;
    color: ${colors.primary[500]};
    outline: none;
  }

  :disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .count {
    color: ${colors.primary[500]};
    font-weight: 600;
    letter-spacing: 0.4px;
  }
`;

export { FilterButtonRoot };
