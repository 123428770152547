/* eslint-disable max-lines */
import { IconButton, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import styled from "styled-components";
import { rem, color, rgba } from "config/variable.styles";

export const ModalBase = styled.div`
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  &.hide-unhide-activities {
    .modal-wrap {
      background-color: #d9d9d9;
      .MuiFormControl-root {
        .MuiInputBase-root {
          &:before {
            display: none;
          }
          &:after {
            display: none;
          }
        }
      }
    }
  }
  .modal-wrap {
    position: relative;
    margin: auto;
    width: 100%;
    min-height: 719px;
    display: flex;
    flex-direction: column;
    max-width: ${rem(1210)};
    box-shadow: 0 0 ${rem(30)} ${rem(10)} ${rgba(color.Black, 0.26)};
    background-color: ${color.darkergraybg};
    padding: ${rem(35)} ${rem(40)};
    .modal-header {
      margin-bottom: ${rem(30)};
      h3 {
        margin: 0 0 ${rem(18)};
        font-size: ${rem(22)};
        font-weight: 600;
        text-transform: capitalize;
        color: ${color.DarkBlack};
      }
      p {
        margin: 0 0;
        font-size: ${rem(16)};
        color: ${color.DarkBlack};
      }
    }
    .search-box {
      margin: 0 0 ${rem(17)};
      .MuiFormControl-root {
        width: 100%;
        display: block;
        position: relative;
        &.not-valid {
          .MuiInputBase-root {
            .MuiInputBase-input {
              border-color: ${color.coral};
            }
          }
        }
        .MuiFormLabel-root {
          width: 100%;
          font-size: ${rem(11)};
          font-weight: 700;
          color: ${color.DarkBlack};
          text-transform: uppercase;
          transform: translate(0, 0) scale(1);
          position: relative;
          margin: 0 0 ${rem(5)};
        }
        .MuiInputBase-root {
          width: 100%;
          display: block;
          margin: 0;
          &:after,
          &:before {
            display: none;
          }
          &.Mui-error {
            .MuiInputBase-input {
              border-color: ${color.coral};
            }
          }
          .MuiInputBase-input {
            background-color: ${color.White};
            height: ${rem(40)};
            box-sizing: border-box;
            padding: ${rem(6)} ${rem(20)};
            border: ${rem(1)} solid ${color.White};
            font-size: ${rem(14)};
            color: ${color.DarkBlack};
            line-height: ${rem(28)};
          }
        }
        .MuiFormHelperText-root {
          font-size: ${rem(11)};
          color: ${color.coral};
          line-height: ${rem(14)};
          margin: ${rem(5)} 0 0;
          position: absolute;
          top: 100%;
        }
      }
    }
    .modal-footer {
      padding: ${rem(20)} 0 0;
      display: flex;
      justify-content: flex-end;
      margin-top: auto;
      align-items: center;
      .foot-item {
        margin-left: ${rem(20)};
      }
      .MuiLink-root {
        font-size: ${rem(14)};
        line-height: ${rem(20)};
        color: ${color.TurquoiseBlueThree};
      }
    }
  }
  &.removeFlettModal {
    .modal-footer {
      .foot-item {
        margin-left: ${rem(20)};
        button.MuiButton-root {
          width: ${rem(204)};
        }
      }
    }
  }
  &.hide-unhide-activities {
    .modal-wrap {
      background-color: #d9d9d9;
    }
  }
  &.delete-tag-list {
    .modal-wrap {
      background-color: ${color.White};
      .modal-header {
        margin-bottom: ${rem(30)};
        h3 {
          margin: 0 0 ${rem(25)};
          font-weight: 600;
          text-transform: capitalize;
        }
      }
      .modal-footer {
        .foot-item {
          margin-left: ${rem(20)};
        }
      }
    }
  }
  &.detectors-modal {
    .modal-wrap {
      background-color: ${color.paleGreyTwo};
      max-height: inherit !important;
      max-width: 500px;
      .modal-header {
        margin-bottom: ${rem(20)};
        h3 {
          margin: 0 0 ${rem(25)};
          font-weight: 600;
          text-transform: capitalize;
        }
        p {
          margin: ${rem(0)};
          font-weight: 500;
          font-size: ${rem(16)};
          color: ${color.DarkBlack};
        }
      }
      .MuiFormControl-root {
        .MuiTextField-root {
          margin: 0;
          margin-bottom: 15px;
          .MuiInputLabel-formControl {
            font-weight: 500;
            font-size: ${rem(22)};
            color: ${color.DarkBlack};
          }
          .MuiInput-root {
            margin-top: ${rem(35)};
            background: ${color.White};
            padding: ${rem(15)};
            height: ${rem(150)};
            align-items: flex-start;
            font-weight: 500;
            font-size: ${rem(16)};
            &:before,
            &:after {
              display: none;
            }
            .MuiInput-input {
              padding: ${rem(0)};
            }
          }
        }
      }
    }
  }
  &.detectors-vessel-modal {
    .modal-wrap {
      max-width: ${rem(700)};
    }
  }
`;

export const ModalTableBase = styled.div`
  .MuiTableContainer-root {
    overflow: visible;
    .MuiTable-root .MuiTableHead-root {
      tr th {
        background-color: transparent;
        font-size: ${rem(16)};
        color: ${color.DarkBlack};
        line-height: ${rem(20)};
        padding: 0 ${rem(18)} ${rem(15)};
        border: 0px;
      }
    }
    .MuiTableBody-root {
      .MuiTableRow-root {
        will-change: filter;
        &:hover,
        &.row-hover {
          filter: drop-shadow(0 0 ${rem(8)} ${rgba(color.Black, 0.25)});
          transform: scaleX(1.0072);
          position: relative;
          z-index: 1;
          .table-action {
            opacity: 1;
            pointer-events: inherit;
          }
          .MuiTableCell-root {
            border-right-color: ${color.White};
            position: relative;
            &.icon {
              span {
                display: flex;
              }
            }
            &.disable-control {
              background: ${color.White};
            }
            &::after {
              opacity: 1;
            }
          }
        }
        .MuiTableCell-root {
          background-color: ${color.White};
          font-size: ${rem(16)};
          color: ${color.DarkBlack};
          line-height: ${rem(20)};
          padding: 0;
          border-color: ${color.paleGreyTwo};
          border-right: 1px solid ${color.paleGreyTwo};
          &.icon {
            min-width: ${rem(81)};
            max-width: ${rem(81)};
            width: ${rem(81)};
            text-align: center;
            span {
              display: none;
              align-items: center;
              justify-content: center;
            }
          }
          .pointer {
            cursor: pointer;
          }
          .disabled {
            opacity: 0.4;
            pointer-events: none;
            cursor: default;
          }
          &.disable-control {
            background: ${color.WhiteFour};
          }
          &.autofilled {
            .custom-control {
              .MuiInputBase-root {
                color: ${color.warmGreyTwo};
              }
            }
          }
          &:last-child {
            border-right: 0px;
          }
          &:first-child {
            &::after {
              display: none;
            }
          }
          &::after {
            content: "";
            position: absolute;
            top: 0;
            left: ${rem(-2)};
            height: 100%;
            width: ${rem(4)};
            background-color: ${color.White};
            opacity: 0;
          }
        }
      }
    }
  }
  .custom-control .MuiInputBase-root {
    background: transparent;
    font-size: ${rem(14)};
    line-height: ${rem(28)};
    padding: ${rem(6)} ${rem(20)};
    color: ${color.DarkBlack};
    &:before,
    &:after {
      display: none;
    }
  }
  .custom-select {
    width: 100%;
    padding-right: ${rem(10)};
    &.w-268 {
      width: 268px;
    }
    &.multi-select .MuiSelect-root {
      white-space: normal;
      text-overflow: inherit;
    }
    &:before,
    &:after {
      display: none;
    }
    .MuiSelect-root {
      background: transparent;
      font-size: ${rem(14)};
      line-height: ${rem(28)};
      padding: ${rem(6)} ${rem(20)};
      color: ${color.DarkBlack};
    }
  }
  .table-foot-btn {
    margin-top: ${rem(10)};
    .MuiLink-root {
      font-size: ${rem(14)};
      line-height: ${rem(20)};
      color: ${color.TurquoiseBlueThree};
      &.disabled {
        text-decoration: none;
        color: ${color.BrownishGrey};
        cursor: default;
      }
    }
  }
`;

export const ModalClose = withStyles(
  {
    root: {
      position: "absolute",
      top: rem(16),
      right: rem(16),
      padding: 0,
      margin: 0,
      background: "transparent",
      color: color.Black,
    },
    label: {
      padding: "0",
    },
  },
  { name: "topbar-action-btn" }
)(IconButton);

export const BackBtn = withStyles(
  {
    root: {
      height: rem(50),
      minWidth: rem(112),
      borderRadius: rem(25),
      border: `solid ${rem(2)} ${color.CloudyBlue}`,
      background: color.White,
      color: color.DarkBlack,
      fontSize: rem(14),
      fontWeight: 500,
      boxShadow: "inherit",
      "&:hover": {
        boxShadow: "inherit",
        background: color.White,
      },
      "& .MuiCircularProgress-colorPrimary": {
        color: color.DarkBlack,
        marginLeft: rem(10),
      },
    },
    label: {
      textTransform: "initial",
    },
  },
  { name: "fleet-button" }
)(Button);
