import { useMemo } from "react";
import { Vessel } from "types/Fleet.types";
import useFleets from "./useFleets";

/**
 * Returns a list of all the vessels associated to user.
 * Accespts an optional argument vessel id. When passed,
 * it will return particular vessel object instead of list.
 */
function useVessels(): { data: Vessel[]; loading: boolean };
function useVessels(vesselId: string): { data: Vessel | undefined; loading: boolean };
function useVessels(vesselId: string | undefined): { data: Vessel[] | Vessel | undefined; loading: boolean };
function useVessels(vesselId?: string | undefined) {
  const { data: vessels, loading } = useFleets((data) => {
    const vessels: Vessel[] = [];
    data?.fleets.forEach((fleet) =>
      fleet.vessels.forEach((vessel) => {
        vessels.push(vessel);
      })
    );
    return vessels;
  });

  const data = useMemo(() => {
    if (!vesselId) return vessels;
    return vessels.find((e) => e.id === vesselId);
  }, [vessels, vesselId]);

  return { data, loading };
}

export default useVessels;
