import { useState } from "react";
import { IoClose } from "react-icons/io5";
import { endOfMonth, startOfMonth } from "date-fns";
import { Box, Popover, Select, MenuItem, MenuProps, BoxProps } from "@material-ui/core";

import { months, parseDateString, years } from "./utils";
import { MonthSelectProps } from "./MonthSelect.types";
import { MonthSelectBase, ErrorText, CloseIcon } from "./MonthSelect.styles";
import { rem } from "config/variable.styles";
import Button from "components/Button";

const menuProps: Partial<MenuProps> = {
  PaperProps: {
    style: { maxHeight: rem(250), overflowY: "auto", fontSize: rem(14) },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  MenuListProps: {
    style: { fontSize: rem(14) },
    className: "select-menu",
  },
  getContentAnchorEl: null,
};

const boxProps: BoxProps = {
  display: "flex",
  gridGap: 10,
  alignItems: "center",
  justifyContent: "flex-end",
};

const MonthSelectForm = (props: Omit<MonthSelectProps, "label">) => {
  const { value, onSave } = props;
  const { start, end } = value;
  const parsedStartDate = parseDateString(start);
  const parsedEndDate = parseDateString(end);
  const [startDate, setStartDate] = useState(parsedStartDate);
  const [endDate, setEndDate] = useState(parsedEndDate);
  const disabled =
    startDate.month === parsedStartDate.month &&
    startDate.year === parsedStartDate.year &&
    endDate.month === parsedEndDate.month &&
    endDate.year === parsedEndDate.year;

  const disableEndDate = startDate.year > endDate.year || (startDate.year === endDate.year && startDate.month > endDate.month);

  const onApplyClick = () => {
    const start = startOfMonth(new Date(startDate.year, startDate.month));
    const end = endOfMonth(new Date(endDate.year, endDate.month));
    end.setHours(23, 59, 59);

    onSave({
      start: start.toString(),
      end: end.toString(),
    });
  };

  return (
    <>
      <Box mb={rem(disableEndDate ? 36 : 20)} mt={1} position="relative">
        <Box {...boxProps}>
          <Box component="p" fontWeight={600}>
            From:
          </Box>
          <Select value={startDate.month} MenuProps={menuProps} onChange={(e) => setStartDate({ ...startDate, month: e.target.value as number })}>
            {months.map((month) => (
              <MenuItem key={month.value} value={month.value}>
                {month.name}
              </MenuItem>
            ))}
          </Select>
          <Select value={startDate.year} MenuProps={menuProps} onChange={(e) => setStartDate({ ...startDate, year: e.target.value as number })}>
            {years.map((year) => (
              <MenuItem key={year.value} value={year.value}>
                {year.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box {...boxProps}>
          <Box component="p" fontWeight={600}>
            To:
          </Box>
          <Select value={endDate.month} MenuProps={menuProps} onChange={(e) => setEndDate({ ...endDate, month: e.target.value as number })}>
            {months.map(({ value, name }) => (
              <MenuItem key={value} value={value} disabled={value < startDate.month && endDate.year <= startDate.year}>
                {name}
              </MenuItem>
            ))}
          </Select>
          <Select value={endDate.year} MenuProps={menuProps} onChange={(e) => setEndDate({ ...endDate, year: e.target.value as number })}>
            {years.map(({ value, name }) => (
              <MenuItem key={value} value={value} disabled={value < startDate.year}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        {!!disableEndDate && (
          <ErrorText>
            Start date should not exceed <br /> the End date
          </ErrorText>
        )}
      </Box>
      <Button size="small" onClick={onApplyClick} disabled={disabled || disableEndDate}>
        Apply
      </Button>
    </>
  );
};

const MonthSelect = (props: MonthSelectProps) => {
  const { label, value, onSave } = props;
  const [width, setWidth] = useState(0);
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorElement);

  // Applying width of label element to popover
  // So that it looks aligned with the label
  // Not wrapping this in useCallback, because if we do that,
  // width state will be set only once and will not update the same
  // when the label width changes
  const ref = (node: HTMLDivElement | null) => {
    if (node !== null) {
      setWidth(node.clientWidth);
    }
  };

  const onApply = (arg: typeof value) => {
    onSave(arg);
    setAnchorElement(null);
  };

  return (
    <>
      <div role="button" ref={ref} onClick={(e) => setAnchorElement(e.currentTarget)}>
        {label}
      </div>
      <Popover
        open={open}
        anchorEl={anchorElement}
        onClose={() => setAnchorElement(null)}
        PaperProps={{ style: { borderRadius: 10, minWidth: width } }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MonthSelectBase>
          <CloseIcon role="button" onClick={() => setAnchorElement(null)}>
            <IoClose />
          </CloseIcon>
          <MonthSelectForm onSave={onApply} value={value} />
        </MonthSelectBase>
      </Popover>
    </>
  );
};

export { MonthSelect };
