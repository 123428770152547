const Edit = () => (
  <svg width={18} height={17} viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_698_3711)">
      <path
        d="M5 12.24L0.5 13.5L1.76 9.00002L10 0.80002C10.0931 0.704774 10.2044 0.629095 
        10.3271 0.577428C10.4499 0.52576 10.5818 0.499146 10.715 0.499146C10.8482 0.499146 
        10.9801 0.52576 11.1029 0.577428C11.2256 0.629095 11.3369 0.704774 11.43 0.80002L13.2 
        2.58002C13.2937 2.67298 13.3681 2.78359 13.4189 2.90544C13.4697 3.0273 13.4958 3.15801 
        13.4958 3.29002C13.4958 3.42203 13.4697 3.55274 13.4189 3.6746C13.3681 3.79646 13.2937 
        3.90706 13.2 4.00002L5 12.24Z"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <path d="M1 16H17" stroke="black" strokeLinecap="round" />
    <defs>
      <clipPath id="clip0_698_3711">
        <rect width={14} height={14} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export { Edit };
