const ReviewedFilterIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.7221 7.04217C14.9009 7.26521 15 7.55499 15 7.85545C15 8.15592 14.9009 8.4457 14.7221 
      8.66874C13.5891 10.0404 11.0103 12.7109 8.00001 12.7109C4.98966 12.7109 2.41091 10.0404 
      1.27799 8.66874C1.09905 8.4457 1 8.15592 1 7.85545C1 7.55499 1.09905 7.26521 1.27799 
      7.04217C2.41091 5.6705 4.98966 3 8.00001 3C11.0103 3 13.5891 5.6705 14.7221 7.04217Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.00024 10.0132C9.19204 10.0132 10.1582 9.04702 10.1582 7.85522C10.1582 
      6.66341 9.19204 5.69727 8.00024 5.69727C6.80843 5.69727 5.84229 6.66341 5.84229 
      7.85522C5.84229 9.04702 6.80843 10.0132 8.00024 10.0132Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const UnreviewedFilterIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_910_1134)">
      <path
        d="M4.36975 4.3647C5.43885 3.66998 6.67766 3.15381 7.99987 3.15381C11.0045 3.15381 
        13.5784 5.81923 14.7091 7.18829C14.8877 7.41091 14.9866 7.70014 14.9866 
        8.00003C14.9866 8.29992 14.8877 8.58915 14.7091 8.81177C14.0858 9.56642 
        13.024 10.715 11.6933 11.5939M9.61526 12.5981C9.09577 12.7552 8.55518 12.8462 
        7.99987 12.8462C4.99526 12.8462 2.42141 10.1808 1.29064 8.81177C1.11204 8.58915 
        1.01318 8.29992 1.01318 8.00003C1.01318 7.70014 1.11204 7.41091 1.29064 
        7.18829C1.6489 6.75452 2.15202 6.19063 2.7678 5.62262"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.52304 9.52303C10.3642 8.6819 10.3642 7.31816 9.52304 6.47703C8.68191 
        5.63591 7.31818 5.63591 6.47705 6.47703"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M15 15L1 1" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_910_1134">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const StatusFilterIcon = () => {
  return (
    <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.26961 1H5.03884C4.44407 1 3.96191 1.48215 3.96191 2.07692V2.61538C3.96191 
        3.21015 4.44407 3.69231 5.03884 3.69231H8.26961C8.86437 3.69231 9.34653 3.21015 
        9.34653 2.61538V2.07692C9.34653 1.48215 8.86437 1 8.26961 1Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.61539 2.0769H11.2308C11.5164 2.0769 11.7903 2.19037 
        11.9923 2.39232C12.1942 2.59429 12.3077 2.86821 12.3077 
        3.15383V13.9231C12.3077 14.2087 12.1942 14.4826 11.9923 
        14.6845C11.7903 14.8865 11.5164 15 11.2308 15H2.07692C1.7913 
        15 1.51739 14.8865 1.31542 14.6845C1.11346 14.4826 1 14.2087 
        1 13.9231V3.15383C1 2.86821 1.11346 2.59429 1.31542 2.39232C1.51739 
        2.19037 1.7913 2.0769 2.07692 2.0769H3.69231"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { StatusFilterIcon, ReviewedFilterIcon, UnreviewedFilterIcon };
