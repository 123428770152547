import styled from "styled-components";
import { color, rem, rgba } from "config/variable.styles";

interface DateRangeRootProps {
  backgroundColor: string;
}
export const DateRangeRoot = styled.div<DateRangeRootProps>`
  position: relative;
  .date-picker-popup {
    position: absolute;
    top: ${rem(20)};
    left: 0;
    z-index: 9999;
    .MuiListItem-gutters {
      padding-right: ${rem(36)};
    }
    h6 {
      white-space: nowrap;
    }
    .MuiPaper-root {
      background-color: ${(props) => props.backgroundColor};
      max-height: calc(100% - ${rem(600)}) !important;
      @media (max-width: 1400px) {
        max-height: calc(100% - ${rem(540)}) !important;
      }
      & > div > div:last-child {
        flex-grow: 1;
      }
      &.MuiPaper-rounded {
        top: ${rem(227)} !important;
      }
    }
    .MuiPaper-elevation5 {
      position: relative;
      box-shadow: inherit;
      box-shadow: 0 2px ${rem(7)} ${rem(2)} ${rgba(color.Black, 0.26)};
      &::after {
        content: "";
        position: absolute;
        width: 15px;
        height: 15px;
        top: -8px;
        left: 34px;
        background: ${(props) => props.backgroundColor};
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        z-index: 0;
      }
      .materialui-daterange-picker-makeStyles-header-4 {
        .MuiGrid-item {
          &:nth-child(2) {
            flex: 0 0 35px;
          }
        }
      }
      .materialui-daterange-picker-MuiGrid-root-7 {
        .MuiList-root {
          .MuiListItem-button {
            white-space: nowrap;
          }
        }
      }
      .MuiList-root {
        .MuiListItem-button {
          white-space: nowrap;
        }
      }
    }
    .MuiGrid-root {
      [class*="materialui-daterange-picker-makeStyles-header-"] {
        padding: 20px 20px;
      }
    }

    .MuiList-root {
      .MuiListItem-button {
        white-space: nowrap;
      }
    }
  }
`;

export const RangeControl = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  .calendarBlock {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .calendar-label {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .icon {
      margin-right: ${rem(5)};
      display: block;
      height: ${rem(16)};
    }
  }
`;
