import { CSSProperties, ReactNode, useEffect, useRef, useState } from "react";
import { DateRangePicker as RangePicker, DateRange, DefinedRange } from "materialui-daterange-picker";

import { DateRangeRoot, RangeControl } from "./DateRangePicker.styles";

interface DateRangePickerProps {
  value: DateRange;
  onDateChange: (range: DateRange) => boolean | void;
  label: ReactNode;
  ranges?: DefinedRange[];
  backgroundColor?: CSSProperties["color"];
  class?: string;
}
const DateRangePicker = (props: DateRangePickerProps) => {
  const { value, onDateChange, label, ranges, backgroundColor = "#f1f1f1", class: className } = props;
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  // Detects if user has clicked outside the container
  // To close the date range picker when user clicks outside.
  // Listener is attached to document only when calendar is opened.
  // closeOnClickOutside prop sometimes doesn't close on outside clicks.
  useEffect(() => {
    if (!open) return;

    function listener(this: Document, e: MouseEvent) {
      if (!ref.current || ref.current.contains(e.target as Node)) {
        return;
      }
      setOpen(false);
    }

    document.addEventListener("mousedown", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, [open]);

  const onChange = (range: DateRange) => {
    if (!range.startDate || !range.endDate) return;
    const close = onDateChange?.(range);
    if (!close) {
      setOpen(false);
    }
  };

  // DateRangePicker doesn't support controlled way of handling it's state.
  // So, using key prop to make it behave like controlled. key will re-mount
  // The component and it will receive updated values in initialDateRange prop.
  // Otherwise, it would be not be possible to jump to current date on clicking Live button.
  return (
    <DateRangeRoot backgroundColor={backgroundColor} ref={ref} className={className}>
      <RangeControl>
        <div className="calendarBlock">
          <div className="calendar-label" onClick={() => setOpen(!open)}>
            {label}
          </div>
        </div>
      </RangeControl>
      <RangePicker
        key={value.startDate?.toISOString()}
        open={open}
        onChange={onChange}
        closeOnClickOutside
        maxDate={new Date()}
        definedRanges={ranges}
        toggle={() => setOpen(!open)}
        wrapperClassName={"date-picker-popup " + className ?? ""}
        initialDateRange={value}
      />
    </DateRangeRoot>
  );
};

export { DateRangePicker };
