import styled from "styled-components";
import { rem } from "config/variable.styles";

export const MonthSelectBase = styled.div`
  position: relative;
  padding: ${rem(20)};
  font-size: ${rem(13)};
  text-align: center;
  font-weight: 500;
  .MuiInputBase {
    &-root {
      font-size: ${rem(13)};
      font-weight: 500;
      min-width: ${rem(50)};
    }
    &-input {
      padding: 6px 0 4px;
    }
  }
  .MuiSvgIcon-root {
    transition: transform 0.3s ease;
  }
`;

export const CloseIcon = styled.span`
  position: absolute;
  right: ${rem(8)};
  top: ${rem(8)};
  cursor: pointer;
  color: #828282;
`;

export const ErrorText = styled.p`
  color: red;
  font-size: ${rem(10)};
  margin-top: ${rem(5)};
  position: absolute;
  left: 50%;
  bottom: ${rem(-28)};
  transform: translateX(-50%);
  min-width: 100%;
`;
