/**
 * This file contains the translations for the enums in the activity.proto file.
 * Translates from GRPC enums to REST API and UI compatible strings
 */
import { OperationType, Severity } from "@shipin/proto-activity-client/activity";
import { color } from "config/variable.styles";
import { Severity as RESTSeverity, Activity } from "types/ReduxState/TableFilterBar.types";

const severity: Record<Severity, RESTSeverity> = {
  [Severity.ROUTINE]: "Routine",
  [Severity.ATTENTION]: "Attention",
  [Severity.ALERT]: "Alert",
  [Severity.UNSPECIFIED]: "Routine",
};

const activityType: Record<OperationType, Activity> = {
  [OperationType.CARGO]: "CARGO",
  [OperationType.SAFETY]: "SAFETY",
  [OperationType.MAINTENANCE]: "MAINTENANCE",
  [OperationType.BRIDGE]: "BRIDGE",
  [OperationType.BUNKERING]: "BUNKERING",
  [OperationType.UNSPECIFIED]: "CARGO",
};

const severityColors: Record<RESTSeverity, string> = {
  Routine: color.aquaMarine,
  Attention: color.paleOrange,
  Alert: color.coral,
};

export { severity, activityType, severityColors };
