import { color, rem } from "config/variable.styles";
import styled from "styled-components";

export const PlaceholderStyle = styled.div`
  display: flex;
  flex-direction: column;
  min-height: ${rem(300)};
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: ${rem(16)};
  font-weight: 900;
  color: ${color.DarkBlack};
  .icon {
    font-size: ${rem(100)};
    display: flex;
  }
`;
