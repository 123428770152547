const SendMessage = () => (
  <svg width="1em" height="1em" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.08974 7.94491L0.904541 5.58379L13.1605 0.861572L8.44665 13.1393L6.08974 7.94491Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M6.08984 7.94491L8.91814 5.11157" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export { SendMessage };
