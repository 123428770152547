import { addDays, differenceInDays, differenceInHours } from "date-fns";
export { default as convertDataForChart } from "./convertDataForChart";

export const scrollbarTooltipContent = (endDate: string | number, startDate: string | number) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  let diff = differenceInHours(end, start);
  const unit = diff > 24 ? "Day" : "Hour";
  if (unit === "Day") {
    diff = differenceInDays(addDays(end, 1), start);
  }
  return `Showing ${diff} ${diff === 1 ? unit : unit + "s"}`;
};

const timelineResolutions = [
  { min: 1, max: 1, res: { minutes: 15, hours: 0 } },
  { min: 2, max: 2, res: { minutes: 30, hours: 0 } },
  { min: 3, max: 3, res: { minutes: 45, hours: 0 } },
  { min: 4, max: 4, res: { minutes: 0, hours: 1 } },
  { min: 5, max: 8, res: { minutes: 0, hours: 2 } },
  { min: 9, max: 12, res: { minutes: 0, hours: 3 } },
  { min: 13, max: 20, res: { minutes: 0, hours: 4 } },
  { min: 21, max: 24, res: { minutes: 0, hours: 6 } },
  { min: 25, max: 36, res: { minutes: 0, hours: 8 } },
  { min: 37, max: 64, res: { minutes: 0, hours: 12 } },
  { min: 65, max: 120, res: { minutes: 0, hours: 24 } },
  { min: 121, max: 180, res: { minutes: 0, hours: 48 } },
  { min: 181, max: Infinity, res: { minutes: 0, hours: 120 } },
];

export const getOptions = (differenceInHours: number) => {
  const roundedDifferenceInHours = Math.round(differenceInHours / 12) * 12;
  const roundedDifferenceInDays = Math.round(roundedDifferenceInHours / 24);

  if (differenceInHours >= 0 && differenceInHours <= 9) {
    return {
      resolution: { minutes: 5, hours: 0 },
      duration: roundedDifferenceInDays * 2.5,
    };
  }
  if (differenceInHours >= 10 && differenceInHours <= 17) {
    return {
      resolution: { minutes: 10, hours: 0 },
      duration: roundedDifferenceInDays * 2.5,
    };
  }
  if (roundedDifferenceInHours >= 18) {
    const timelineResolution = timelineResolutions.find(({ min, max }) => roundedDifferenceInDays >= min && roundedDifferenceInDays <= max);

    return {
      resolution: timelineResolution?.res ?? { minutes: 5, hours: 0 },
      duration: roundedDifferenceInDays * 2.5,
    };
  }

  return {
    resolution: { minutes: 5, hours: 0 },
    duration: roundedDifferenceInDays * 2.5,
  };
};
