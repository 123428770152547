/* eslint-disable max-lines */
import { createGlobalStyle } from "styled-components";
import { color, rem, rgba, Transition } from "./config/variable.styles";
import { fonts } from "./config/fonts";
import { colors } from "ui";

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Inter';
    src: url(${fonts.interBlackEOT});
    src: url('${fonts.interBlackEOT}?#iefix') format('embedded-opentype'),
        url(${fonts.interBlackWOFF2}) format('woff2'),
        url(${fonts.interBlackWOFF}) format('woff'),
        url(${fonts.interBlackTTF}) format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Inter';
    src: url(${fonts.interExtraBoldEOT});
    src: url('${fonts.interExtraBoldEOT}?#iefix') format('embedded-opentype'),
        url(${fonts.interExtraBoldWOFF2}) format('woff2'),
        url(${fonts.interExtraBoldWOFF}) format('woff'),
        url(${fonts.interExtraBoldTTF}) format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Inter';
    src: url(${fonts.interBoldEOT});
    src: url('${fonts.interBoldEOT}?#iefix') format('embedded-opentype'),
        url(${fonts.interBoldWOFF2}) format('woff2'),
        url(${fonts.interBoldWOFF}) format('woff'),
        url(${fonts.interBoldTTF}) format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Inter';
    src: url(${fonts.interExtraLightEOT});
    src: url('${fonts.interExtraLightEOT}?#iefix') format('embedded-opentype'),
        url(${fonts.interExtraLightWOFF2}) format('woff2'),
        url(${fonts.interExtraLightWOFF}) format('woff'),
        url(${fonts.interExtraLightTTF}) format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Inter';
    src: url(${fonts.interLightEOT});
    src: url('${fonts.interLightEOT}?#iefix') format('embedded-opentype'),
        url(${fonts.interLightWOFF2}) format('woff2'),
        url(${fonts.interLightWOFF}) format('woff'),
        url(${fonts.interLightTTF}) format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Inter';
    src: url(${fonts.interMediumEOT});
    src: url('${fonts.interMediumEOT}?#iefix') format('embedded-opentype'),
        url(${fonts.interMediumWOFF2}) format('woff2'),
        url(${fonts.interMediumWOFF}) format('woff'),
        url(${fonts.interMediumTTF}) format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Inter';
    src: url(${fonts.interSemiBoldEOT});
    src: url('${fonts.interSemiBoldEOT}?#iefix') format('embedded-opentype'),
        url(${fonts.interSemiBoldWOFF2}) format('woff2'),
        url(${fonts.interSemiBoldWOFF}) format('woff'),
        url(${fonts.interSemiBoldTTF}) format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Inter';
    src: url(${fonts.interRegularEOT});
    src: url('${fonts.interRegularEOT}?#iefix') format('embedded-opentype'),
        url(${fonts.interRegularWOFF2}) format('woff2'),
        url(${fonts.interRegularWOFF}) format('woff'),
        url(${fonts.interRegularTTF}) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Inter';
    src: url(${fonts.interThinEOT});
    src: url('${fonts.interThinEOT}?#iefix') format('embedded-opentype'),
        url(${fonts.interThinWOFF2}) format('woff2'),
        url(${fonts.interThinWOFF}) format('woff'),
        url(${fonts.interThinTTF}) format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
  }
  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }
  body {
    margin: 0;
    color: ${colors.gray[950]};
    background-color: ${colors.gray[50]};
    font-family: 'Inter';
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } 
  .overFlowYHidden {
    overflow-y: hidden !important;
  }
  .overFlowHidden{
    overflow: inherit !important;
  }
  .bodyOverFlowHidden{
    overflow: inherit !important;
  }
  .overflow-hidden {
    overflow: hidden !important;
  }
  #root {
    position: relative;
    min-height: 100vh;
  }
  .overflowXHide {
    overflow-x: hidden;
    min-height: 100vh;
  }
  .page-loader {
    position: fixed;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${color.White};
  }
  ul.MuiList-padding {
    padding-top: 0;
    padding-bottom: 0;
  }
  .custom-menu {
    .MuiMenu-paper {
      .MuiList-root {
        padding: 0;
      }
    }
  }
  .custom-dropdown {
    &.submenu-dropdown {
      .MuiPaper-root {
        top: ${rem(40)} !important;
      }
    }
    &.notification-action-dropdown {
      .MuiMenu-paper {
        padding-top: ${rem(40)};
      }
      .MuiList-root {
        &::after {
          right:${rem(10)};
        }
      }
    }
    .MuiMenu-paper {
      padding-top: ${rem(17)};
      box-shadow: inherit;
      max-height: ${rem(360)} ;
      overflow-y: inherit;
      overflow-x: inherit;
      background-color: transparent;
      -webkit-appearance: none !important;
      -moz-filter: drop-shadow(0 ${rem(2)} ${rem(4)} ${rgba(color.Black, 0.26)});
      -webkit-filter: drop-shadow(0 ${rem(2)} ${rem(4)} ${rgba(color.Black, 0.26)});
      -ms-filter: drop-shadow(0 ${rem(2)} ${rem(4)} ${rgba(color.Black, 0.26)});
      -o-filter: drop-shadow(0 ${rem(2)} ${rem(4)} ${rgba(color.Black, 0.26)});
      filter: drop-shadow(0 ${rem(2)} ${rem(4)} ${rgba(color.Black, 0.26)});
      margin-top: 0;
      min-width: auto !important;
    }
    &.scrollbar-menu {
      .MuiMenu-paper {
        height: ${rem(190)} ;
        overflow-y: auto;
        overflow-x: hidden;
      }
    }
    .list-auto-scroll {
      max-height: ${rem(206)} ;
      overflow: auto;
    }
    .list-auto-scroll-lable {
      &:focus {
        outline: none;
        box-shadow: inherit;
      }
    }
    .clear-Selected-Items {
      padding:0 ${rem(16)} ;
      margin: 0;
      font-size: ${rem(14)} ;
      font-weight: normal;
      font-style: normal;
      line-height: 2;
      display: block;
      color:${color.TurquoiseBlueThree};
      cursor: pointer;
    }
    &.vessel-area-filter-menu {
      .MuiMenu-paper {
        height: ${rem(140)} ;
        overflow-y: auto;
        overflow-x: hidden;
      }
    }
    .MuiList-root {
      padding: ${rem(10)} 0;
      background-color: ${color.grayWhite};
      &::after {
        content: "";
        position: absolute;
        width: ${rem(15)};
        height: ${rem(15)};
        top: ${rem(-8)};
        right: ${rem(20)};
        background-color: ${color.grayWhite};
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
      .title {
        font-size: ${rem(14)};
        font-weight: 700;
        margin: 0;
        padding: ${rem(8)} ${rem(20)};
        text-transform: uppercase;
      }
      .MuiDivider-root {
        margin: ${rem(10)} 0;
        background-color: ${color.graywhiteTwo};
      }
      .MuiButtonBase-root {
        font-size: ${rem(14)};
        font-weight: 400;
        line-height: 1;
        padding: ${rem(8)} ${rem(20)};
        color: ${color.DarkBlack};
        &.MuiSwitch-switchBase {
          color: white;
          padding: ${rem(9)};
          & + .MuiSwitch-track {
            background-color: #BDC1C6;
            opacity: 1;
          }
          &.Mui-checked {
            & + .MuiSwitch-track {
              background-color: ${color.navyBlue};
              opacity: 0.38;
            }
            .MuiSwitch-thumb {
              color: ${color.navyBlue};
            }
          }
          &:hover {
            background-color: transparent;
          }
        }
        &:hover, &.active {
          background-color: ${color.graywhiteTwo};
        }
        &.Mui-disabled {
          color: ${color.pinkishGrey};
          opacity: 1;
        }
        .MuiListItemIcon-root {
          min-width: auto;
          padding-right: ${rem(6)};
          font-size: ${rem(18)};
          .icon  {
            display: flex;
          }
        }
        .MuiListItemText-root {
          margin: 0;
        }
        .MuiTypography-root {
          font-size: ${rem(14)};
          font-weight: 400;
          line-height: 1;
        }
        &.width-checkbox {
          padding: 0;
          &:hover {
            .MuiFormControlLabel-root {
              .MuiButtonBase-root {
                background-color: ${color.cloudyBlueTwo};
              }
            }
          }
          .MuiFormControlLabel-root {
            margin: 0;
            display: flex;
            width: 100%;
            padding: ${rem(3)} ${rem(16)};
            .MuiFormControlLabel-label {
              width: 100%;
            }
            .MuiButtonBase-root {
              padding: 0 0 ${rem(1)};
              transition: ${Transition};
              border-radius: ${rem(4)};
              height: ${rem(24)};
              width: ${rem(24)};
              color: ${color.darkBlue};
              .MuiSvgIcon-root {
                height: ${rem(19)};
                width: ${rem(19)};
              }
            }
            .MuiTypography-root {
              display: flex;
              align-items: center;
              gap: ${rem(10)};
              padding-left: ${rem(6)};
            }
          }
        }
      }
    }

    &.kpi-config-menu {
      .MuiMenu-paper {
        padding-top: ${rem(5)};
        margin-left: ${rem(10)};
      }
    }

    &.bottom-align-left {
      .MuiMenu-paper {
        padding: 0 0 ${rem(17)};
      }
      .MuiList-root {
          &::after {
          bottom: ${rem(-16)};
          left: 0;
          border-top: ${rem(16)} solid ${color.grayWhite};
          border-right: ${rem(18)} solid transparent;
        }
      }
    }
    &.custom-action-dropdown {
      .MuiList-root {
        &::after {
          right:${rem(10)};
        }
      }
    }
    &.popupFleetsDropdown {
      .MuiList-root {
        &::after {
          right:${rem(13)};
        }
      }
    }
    &.mapDefaultDropdown {
      .MuiMenu-paper {
        padding-top: ${rem(0)};
        padding-bottom: ${rem(12)};
      }
      .MuiList-root {
        &::after {
          top: inherit;
          bottom:${rem(-8)};
          right: ${rem(10)};
        }
      }
    }
    &.tagDropdown {
      .MuiMenu-paper {
        margin-left:${rem(23)};
      }
    }
    &.bottom-arrow {
      .MuiList-root {
        bottom: 30px;
        &::after {
          top: 142px;
        }
      }
    }
    &.timeline-settings-dropdown {
      .MuiMenu-paper {
        padding-top:0 ;
        padding-bottom:${rem(5)};
        margin-left: ${rem(-5)};
        .MuiMenu-list {
          padding-bottom:${rem(10)};
        }
      }
      .MuiMenu-list {
        padding: ${rem(0)};
        .timeline-settings-title {
          font-size: ${rem(14)};
          font-weight: 500;
          margin: 0;
          padding: ${rem(8)} ${rem(20)};
          border-bottom:1px solid #dfdfdf;
        }
      }
      .MuiList-root {
        &::after {
          top: inherit;
          bottom:${rem(-8)};
          left: ${rem(10)};
        }
      }
    }
    &.comment-action-dropdown {
      .MuiMenu-paper {
        margin-left: ${rem(10)};
      }
      .MuiList-root {
        &::after {
          right:${rem(10)};
        }
      }
    }
  }

  .Toastify {
    &__toast-container  {
      top: ${rem(80)};
      right: ${rem(80)};
      width: ${rem(520)};
    }
    &__toast {
      background-color: ${color.White};
      border-radius: 0;
      box-shadow: 0 0 ${rem(12)} 0 ${rgba(color.Black, 0.15)};
      padding: ${rem(21)} ${rem(22)};
      position: relative;
      align-items: center;
      display: flex;
      &--error{
        border-top: ${rem(4)} solid ${color.coral};
      }
      &--info {
        border-top: ${rem(4)} solid ${color.TurquoiseBlueThree};
      }
      &--success {
        border-top: ${rem(4)} solid ${color.aquaMarine};
      }
      &.alert-toast {
        border-top: ${rem(4)} solid ${color.coral};
      }
      &.attention-toast {
        border-top: ${rem(4)} solid ${color.paleOrange};
      }
      &.routine-toast {
        border-top: ${rem(4)} solid ${color.aquaMarine};
      }
      &-body {
        padding: 0;
        margin: 0;
        .toast-content {
          align-items: center;
          display: flex;
          &__icon {
            flex: 0 0 ${rem(40)};
            width:${rem(40)};
            max-width:${rem(40)};
            .icon  {
              font-size: ${rem(40)};
              display: flex;
            }
          }
          &__info {
            padding:0 0 0 ${rem(20)}; 
            h3 {
              font-size: ${rem(14)};
              font-weight: 900;
              line-height: ${rem(20)};
              margin: 0;
              text-transform: uppercase;
              color: ${color.DarkBlack};
            }
            p {
              font-size: ${rem(14)};
              line-height: ${rem(20)};
              margin: 0; 
              color: ${color.DarkBlack};
              strong {
                font-weight: 900;
              }
            }
          }
        }
      } 
    }
    .MuiAlert-action {
      position: absolute;
      top: ${rem(4)};
      right: ${rem(4)};
      padding: 0;
      margin: 0;
      color: ${color.Black};
      .MuiButtonBase-root {
        padding: 0;
        font-size: ${rem(16)};
        .MuiSvgIcon-root {
          font-size: ${rem(28)};
        }
      }
    }
  }
  .MuiTooltip-popper {
    .MuiTooltip-tooltip{
      margin: ${rem(2)} 0;
      background-color: ${color.White};
      font-size: ${rem(14)};
      font-weight: 400;
      color: ${color.DarkBlack};
      padding: ${rem(16)} ${rem(20)};
      border-radius: ${rem(4)};
      box-shadow: 0 ${rem(2)} ${rem(4)} 0 ${rgba(color.Black, 0.26)};
      .MuiTooltip-arrow {
        color: ${color.White};
      }
    }
  }
  .headingPopoverContent {
    ul {
        margin: ${rem(0)};
        padding:${rem(5)} ${rem(10)} ${rem(5)} ${rem(25)};
        
        li {
            list-style: none;
            padding:${rem(5)} ${rem(0)};
            font-size: ${rem(14)};
        }
    }
  }
  .whiteBgskeleton {
    background-color: ${rgba(color.White, 0.15)};
  }

  .colorCodeItemRow {
    display:flex;
    flex-wrap: wrap;
    width:${rem(120)};
    padding:${rem(10)} ${rem(15)} 0;
    .colorCodeItem {
      width:25%;
      max-width:25%;
      flex:0 0 25%;
      margin-bottom:${rem(10)};
      padding:0;
      &:hover {
        background-color: transparent;
      }
    }
  }

  .saveLoadPopover {
    .MuiPopover-paper {
      margin-left: ${rem(10)};
      margin-top: ${rem(10)};
    }
  }
  .filterTooltipTitle {
    white-space: pre
  }

  .MuiTable-root {
    .MuiTableHead-root {
      .MuiTableRow-root {
        .MuiTableCell-root {
          &:hover {
            background-color: #ebf4fb !important;
          }
          .MuiTableSortLabel-root {
            position: static;
            &:hover {
              color: ${color.DarkBlack};
              .icon-sorticon {
                opacity: 1;
                .icon {
                  color: ${color.warmGreyTwo};
                }
              }
            }
            .icon-sorticon {
              display: flex;
              opacity: 0;
              position: absolute;
              right: ${rem(6)};
              flex-direction: column;
              width: ${rem(16)};
              height: ${rem(16)};
              justify-content: center;
              align-items: center;
              opacity: 1;
              .icon {
                display: flex;
                font-size: ${rem(10)} !important;
                color: transparent;
              }
              .up-icon {
                display: inline-flex;
                transform: rotate(-180deg);
              }
              .down-icon {
                display: inline-flex;
              }
            }
          }
          &[aria-sort="ascending"] {
            background-color: #ebf4fb !important;
            .icon-sorticon {
              .up-icon {
                .icon {
                  color: ${color.DarkBlackThree} !important;
                }
              }
            }
          }
          &[aria-sort="descending"] {
            background-color: #ebf4fb !important;
            .icon-sorticon {
              .down-icon {
                .icon {
                  color: ${color.DarkBlackThree} !important;
                }
              }
            }
          }
        }
      }
    }
  }
  // for custom month picker used in performance analytics
  .select-menu {
    li {
      font-size: ${rem(13)};
      font-weight: 500;
    }
  }
  // for help icon menu in header
  .shipshape-menu {
    .MuiMenu-paper {
      padding-top: 10px;
    }
    .MuiList-root {
      .MuiButtonBase-root {
        padding-right: 8px;
      }
    }
  }

  .pr-0 {
    padding-right: 0 !important;
  }
`;
