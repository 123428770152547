import { useQuery } from "@tanstack/react-query";
import { ActivitiesFilter, Blacklist, GetAnalyticsActivityTimelineResponse } from "@shipin/proto-activity-client/activity";
import { activityClient } from "config";
import { protoFilterKey } from "hooks/useFilters";
import { EngineRoomReportsAmchartsObject } from "types/Reports.types";
import { convertFromTimestampToUTC } from "utils";
import { Stats } from "components/SummaryBar/SummaryBar";
import { severity, severityColors } from "constants/translations";
import { differenceInHours } from "date-fns";
import { getOptions } from "components/Timelines/utils";

const activitiesFilter: ActivitiesFilter = {
  edgeIds: [],
  blacklist: Blacklist.EXCLUDE,
  severities: [],
  operationTypes: [],
  titles: [],
  feedIds: [],
  cameraIds: [],
  sectionIds: [],
  placementIds: [],
  activityIds: [],
  compartmentNames: [],
  areaNames: [],
  vesselIds: [],
  vesselTypes: [],
  fleetIds: [],
  tagIds: [],
  navigationStatuses: [],
  distanceFromShores: [],
  vesselSpeeds: [],
  phaseOfDays: [],
  visibilities: [],
  beauforts: [],
  geoFencings: [],
  workflowStatuses: [],
};

function select(data: GetAnalyticsActivityTimelineResponse): Record<string, EngineRoomReportsAmchartsObject[]> {
  return data.titles.reduce<Record<string, EngineRoomReportsAmchartsObject[]>>((acc, item) => {
    acc[item.title] = item.entries.map((entry) => ({
      amc_fromDate: convertFromTimestampToUTC(entry.rangeStart!),
      amc_toDate: convertFromTimestampToUTC(entry.rangeEnd!),
      type: item.title,
      data: entry.countBySeverities.reduce<Stats>(
        (acc, item) => {
          return {
            ...acc,
            [severity[item.severity]]: Number(item.count),
          };
        },
        {
          Routine: 0,
          Attention: 0,
          Alert: 0,
        }
      ),
      color: severityColors[severity[entry.highestSeverity]],
    }));
    return acc;
  }, {});
}

function useReportsTimelineQuery(request: { filters: Partial<ActivitiesFilter>; range: { start: string; end: string } }) {
  // getActivityTimeline requires all filters to be passed explicitly.
  // Appending empty lists to the filters object, so that we don't need to
  // Pass them explicitly every time we call this hook.
  // Filters passed to this hook will override the default filters.
  const filter = {
    ...activitiesFilter,
    ...request.filters,
  };

  const difference = differenceInHours(new Date(request.range.end), new Date(request.range.start));
  const { resolution } = getOptions(difference);

  return useQuery({
    queryKey: protoFilterKey("reports-timeline", filter),
    queryFn: () =>
      activityClient.getAnalyticsActivityTimeline({
        filter,
        resolution: resolution.minutes || resolution.hours * 60,
      }).response,
    select,
    structuralSharing: false,
    staleTime: 0,
    gcTime: 0,
  });
}

export { useReportsTimelineQuery };
