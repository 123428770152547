import { useCallback, useEffect, useRef } from "react";

/**
 * Custom hook that creates an IntersectionObserver and returns a ref callback function.
 * The ref callback function can be attached to a DOM element to observe its intersection with the viewport.
 * When the observed element intersects with the viewport, the provided onIntersect callback function is called.
 * This hook is used mainly for infinite scrolling in the application.
 *
 * @param options - The options for the useObserver hook.
 * @param options.onIntersect - The callback function to be called when the observed element intersects with the viewport.
 * @returns - The ref callback function that should be attached to a DOM element.
 */
function useObserver(
  {
    onIntersect,
  }: {
    onIntersect: (entry?: IntersectionObserverEntry) => void;
  } = {
    onIntersect: () => {},
  }
) {
  const observer = useRef<IntersectionObserver>(
    new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          onIntersect(entry);
        }
      });
    })
  );

  const ref = useCallback((node: HTMLElement | null) => {
    observer.current.disconnect();
    if (node) {
      observer.current.observe(node);
    }
  }, []);

  useEffect(() => () => observer.current.disconnect(), []);

  return ref;
}

export { useObserver };
