import { Menu, MenuItem } from "@material-ui/core";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import DownArrow from "components/Icon/DownArrow";
import useMenu from "hooks/useMenu";
import { HeaderCrumb } from "./Header.types";
import { isEdge } from "utils";

//Header Submenu
const Submenu = (props: Omit<Required<HeaderCrumb>, "exact" | "visible" | "path">) => {
  const { label, submenu } = props;
  const match = useRouteMatch();
  const { pathname } = useLocation();
  const history = useHistory();
  const [menuProps, handleOpen] = useMenu({ closeOnSelect: true, className: "submenu-dropdown" });
  const active = submenu.some((sub) => sub.sublinkpath === pathname || (!!sub.nestedRouteBasePath ? sub.nestedRouteBasePath === match.path : false));

  return (
    <>
      <li className="nav__item">
        {!isEdge ? (
          <span className={`nav__item_link ${!!menuProps.open || active ? "nav__item_link_active" : ""}`} color="inherit" onClick={handleOpen}>
            {label}
            <span className="downArrow">
              <DownArrow />
            </span>
          </span>
        ) : null}
      </li>
      {!isEdge ? (
        <Menu {...menuProps} autoFocus={false}>
          {submenu.map((sub, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                if (sub.sublinkpath) history.push(sub.sublinkpath);
              }}
              selected={sub.sublinkpath === pathname || (!!sub.nestedRouteBasePath ? sub.nestedRouteBasePath === match.path : false)}
              disabled={sub.disabled}
            >
              {sub.sublabel}
            </MenuItem>
          ))}
        </Menu>
      ) : null}
    </>
  );
};

export default Submenu;
