import { NestedRouteConfig } from "components/NestedRoute";
import { lazyWithRetry } from "config/lazyrefresh";
import routes from "config/routes";

const FleetReport = lazyWithRetry(() => import("./FleetReport"));
const BridgeWatchReport = lazyWithRetry(() => import("./BridgeWatchReport"));

const getPath = (path: string) => `${routes.reports.path}/${path}`;

type Routes = "fleetReport" | "bridgeWatch";

export const reportsRoutes: NestedRouteConfig<Routes> = {
  fleetReport: {
    path: getPath("fleet-report"),
    component: FleetReport,
  },
  bridgeWatch: {
    path: getPath("bridgewatch"),
    component: BridgeWatchReport,
  },
};
