import { createQueryKeyStore } from "@lukemorales/query-key-factory";
import { AppActivityFilter } from "@shipin/shipin-app-server-client";

/**
 * Return current snapshot of filters to Query Key.
 */
const filtersQuery = (filters: AppActivityFilter) => [{ filters }] as const;
const getDetailsById = (id: string | undefined) => [{ id }] as const;

export const queryKeys = createQueryKeyStore({
  sensors: {
    ais: null,
    cameras: null,
    sensorInfo: null,
  },
  sensorsEdge: {
    liveStreamUrl: (sensorId: string | null) => [{ sensorId }],
  },
  myFilters: {
    getMyFilters: null,
  },
  activities: {
    summary: filtersQuery,
    blacklistById: getDetailsById,
    sections: getDetailsById,
    placement: getDetailsById,
  },
  user: {
    permissions: null,
    info: null,
    byID: (id: string) => [id],
    userFilterOptions: null,
    userCount: null,
  },
  weather: {
    "weather-info": (vesselID: string | undefined, start_dttm: string | undefined, end_dttm: string | undefined) => [
      { vesselID, start_dttm, end_dttm },
    ],
  },
  extendedTimeline: {
    "beaufort-timeline": (vesselID: string | undefined, start_dttm: string | undefined, end_dttm: string | undefined) => [
      { vesselID, start_dttm, end_dttm },
    ],
    "visibility-timeline": (vesselID: string | undefined, start_dttm: string | undefined, end_dttm: string | undefined) => [
      { vesselID, start_dttm, end_dttm },
    ],
    "daynight-timeline": (vesselID: string | undefined, start_dttm: string | undefined, end_dttm: string | undefined) => [
      { vesselID, start_dttm, end_dttm },
    ],
  },
  notifications: {
    all: null,
    emailConfig: getDetailsById,
    config: null,
  },
  useComments: {
    comments: (id: string | undefined) => [{ id }] as const,
  },
  tags: {
    getTags: null,
  },
  // queryKeys defined in this section will NOT be refreshed automatically
  manualRefresh: {
    // Don't use this key, defined just for the type-safety in invalidateQueries predicate
    all: null,

    // User management page
    userList: (filters: {
      status: string;
      role: string[];
      fleet: string[];
      company: string[];
      ship_manager: string[];
      designaion: string[];
      sort: string;
      order: string;
    }) => [filters],
    sensorNames: getDetailsById,
  },
  detectors: {
    detectorsList: null,
  },
});
