import ChevronIcon from "components/Icon/ChevronIcon";
import { FilterButtonStyle } from "./FilterButton.styles";
import { ButtonProps } from "@material-ui/core";
import { MouseEvent } from "react";

interface FilterButtonProps extends ButtonProps {
  filterType?: string;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
}
const FilterButton = ({ filterType, children, onClick, disabled }: FilterButtonProps) => {
  return (
    <div className="topbar-right__item">
      <FilterButtonStyle onClick={onClick} variant="contained" disabled={disabled}>
        {!!filterType ? <>{filterType}:</> : null} <strong>{children}</strong>
        <ChevronIcon />
      </FilterButtonStyle>
    </div>
  );
};
export default FilterButton;
