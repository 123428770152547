import { ButtonProps as MuiButtonProps, CircularProgress } from "@material-ui/core";
import { StyledButton } from "./Button.styles";

interface ButtonProps extends Omit<MuiButtonProps, "size"> {
  loading?: boolean;
  loaderSize?: number;
  size?: "small" | "default";
}
const Button = (props: ButtonProps) => {
  const { loading, children, loaderSize = 24, size = "default", ...rest } = props;
  return (
    <StyledButton className={size === "small" ? "small" : "".trim()} {...rest}>
      {children}
      {loading && <CircularProgress size={loaderSize} />}
    </StyledButton>
  );
};

export default Button;
