import { CheckboxProps } from "@material-ui/core";
import { StyledCheckbox } from "./Checkbox.styles";
import { colors } from "ui/Theme/colors";
import { forwardRef } from "react";

const CheckedIcon = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 2H5C3.34315 2 2 3.34315 2 5V12C2 13.6569 3.34315 15 5 15H12C13.6569 
        15 15 13.6569 15 12V5C15 3.34315 13.6569 2 12 2Z"
        fill={colors.primary[500]}
        stroke={colors.primary[500]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M11.4171 6.25L7.41711 11.25L5.41711 9.75" fill={colors.primary[500]} />
      <path d="M11.4171 6.25L7.41711 11.25L5.41711 9.75" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

const Icon = (props: Pick<CustomCheckboxProps, "color">) => {
  const { color = colors.gray[950] } = props;

  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 2H5C3.34315 2 2 3.34315 2 5V12C2 13.6569 3.34315 15 5 15H12C13.6569 15 15 13.6569 
        15 12V5C15 3.34315 13.6569 2 12 2Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const IndeterminateIcon = (props: Pick<CustomCheckboxProps, "color">) => {
  const { color = colors.gray[950] } = props;

  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 2H5C3.34315 2 2 3.34315 2 5V12C2 13.6569 3.34315 15 5 15H12C13.6569 15 15 13.6569 
        15 12V5C15 3.34315 13.6569 2 12 2Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line x1="5" y1="8.5" x2="12" y2="8.5" stroke={color} />
    </svg>
  );
};

type CustomCheckboxProps = Omit<
  CheckboxProps,
  "size" | "icon" | "indeterminateIcon" | "checkedIcon" | "disableTouchRipple" | "disableFocusRipple" | "disableRipple" | "color"
> & {
  color?: string;
};
const Checkbox = forwardRef<HTMLButtonElement, CustomCheckboxProps>((props, ref) => {
  const { color = colors.gray[950], ...rest } = props;

  return (
    <StyledCheckbox
      ref={ref}
      size="small"
      icon={<Icon color={color} />}
      indeterminateIcon={<IndeterminateIcon color={color} />}
      checkedIcon={<CheckedIcon />}
      disableFocusRipple
      disableRipple
      disableTouchRipple
      {...rest}
    />
  );
});

export { Checkbox };
