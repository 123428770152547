import styled from "styled-components";
import { rem, color } from "config/variable.styles";

export const CheckboxLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .icon {
    flex: 0 0 ${rem(17)};
    width: ${rem(17)};
    height: ${rem(17)};
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .label-text {
    font-size: ${rem(14)};
    color: ${color.DarkBlack};
    line-height: ${rem(17)};
    /* padding: 0 0 0 ${rem(10)}; */
    flex: 1 1 auto;
  }
  .count {
    /* margin-left: auto; */
  }
`;
