import { useQuery } from "@tanstack/react-query";
import { ActivitiesV1Service, AisV1Service, NavigationTimelineItem, NavigationTimelineResponse } from "@shipin/shipin-app-server-client";

import { queryKeys } from "config";
import { useDateRange } from "hooks";

import { getOptions } from "components/Timelines/utils";
import { navigationTimelineColors } from "components/Timelines/TimelineColor";

export const getBase = (type: string) => {
  return { id: "", type: type, color: "" };
};

function useTimelineResolution() {
  const { range, getDateRange, getDiffInHours } = useDateRange();

  return [
    range,
    () => {
      const { min, max } = getDateRange();
      const diffInHours = getDiffInHours();
      const { resolution } = getOptions(diffInHours);

      return { resolution, start_dttm: min, end_dttm: max };
    },
  ] as const;
}

function useDayNightTimelineQuery(vessel: string | undefined) {
  const [{ start, end }, getPayload] = useTimelineResolution();
  const dayNightenabled = getPayload().resolution.hours <= 2;

  const query = useQuery({
    ...queryKeys.extendedTimeline["daynight-timeline"](vessel, start, end),
    queryFn: async () => {
      if (!vessel) return Promise.reject("Err");
      const {
        resolution: { minutes },
        ...rest
      } = getPayload();

      return AisV1Service.postApiV1AisV1SunlightPhaseTimeline({
        vessel_id: vessel,
        ...rest,
        resolution: {
          minutes: minutes !== 0 && minutes < 15 ? minutes : 15,
          hours: 0,
        },
      });
    },
    structuralSharing: false,
    enabled: !!vessel && dayNightenabled,
  });

  return { query, dayNightenabled };
}

function useBeaufortTimelineQuery(vessel: string | undefined) {
  const [{ start, end }, getPayload] = useTimelineResolution();

  return useQuery({
    ...queryKeys.extendedTimeline["beaufort-timeline"](vessel, start, end),
    queryFn: async () => {
      if (!vessel) return Promise.reject("Err");

      return ActivitiesV1Service.postApiV1ActivitiesV1BeaufortTimeline({
        vessel_id: vessel,
        ...getPayload(),
      });
    },
    structuralSharing: false,
    enabled: !!vessel,
  });
}

function useVisibilityTimelineQuery(vessel: string | undefined) {
  const [{ start, end }, getPayload] = useTimelineResolution();

  return useQuery({
    ...queryKeys.extendedTimeline["visibility-timeline"](vessel, start, end),
    queryFn: async () => {
      if (!vessel) return Promise.reject("Err");

      return ActivitiesV1Service.postApiV1ActivitiesV1VisibilityTimeline({
        vessel_id: vessel,
        ...getPayload(),
      });
    },
    structuralSharing: false,
    enabled: !!vessel,
  });
}

export const navigationTimelineTitle: Record<`${NavigationTimelineItem.status}`, string> = {
  UNDERWAY_50: "Underway > 50NM",
  UNDERWAY_12: "Underway > 12NM",
  UNDERWAY_RESTRICTED_WATER: "Underway Restricted Water",
  UNDERWAY_NEAR_PORT: "Underway Near Port",
  UNDERWAY_HEAVY_TRAFFIC: "Underway Heavy Traffic",
  MOORED: "Moored",
  AT_ANCHOR: "At Anchor",
  OTHER: "Other",
  CONFLICTING_INFORMATION: "Conflicting Information",
  NOT_UNDER_COMMAND: "Not Under Command",
  UNDERWAY: "Underway",
};

// Transforming data to add color based on the status.
const selectNavigationTimeline = (response: NavigationTimelineResponse) =>
  (response?.timeline ?? []).map(({ status, ...i }, id) => {
    const port = !!i.current_port_unlocode ? `[${i.current_port_unlocode?.substring(0, 2)}]` : "";

    return {
      ...i,
      color: navigationTimelineColors[status ?? "OTHER"],
      type: "Nav. status",
      amc_fromDate: new Date(i.start_dttm),
      amc_toDate: new Date(i.end_dttm),
      from_dttm: i.start_dttm,
      to_dttm: i.end_dttm,
      id: id.toString(),
      tooltip: [
        { title: "Navigation status", value: navigationTimelineTitle[status] },
        { title: "Current Port", value: i.current_port ? `${i.current_port} ${port}`.trim() : null },
      ],
    };
  });

function useNavigationTimelineQuery(vessel: string | undefined) {
  const [{ start, end }, getPayload] = useTimelineResolution();

  return useQuery({
    queryKey: ["navigation-timeline", vessel, start, end],
    queryFn: () => {
      if (!vessel) return Promise.reject("");

      return ActivitiesV1Service.postApiV1ActivitiesV1NavigationTimeline({
        vessel_id: vessel,
        ...getPayload(),
      });
    },
    enabled: !!vessel,
    select: selectNavigationTimeline,
    structuralSharing: false,
  });
}

export { useBeaufortTimelineQuery, useDayNightTimelineQuery, useNavigationTimelineQuery, useVisibilityTimelineQuery };
