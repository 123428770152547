import { useId, useMemo, useRef } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as yup from "yup";
import { MenuItem, Select, TextField } from "@material-ui/core";
import { UserQuerySingleResponse } from "@shipin/shipin-app-server-client";
import { isEqual } from "lodash";

import { validation } from "utils";
import { useInvalidateUsers, useUpdateUserMutation } from "queries";

import { CustomModalProps } from "components/Modal";
import { UsersModalContent, UsersModalStyle } from "./EditUser.style";
import InviteUsersDropdpwn from "components/InviteUsersDropdpwn";
import ChevronIcon from "components/Icon/ChevronIcon";
import { useTransferFleets } from "components/Transfer";
import { ErrorToast, SuccessToast } from "components/Toastbar";
import { useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "config";
import { useAppDispatch, useAppSelector } from "hooks";
import { setCompanyFilter, setDesignationFilter, setShipManagerFilter } from "services/redux/reducers/TableFilterBar/UsersFilter.slice";

const schema = yup.object().shape({
  name: validation.name(),
  designation: validation.name("Designation"),
  ship_manager: validation.name("Ship Manager"),
  company: validation.name("Company"),
});

const EditUser = (props: CustomModalProps & Omit<UserQuerySingleResponse, "role">) => {
  const { id: userID, email, full_name, status: currentStatus, memberships, onClose, designation, ship_manager, company } = props;
  const id = useId();
  const client = useQueryClient();
  const dispatch = useAppDispatch();
  const invalidateUsers = useInvalidateUsers();
  const userFilters = useAppSelector((state) => state.usersFilter);

  const { isPending, mutate } = useUpdateUserMutation({
    onSuccess: () => {
      toast.success(<SuccessToast>User updated successfully.</SuccessToast>);
      invalidateUsers();
      client.invalidateQueries({ ...queryKeys.user.userFilterOptions });

      // If user change designation, ship manager or company which is already selected in filter bar, remove it from filter bar
      if (!!company && userFilters.company.includes(company)) {
        dispatch(setCompanyFilter(userFilters.company.filter((e) => e !== company)));
      }

      if (!!designation && userFilters.designation.includes(designation)) {
        dispatch(setDesignationFilter(userFilters.designation.filter((e) => e !== designation)));
      }

      if (!!ship_manager && userFilters.shipManager.includes(ship_manager)) {
        dispatch(setShipManagerFilter(userFilters.shipManager.filter((e) => e !== ship_manager)));
      }

      setTimeout(() => {
        onClose?.(undefined);
      }, 1000);
    },
    onError: (e) => toast.error(<ErrorToast>{e?.body?.details ?? "An error occurred updating the user."}</ErrorToast>),
  });
  const associatedFleets = useRef((memberships ?? []).map((e) => e.fleet_id));
  const { props: dropdownProps, selected } = useTransferFleets(associatedFleets.current);

  const { getFieldProps, errors, values, setFieldValue } = useFormik({
    initialValues: {
      name: full_name ?? "",
      designation: designation ?? "",
      ship_manager: ship_manager ?? "",
      company: company ?? "",
      status: currentStatus?.toUpperCase() ?? "",
    },
    // Formik is used just for validation,
    onSubmit: () => {},
    validationSchema: schema,
  });

  const name = values.name.trim();
  const trimmedDesignation = values.designation.trim();
  const trimmedShipManager = values.ship_manager.trim();
  const trimmedCompany = values.company.trim();
  const isFleetsEqual = useMemo(() => isEqual(selected.map((e) => e.id).sort(), [...associatedFleets.current].sort()), [selected]);
  const hasError =
    Object.keys(errors).length > 0 ||
    (name === (full_name ?? "") &&
      trimmedDesignation === (designation ?? "") &&
      trimmedShipManager === (ship_manager ?? "") &&
      trimmedCompany === (company ?? "") &&
      values.status === currentStatus?.toUpperCase() &&
      isFleetsEqual);

  const handleSubmit = () => {
    if (!userID || hasError) return;

    mutate({
      id: userID,
      //@ts-ignore
      status: values.status.toUpperCase(),
      fleets_associated: selected.map((e) => ({ fleet_id: e.id })),
      full_name: name,
      designation: trimmedDesignation,
      ship_manager: trimmedShipManager,
      company: trimmedCompany,
    });
  };

  return (
    <UsersModalStyle
      {...props}
      loading={isPending}
      disableButton={isPending || hasError}
      onSubmit={handleSubmit}
      title="Edit User"
      actionTitle="Save"
    >
      <UsersModalContent>
        <div className="modalFormRow">
          <label>Email</label>
          <div className="from-group center">
            <span className="email-text-block">{email}</span>
          </div>
        </div>
        <div className="modalFormRow">
          <label htmlFor={`name-${id}`}>Name</label>
          <div className="from-group">
            <TextField
              id={`name-${id}`}
              helperText={errors.name}
              inputProps={{ autoComplete: "off" }}
              className="form-control"
              type="name"
              {...getFieldProps("name")}
            />
          </div>
        </div>
        <div className="modalFormRow">
          <label htmlFor={`status-${id}`}>Status</label>
          <div className="from-group">
            <Select
              id={`status-${id}`}
              className="statusSelectBox"
              value={values.status}
              IconComponent={ChevronIcon}
              onChange={(e) => setFieldValue("status", e.target.value as string)}
            >
              <MenuItem value="ACTIVE">Active</MenuItem>
              <MenuItem value="PENDING">Pending</MenuItem>
              <MenuItem value="SUSPENDED">Suspended</MenuItem>
            </Select>
          </div>
        </div>
        <div className="modalFormRow">
          <label>Associated Fleets</label>
          <div className="from-group">
            <InviteUsersDropdpwn {...dropdownProps} />
          </div>
        </div>
        <div className="modalFormRow">
          <label htmlFor={`designation-${id}`}>Designation</label>
          <div className="from-group">
            <TextField
              id={`designation-${id}`}
              helperText={errors.designation}
              inputProps={{ autoComplete: "off" }}
              className="form-control"
              type="name"
              {...getFieldProps("designation")}
            />
          </div>
        </div>
        <div className="modalFormRow">
          <label htmlFor={`ship-manager-${id}`}>Ship Manager</label>
          <div className="from-group">
            <TextField
              id={`ship-manager-${id}`}
              helperText={errors.ship_manager}
              inputProps={{ autoComplete: "off" }}
              className="form-control"
              type="name"
              {...getFieldProps("ship_manager")}
            />
          </div>
        </div>
        <div className="modalFormRow">
          <label htmlFor={`company-${id}`}>Company</label>
          <div className="from-group">
            <TextField
              id={`company-${id}`}
              helperText={errors.company}
              inputProps={{ autoComplete: "off" }}
              className="form-control"
              type="name"
              {...getFieldProps("company")}
            />
          </div>
        </div>
      </UsersModalContent>
    </UsersModalStyle>
  );
};

export default EditUser;
