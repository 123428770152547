import { Box } from "@material-ui/core";
import { endOfMonth, startOfMonth, subMonths } from "date-fns";

import { MonthSelect } from "ui";
import { formatDate } from "utils";

import { DateSelectButton } from "./Filters.styles";
import { previousMonth, previousSixMonths, previousThreeMonths, today } from "components/Filters/DateRangePicker";
import FilterButton from "components/FilterButton";

const previousTwelveMonths = subMonths(today, 12);

// When changing anything here, make sure to update the ranges in src/services/redux/reducers/Filters/PerformanceAnalyticsFilter.slice.ts
// In that file, array index is used to get the initial date range
export const ranges = [
  {
    label: "Current Month",
    startDate: startOfMonth(today).toString(),
    endDate: endOfMonth(today).toString(),
  },
  {
    label: "1M",
    startDate: startOfMonth(previousMonth).toString(),
    endDate: endOfMonth(previousMonth).toString(),
  },
  {
    label: "3M",
    startDate: startOfMonth(previousThreeMonths).toString(),
    endDate: endOfMonth(previousMonth).toString(),
  },
  {
    label: "6M",
    startDate: startOfMonth(previousSixMonths).toString(),
    endDate: endOfMonth(previousMonth).toString(),
  },
  {
    label: "12M",
    startDate: startOfMonth(previousTwelveMonths).toString(),
    endDate: endOfMonth(previousMonth).toString(),
  },
] as const;

const FORMATTER = "MMM yyyy";

interface DateFilterProps {
  start: string;
  end: string;
  onChange: (value: { start: string; end: string }) => void;
}
const DateFilter = (props: DateFilterProps) => {
  const { start, end, onChange } = props;
  const labelText = `${formatDate(start, FORMATTER)} - ${formatDate(end, FORMATTER)}`;

  return (
    <>
      <MonthSelect value={{ start, end }} label={<FilterButton>{labelText}</FilterButton>} onSave={onChange} />
      <Box display="flex" gridGap={15} mr="auto">
        {ranges.map(({ label, startDate, endDate }) => (
          <DateSelectButton
            key={label}
            className={start === startDate && end === endDate ? "active" : undefined}
            onClick={() => onChange({ start: startDate, end: endDate })}
          >
            {label}
          </DateSelectButton>
        ))}
      </Box>
    </>
  );
};

export default DateFilter;
