import { PropsWithChildren } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Menu, MenuItem } from "@material-ui/core";

import { routes } from "config";
import { useMenu } from "hooks";

import { ProfileBtn } from "../Header.styles";
import { userProfileRoutes } from "pages/MyProfile/userprofile.routes";
import { Tooltip } from "components/Tooltip/Tooltip.styles";

const ProfileMenu = (props: PropsWithChildren<{}>) => {
  const { children } = props;
  const history = useHistory();
  const { pathname } = useLocation();
  const [menuProps, handleMenuOpen] = useMenu({
    closeOnSelect: true,
    className: "custom-action-dropdown",
  });

  const getMenuProps = (path: string) => ({ selected: pathname === path, onClick: () => history.push(path) });

  return (
    <>
      <Tooltip placement="top-end" arrow title="My Account">
        <ProfileBtn aria-controls="customized-menu" aria-haspopup="true" variant="contained" onClick={handleMenuOpen} disableRipple={true}>
          {children}
        </ProfileBtn>
      </Tooltip>
      <Menu id="customized-menu" {...menuProps}>
        <MenuItem {...getMenuProps(userProfileRoutes.profileInformation.path)}>My Profile</MenuItem>
        <MenuItem {...getMenuProps(userProfileRoutes.notificationSettings.path)}>Notification Settings</MenuItem>
        <MenuItem onClick={() => window.location.replace(routes.logout.path)}>Logout</MenuItem>
      </Menu>
    </>
  );
};

export default ProfileMenu;
