import { Switch as MuiSwitch, withStyles } from "@material-ui/core";
import { color } from "config/variable.styles";

const Switch = withStyles(
  {
    switchBase: {
      color: color.White,
      "&$checked": {
        color: color.navyBlue,
      },
      "&$checked + $track": {
        backgroundColor: color.navyBlue,
      },
    },
    track: {
      backgroundColor: "#BDC1C6",
    },
    checked: {},
    colorSecondary: {},
  },
  { name: "switch" }
)(MuiSwitch);

export { Switch };
