import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StackChartItem, XYChartData } from "components/Charts/Charts.types";

type State = {
  fleetCoverage: XYChartData[number] | null;
  alertSummary: StackChartItem | null;
};
const initialState: State = {
  fleetCoverage: null,
  alertSummary: null,
};
const reportsSelector = createSlice({
  name: "reportsSelector",
  initialState,
  reducers: {
    setSelectedFleetCoverage(state, action: PayloadAction<State["fleetCoverage"]>) {
      state.fleetCoverage = action.payload;
    },
    setSelectedAlert(state, action: PayloadAction<State["alertSummary"]>) {
      state.alertSummary = action.payload;
    },
    clearSelectedPoints(state) {
      // Return if already null
      if (!state.fleetCoverage && !state.alertSummary) return;

      return initialState;
    },
  },
});

export const { setSelectedFleetCoverage, clearSelectedPoints, setSelectedAlert } = reportsSelector.actions;
export default reportsSelector.reducer;
