import { RefObject, useEffect, useRef, useState } from "react";

const useHeightChangeObserver = (elementRef: RefObject<HTMLDivElement>) => {
  const [isHeightIncreased, setHeightIncreased] = useState(false);

  useEffect(() => {
    if (!elementRef.current || !window.ResizeObserver) return;
    const resizeObserver = new ResizeObserver((entries) => {
      setHeightIncreased(entries[0].contentRect.height > 77);
    });
    resizeObserver.observe(elementRef.current);
    return () => resizeObserver.disconnect();
  }, [elementRef]);

  return isHeightIncreased;
};

function useElementHeight(initialHeight?: number) {
  const ref = useRef<HTMLElement>(null);
  const [height, setHeight] = useState(initialHeight ?? 0);

  const observerRef = useRef<ResizeObserver>(
    new ResizeObserver((entries) => {
      const newHeight = entries[0].contentRect.height;
      setHeight(newHeight);
    })
  );

  useEffect(() => {
    const element = ref.current;
    const observer = observerRef.current;

    if (element && !!window?.ResizeObserver) {
      observer.observe(element);
    }
    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return [ref, height] as const;
}

export { useElementHeight };
export default useHeightChangeObserver;
